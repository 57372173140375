import { Moment } from 'moment';

export class ResourceEvent {
    /* fields */
    eventId: string;
    eventType: string;
    requestId: string;
    subscriptionType: string;
    resourceType: string;
    subscriptionId: string;
    eventTime: Moment;
    properties: string;
    orderId: string;
    tags: string;
}

export enum ResourceEventType {
    RestoreCancelled = 'RestoreCancelled',
    RestoreCompleteWithSkips = 'RestoreCompleteWithSkips',
    RestoreFailed = 'RestoreFailed',
    RestoreHeartbeat = 'RestoreHeartbeat',
    RestoreLost = 'RestoreLost',
    RestoreRequestPublished = 'RestoreRequestPublished',
    RestoreRequested = 'RestoreRequested',
    RestoreStalled = 'RestoreStalled',
    RestoreStarted = 'RestoreStarted',
    RestoreSucceeded = 'RestoreSucceeded',
    RestoreSuspended = 'RestoreSuspended',
    SnapshotCancelled = 'SnapshotCancelled',
    SnapshotDelayed = 'SnapshotDelayed',
    SnapshotFailed = 'SnapshotFailed',
    SnapshotHeartbeat = 'SnapshotHeartbeat',
    SnapshotLost = 'SnapshotLost',
    SnapshotRequestPublished = 'SnapshotRequestPublished',
    SnapshotRequested = 'SnapshotRequested',
    SnapshotSkipped = 'SnapshotSkipped',
    SnapshotStalled = 'SnapshotStalled',
    SnapshotStarted = 'SnapshotStarted',
    SnapshotSucceeded = 'SnapshotSucceeded',
    SnapshotSuspended = 'SnapshotSuspended',
}

import { IGraphJourney } from "../models/graph-journey.interface";

// Allows a drive Item to be mapped as either a folder or a file.
function getDriveItemPath(x: Record<string, unknown>): string[] {
  if (x["file.mimeType"] != null) {
    return [];
  } else if (x["fileSystemInfo.createdDateTime"]) {
    return ['driveJourneyChildren'];
  } else {
    return [];
  }
}

/////////////////////////////////////////////////////////////////////////
// Mapping registrations
/////////////////////////////////////////////////////////////////////////
const GraphJourneyMappersRegistrations: Array<{ name: string, func: (x: Record<string, unknown>) => string[] }> = [
  { name: "getDriveItemPath", func: getDriveItemPath }
]

export const GraphJourneyMappers = {
  GraphJourneyMappersRegistrations
}


/////////////////////////////////////////////////////////////////////////
// Drive related journeys
/////////////////////////////////////////////////////////////////////////
const DriveJourney: Array<IGraphJourney> = [
  {
    key: "driveJourneyChildren",
    name: "Folder",
    display: "name",
    url: "drives/{driveId}/items/{id}/children",
    fields: [
      { field: "id", name: "ID Field", alias: "id" },
      { field: "name", name: "Name" },
      { field: "parentReference.driveId", alias: "driveId", hidden: true }
    ],
    childJourneys: ['getDriveItemPath']
  },
  {
    key: "driveSiteJourney",
    name: "Drives",
    display: "name",
    url: "drives/{driveId}/root/children",
    fields: [
      { field: "id", alias: "id", name: "ID Field", hidden: true },
      { field: "name", name: "Name" },
      { field: "parentReference.driveId", alias: "driveId", hidden: true }
    ],
    childJourneys: ['getDriveItemPath']
  }
];

/////////////////////////////////////////////////////////////////////////
// Teams related journeys
/////////////////////////////////////////////////////////////////////////
const TeamsJourney: Array<IGraphJourney> = [{
  key: "teamJourney",
  name: "Teams Channels",
  display: "displayName",
  url: "teams/{teamId}/channels",
  fields: [
    { field: "id", alias: "channelId", name: "ID Field", hidden: true },
    { field: "displayName", name: "Display Name" },
    { field: "tenantId", name: "Tenant ID" }
  ],
  childJourneys: ['teamChannelFilesFolder'],
}, {
  key: "teamChannelFilesFolder",
  name: "Teams Channel Files Folder",
  display: "name",
  url: "teams/{teamId}/channels/{channelId}/filesFolder",
  fields: [
    { field: "id", alias: "id", hidden: true },
    { field: "name", name: "Name" },
    { field: "size", name: "Size" },
    { field: "parentReference.driveId", alias: "driveId", hidden: true }
  ],
  childJourneys: ['driveSiteJourney']
}]

/////////////////////////////////////////////////////////////////////////
// Sites related journeys
/////////////////////////////////////////////////////////////////////////
const SitesJourney: Array<IGraphJourney> = [
  {
    key: "siteJourney",
    name: "Site",
    display: "name",
    url: "sites/{siteId}",
    fields: [
      { field: "id", alias: "siteId", name: "ID Field", hidden: true },
      { field: "name", name: "Name" },
      { field: "webUrl", name: "Web URL" }
    ],
    childJourneys: ['siteSubsitesJourney', 'siteDriveJourney']
  },
  {
    key: "siteSubsitesJourney",
    name: "Site Subsites",
    display: "name",
    url: "sites/{siteId}/sites",
    fields: [
      { field: "id", alias: "siteId", name: "ID Field", hidden: true },
      { field: "name", name: "Name" },
      { field: "webUrl", name: "Web URL" }
    ],
    childJourneys: ['siteSubsitesJourney', 'siteDriveJourney']
  },
  {
    key: "siteDriveJourney",
    name: "Site Drives",
    display: "name",
    url: "sites/{siteId}/drives",
    fields: [
      { field: "id", alias: "driveId", name: "ID Field", hidden: true },
      { field: "name", name: "Name" },
      { field: "webUrl", name: "Web URL" }
    ],
    childJourneys: ['driveSiteJourney', 'listSiteJourney']
  },
  {
    key: "listItemJourney",
    name: "List Item",
    display: "name",
    url: "sites/{siteId}/lists/{listId}/items/{id}",
    fields: [
      { field: "id", name: "ID Field" },
      { field: "parentReference.siteId", alias: "siteId", hidden: true }
    ],
    childJourneys: [],
  }, {
    key: "listItemsJourney",
    name: "List Items",
    display: "name",
    url: "sites/{siteId}/lists/{listId}/items",
    fields: [
      { field: "id", name: "ID Field" }
    ],
    childJourneys: ['listItemJourney']
  }, {
    key: "listJourney",
    name: "List",
    display: "displayName",
    url: "sites/{siteId}/lists/{listId}",
    fields: [
      { field: "id", alias: "listItemId", name: "ID Field", hidden: true },
      { field: "displayName", name: "Name" },
      { field: "parentReference.siteId", alias: "siteId", hidden: true }
    ],
    childJourneys: ['listItemsJourney'],
  }, {
    key: "listSiteJourney",
    name: "Site Lists",
    display: "displayName",
    url: "sites/{siteId}/lists",
    fields: [
      { field: "displayName", name: "Display Name" },
      { field: "id", alias: "listId", hidden: true },
      { field: "parentReference.siteId", alias: "siteId", hidden: true },

    ],
    childJourneys: ['listJourney', 'listItemsJourney'],
  }
];

/////////////////////////////////////////////////////////////////////////
// Groups related journeys
/////////////////////////////////////////////////////////////////////////
const GroupsJourney: Array<IGraphJourney> = [
  {
    key: "groupsRoot",
    name: "Groups Site",
    display: "displayName",
    url: "groups/{groupId}/sites/root",
    fields: [
      { field: "id", alias: "siteId", hidden: true },
      { field: "displayName", name: "Display Name" },
    ],
    childJourneys: ['siteDriveJourney', 'listSiteJourney']
  },
  {
    key: "groupsJourney",
    name: "Groups",
    display: "displayName",
    url: "groups/{groupId}",
    fields: [
      { field: "id", alias: "groupId", hidden: true },
      { field: "displayName", name: "Display Name" },
    ],
    childJourneys: ['groupsRoot']
  }
];

/////////////////////////////////////////////////////////////////////////
// Users related journeys
/////////////////////////////////////////////////////////////////////////
const UsersJourney: Array<IGraphJourney> = [
  {
    key: "oneDriveJourney",
    name: "OneDrive",
    display: "name",
    url: "users/{userId}/drives",
    fields: [
      { field: "id", name: "ID Field", alias: "driveId", hidden: true },
      { field: "name", name: "Name" },
      { field: "webUrl" }
    ],
    childJourneys: ['driveSiteJourney']
  }
];

/////////////////////////////////////////////////////////////////////////
// Registrations
/////////////////////////////////////////////////////////////////////////
const GraphJourneyRegistrations: Array<IGraphJourney> = []
  .concat(DriveJourney)
  .concat(TeamsJourney)
  .concat(SitesJourney)
  .concat(GroupsJourney)
  .concat(UsersJourney);

export const GraphJourney = {
  GraphJourneyRegistrations
};


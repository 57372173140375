import { Component, OnInit, Input } from '@angular/core';
import { map } from 'rxjs/operators';

import * as moment from 'moment';
import * as _ from 'lodash';

import { ApiService } from '../../api-service/api.service';
import { JobStatusWeek, JobLastSuccess, BackupJob } from '@sk-models';

@Component({
  selector: 'sk-subscription-health',
  templateUrl: './subscription-health.component.html',
  styleUrls: ['./subscription-health.component.scss']
})
export class SubscriptionHealthComponent implements OnInit {
  weeklySnapshotSuccess: JobStatusWeek[];
  lastJobSuccess: JobLastSuccess[];

  @Input() orderId: string;
  @Input() subscriptionId: string;
  @Input() resourceType: string;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadSubscriptionHealth();
  }

  loadSubscriptionHealth(): void {
    this.apiService.findSubscriptionJobs(this.orderId, this.subscriptionId, this.resourceType, null, false)
      .pipe(map(jobs => this.mapToSnapshotHealth(jobs)))
      .subscribe(health => {
        this.weeklySnapshotSuccess = health.weeklySnapshotSuccess;
        this.lastJobSuccess = health.lastJobSuccess;
      });
  }

  getLastSuccessInformation(jobs: BackupJob[]): JobLastSuccess[] {
    const lastSuccessfulJobs: JobLastSuccess[] = [];
    if (!jobs) {
      return lastSuccessfulJobs;
    }

    const lastSuccessfulFullSnaps = jobs.filter(job =>
      job.type === 'FullSnapshot' &&
        job.finished !== null &&
        job.created !== null &&
        !job.failed)
      .sort(this.jobHistoryCompareFn);

    if (lastSuccessfulFullSnaps.length > 0) {
      lastSuccessfulJobs.push({
        jobType: 'FullSnapshot',
        lastSuccess: lastSuccessfulFullSnaps[0].finished
      });
    }

    const lastSuccessfulFastSnaps = jobs.filter(job =>
      job.type === 'FastSnapshot' &&
      job.finished !== null &&
      job.created !== null &&
      !job.failed)
      .sort(this.jobHistoryCompareFn);

    if (lastSuccessfulFastSnaps.length > 0) {
      lastSuccessfulJobs.push({
        jobType: 'FastSnapshot',
        lastSuccess: lastSuccessfulFastSnaps[0].finished
      });
    }
    return lastSuccessfulJobs;
  }

  getWeeklySnapshotSuccess(jobs: BackupJob[]): JobStatusWeek[] {
    if (!jobs) {
      return [];
    }

    const snapshotSuccesses = jobs.filter(job => job.finished !== null);

    return _.chain(snapshotSuccesses)
      .groupBy(job => moment(job.finished).week())
      .map(function(group, week) {
        return {
          weekStartDate: week,
          success: group.filter(x => x.failed === false).length,
          total: group.length
        };
      })
      .map(function(x) {
        return {
          weekOf: moment().week(+x.weekStartDate).day(0),
          successfulJobs: x.success === 0 ? '0%' : Math.round((x.success / x.total) * 100) + '%'
        };
      })
      .sort(this.weeklySnapshotCompareFn)
      .value();
  }

  mapToSnapshotHealth(jobs: BackupJob[]): { weeklySnapshotSuccess: JobStatusWeek[], lastJobSuccess: JobLastSuccess[] } {
    return {
      weeklySnapshotSuccess: this.getWeeklySnapshotSuccess(jobs),
      lastJobSuccess: this.getLastSuccessInformation(jobs)
    };
  }

  jobHistoryCompareFn(current: BackupJob, next: BackupJob): number {
    const nextMoment = next.created || moment('1900-01-01');
    const currentMoment = current.created || moment('1900-01-01');

    return nextMoment.valueOf() - currentMoment.valueOf();
  }

  weeklySnapshotCompareFn(
    left: { weekOf: moment.Moment, successfulJobs: string },
    right: { weekOf: moment.Moment, successfulJobs: string }): number {
    return right.weekOf.valueOf() - left.weekOf.valueOf();
  }
}

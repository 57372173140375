import { Injectable } from '@angular/core';

@Injectable()
export class TabsDispatchService {
  tabsMap = {};

  setSubTab(tab: string, subtab: string): void {
    this.tabsMap[tab] = subtab;
  }

  getSubTab(tab: string): string {
    return this.tabsMap[tab];
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

import { MessageService } from '@sk-services';
import { BackupOrderBillingRecord, GenericModalOptions } from '@sk-models';
import { ApiService } from '../../api-service/api.service';

@Component({
  selector: 'sk-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  billingRecords: BackupOrderBillingRecord[];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadBillingRecords();
  }

  toggleSeatDetail(billingRecord: BackupOrderBillingRecord): void {
    billingRecord.showSeatDetail = !billingRecord.showSeatDetail;
  }

  loadBillingRecords(): void {
    const orderId = this.route.snapshot.paramMap.get('id');
    this.apiService.findBillingRecords(orderId)
      .pipe(map(brs => brs.map(br => this.computeBillingRecordProperties(br))),
        map(arr => arr.sort(this.billingRecordCompareFn)))
      .subscribe(brs => this.billingRecords = brs);
  }

  billingRecordCompareFn(left: BackupOrderBillingRecord, right: BackupOrderBillingRecord): number {
    return right.month.valueOf() - left.month.valueOf();
  }

  computeBillingRecordProperties(billingRecord: BackupOrderBillingRecord): BackupOrderBillingRecord {
    return billingRecord;
  }

  expandBillingInfoFlyout(): void {
    const orderId = this.route.snapshot.paramMap.get('id');
    const htmlBody =
      `<span style="white-space:nowrap;">
        Counts displayed are accurate, but <u>DO NOT</u> neccessarily reflect what the order was
        billed.
      </span>
      <span style="white-space:nowrap; padding-bottom:20px;">
        The Promo and other configuration may account for any differences.
      </span>
      <span style="white-space:nowrap;">
        Please refer to the
        <a href="https://manage.skykick.com/BackupOrder/DetailsForSkyKick/${orderId}"
          title="Open order in billing portal."
          target="_blank"
          class="clickable"
          style="outline : none;">
          <u>Billing Portal</u>
        </a>
        for up-to-date billing calculations.
      </span>`;
    this.messageService.openModal(htmlBody, null, GenericModalOptions.ShowHtml);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import * as moment from 'moment';

import {
  SwatTicket,
  SwatTickets,
} from '@sk-models';

@Component({
  selector: 'sk-swat-ticket',
  templateUrl: './swat-ticket.component.html',
  styleUrls: ['./swat-ticket.component.scss']
})
export class SwatTicketComponent {
  @Output() navigateSwatTicket = new EventEmitter<SwatTicket>();
  @Input() swatTickets: SwatTickets;

  onNavigateSwatTicket(swatTicket: SwatTicket): void {
    this.navigateSwatTicket.emit(swatTicket);
  }

  getSwatTicketAge(createdDate: moment.Moment): string {
    const age = moment(createdDate).fromNow(true);
    return age.includes('seconds') ? 'Just now' : `${age} old`;
  }
}




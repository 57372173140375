<form class="tree-viewer-search-form" [formGroup]="searchForm" (ngSubmit)="onFormSubmit()">
  <mat-form-field class="input-full-width">
    <input matInput placeholder="Find Child Item" formControlName="childItemName" >
    <button *ngIf="searchForm.value.childItemName" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint>Applies case insensitive search.</mat-hint>
  </mat-form-field>

  <button mat-raised-button type="submit">Search</button>

  <button mat-raised-button (click)="downloadClicked()">Download</button>
</form>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'sk-job-history-day-range',
  templateUrl: './job-history-day-range.component.html',
  styleUrls: ['./job-history-day-range.component.scss']
})
export class JobHistoryDayRangeComponent implements OnInit {

  @Input() days: number[] = [3, 7, 14, 30];
  @Input() default: number = null;
  @Output() daySelected = new EventEmitter();

  userDays: number = this.default;
  expanded: false;

  ngOnInit(): void {
    if(this.default == null && this.days?.length > 0) {
      this.userDays = this.days[0];
    }
  }

  daysQuery(days: MatSelectChange ): void {
    this.userDays = days.value;
    this.daySelected.emit(days.value);
  }
}

<div class="side-drawer-container">
  <div class="side-drawer-header">
    <div class="text no-text-overflow">
      {{title}}
    </div>
  </div>
  <div class="side-drawer-content-container details-container">
    <div class="side-drawer-sub-section-header" *ngIf="subtitle">
      {{subtitle}}
    </div>
    <div class="details-content">
      <table class="details-table">
        <tbody>
          <tr *ngFor="let p of results">
            <td class="title" [title]="p.name">{{p.name}}:</td>
            <td class="value" [title]="p.value">{{p.value}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="subscription" class="subscription-card">
  <div class="card-row">
    <div class="card-section">
      <mat-icon class="icon" [class.enabled]="subscription.enabled" title="Enabled?">power_settings_new</mat-icon>
      <span class="resourceType">{{subscription.resourceKey.resourceType}}</span>
    </div>
    <div class="card-section">
      <span>{{subscription.friendlyName}}</span>
    </div>

  </div>
  
  <div class="card-row">
    <div class="card-section">
      <span class="fullName">{{subscription.fullName}}</span>
    </div>
  </div>
</div>

import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import {
  LicensingItem,
  LicensingItemChild,
  LicensingItemType,
} from '@sk-models';

@Component({
  selector: 'sk-licensing-drawer',
  templateUrl: './licensing-drawer.component.html',
  styleUrls: ['./licensing-drawer.component.scss']
})
export class LicensingDrawerComponent {
  @Output() navigateToItem = new EventEmitter<LicensingItemChild>();

  @Input() item: LicensingItem;
  @Input() itemType: LicensingItemType;

  onNavigateToItem(grandChild: LicensingItemChild): void {
    this.navigateToItem.emit(grandChild);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../../api-service/api.service';
import { SubscriptionJobMetric } from '@sk-models';


@Component({
  selector: 'sk-restore-details',
  templateUrl: './restore-details.component.html',
  styleUrls: ['./restore-details.component.scss']
})
export class RestoreDetailsComponent implements OnInit {

  jobId: string;
  metrics: SubscriptionJobMetric[];
  isRefreshing: boolean;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.url && this.activatedRoute.snapshot.paramMap.get('jobId')) {
      this.jobId = this.activatedRoute.snapshot.paramMap.get('jobId');
      this.onReloadData();
    }
  }

  onReloadData(): void {
    this.isRefreshing = true;
    this.apiService.getRestoreStatsForJob(this.jobId).subscribe(data => {
      this.metrics = data;
      this.isRefreshing = false;
    });
  }
}

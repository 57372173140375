<div class="bsp-content-container reindex-history">
  <div class="d-flex flex-grow-1 title-container">
    <span class="title">Reindex Job History</span>
  </div>

	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					Filters
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-content *ngIf="!isRefreshing"></ng-content>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="mat-elevation-z8 grid-container">
    <sk-click-to-refresh
      class="refresh"
      [shouldDisplay]="jobs && jobs.length > 0"
      [spin]="isRefreshing"
      (clickEvent)="onRefreshData()">
    </sk-click-to-refresh>
		<div class="table-container">

      <mat-table [dataSource]="dataSource"
        matSort>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Id</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              hoverText="(Click to copy!) {{row.id}}"
              [copyText]="row.id">
            </sk-click-to-copy>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subscriptionId" *ngIf="subscriptionId == null">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Subscription Id</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a href="orders/{{orderId}}/subscriptions/{{row.subscriptionId}}/{{row.mappedResourceType}}">{{row.subscriptionId}}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subscriptionType" *ngIf="subscriptionId == null">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Sub. Type</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.subscriptionType}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Status</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>
							<mat-icon
                [class]="row.statusIconClass"
                [title]="row.statusIconTitle">
								{{row.statusIcon}}
							</mat-icon>
						</span>
          </mat-cell>
        </ng-container>

				<ng-container matColumnDef="startedTime">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Started</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{(row.startedTime | friendlyDate:"MM/DD/YY hh:mm A") || '--'}}</span>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="lastUpdatedTime">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Last Updated</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{(row.lastUpdatedTime | friendlyDate:"MM/DD/YY hh:mm A") || '--'}}</span>
					</mat-cell>
        </ng-container>

        <ng-container matColumnDef="finishedTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Finished</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{(row.finishedTime | friendlyDate:"MM/DD/YY hh:mm A") || '--'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="indexedDocumentCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Re-indexed</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.indexedDocumentCount || '--'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="errorMessage">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Error</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a *ngIf="row.errorMessage"
            href=""
            [title]="row.errorMessage > 400
              ? row.errorMessage.substring(0,400) + '...'
              : row.errorMessage"
            target="_blank"
            (click)="showModal(row.errorMessage, 'Error Message'); $event.preventDefault();">
            error message...
						</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="roleInstanceId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Instance</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              [displayText]="row.roleInstanceId"
              hoverText="(Click to copy text!) {{row.roleInstanceId}}"
              [copyText]="row.roleInstanceId"
              displayIcon="--">
            </sk-click-to-copy>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="machineName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Machine Name</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              [displayText]="row.machineName"
              hoverText="(Click to copy text!) {{row.machineName}}"
              [copyText]="row.machineName"
              displayIcon="--">
            </sk-click-to-copy>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="comments">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Comments</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a *ngIf="row.comments"
              href=""
              [title]="row.comments > 400
                ? row.comments.substring(0,400) + '...'
                : row.comments"
              target="_blank"
              (click)="showModal(row.comments, 'Comments'); $event.preventDefault();">
              comments...
						</a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
			</mat-table>

			<div *ngIf="!dataSource || isRefreshing" class="loading-spinner">
				<mat-spinner></mat-spinner>
			</div>
		</div>
    <div *ngIf="subscriptionId != undefined" class="reindex-button-div">
      <br />
      <button mat-raised-button
        [disabled]="running"
        (click)="displayReindexSubscriptionModal()">
        Reindex Subscription
      </button>
    </div>
    <div *ngIf="subscriptionId == undefined" class="reindex-button-div">
      <br />
      <button mat-raised-button
        (click)="displayReindexOrderModal()">
        Reindex Order
      </button>
    </div>
    <div class="pager-div">
      <mat-paginator [pageSizeOptions]="[12, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
	</div>
</div>

import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import * as moment from 'moment';

import { JobDefinition } from './job-definition';
import { ApiService } from '../../api-service/api.service';
import { MessageService } from '@sk-services';
import { BackupSubscription, QueueJobResult } from '@sk-models';
import { environment } from 'environments/environment';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'sk-queue-job',
  templateUrl: './queue-job.component.html',
  styleUrls: ['./queue-job.component.scss']
})
export class QueueJobComponent {
  @Input() subscription: BackupSubscription;
  @Input() jobDefinitions: JobDefinition[];

  selectedJobType: string;
  selectedJobSubType: string;

  // time interval between heartbeats, used because when a cancellation event is received, it sets the
  // resource's machine state back to idle. The job itself doesn't actually spin down until the next heartbeat happens. We want to
  // ensure job is spun down before allowing new job to be started
  timeIntervalMinutes = 30;

  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) { }

  enqueueJob(): void {
    let jobType = this.selectedJobType;

    if (this.subscription) {
      this.apiService.fetchSnapshotMachineState(this.subscription.backupServiceId, this.subscription.resourceKey)
        .subscribe(snapshotState => {
          const isNotProduction = environment.environment !== 'prod';
          const minSinceLastUpdate = moment().diff(snapshotState.updatedTime, 'minutes');
          if (minSinceLastUpdate >= this.timeIntervalMinutes || isNotProduction) {
            this.apiService.queueSubscriptionJob(
              this.subscription.resourceKey,
              jobType)
              .subscribe(result => this.onJobQueued(result));
          } else {
            const timeDiff = this.timeIntervalMinutes - minSinceLastUpdate;
            this.messageService.openCustomModal(GenericModalComponent, `Previous job may still be running. Please wait ${timeDiff} minutes and try again`);
          }
        });
    } else if (this.activatedRoute.snapshot.paramMap.has('id')) {
      const backupServiceId = this.activatedRoute.snapshot.paramMap.get('id');
      // Discovery is an outlier, where there are different jobs per product
      // instead of different parameters on a generic job. Because of this, we
      // have to handle it a bit differently
      if (jobType === 'discovery') {
        jobType = this.selectedJobSubType + jobType;
      }

      this.apiService.queueOrderJob(
        backupServiceId,
        jobType)
        .subscribe(result => this.onJobQueued(result));
    } else {
      this.messageService.openCustomModal(GenericModalComponent, `Unable to enqueue job. Please try again or contact SWAT.`);
    }
  }

  onJobQueued(queueJobResult: QueueJobResult): void {
    let displayName = _.startCase(this.selectedJobType);
    if (this.selectedJobSubType) {
      displayName = _.startCase(this.selectedJobSubType) + ' ' + displayName;
    }

    if (queueJobResult &&
        queueJobResult.success) {
      this.messageService.openCustomModal(GenericModalComponent, `${displayName} queued up!`);
    } else {
      this.messageService.openCustomModal(GenericModalComponent, `Failed to queue up ${displayName}`);
    }
  }
}

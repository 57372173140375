import {
  AfterViewInit,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { Subscription } from 'rxjs';

import { BackupSubscription } from '@sk-models';
import {
  SubscriptionService,
} from '@sk-services';

@Component({
  selector: 'sk-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  activeTab: string;
  subscription: BackupSubscription;
  subscriptionSubscription: Subscription;

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    const lastUrlSegment = this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1] &&
      this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1].path;

    if (lastUrlSegment === 'subscription-details' ||
        lastUrlSegment === 'jobs' ||
        lastUrlSegment === 'audit' ||
        lastUrlSegment === 'subscription-health' ||
        lastUrlSegment === 'subscription-browser' ||
        lastUrlSegment === 'subscription-search' ||
        lastUrlSegment === 'subscription-reindex-history' ||
        lastUrlSegment === 'graph-journey'
    ) {
      this.activeTab = lastUrlSegment;
    } else {
      const defaultTab = 'subscription-details';
      this.router.navigateByUrl(this.router.url + `/${defaultTab}`);
      return;
    }

    this.subscriptionSubscription = this.subscriptionService.subscription$
      .subscribe(sub => {
        if (sub) {
          this.subscription = sub;
          if (!this.activatedRoute.snapshot.paramMap.has('resourceType')) {
            this.router.navigateByUrl(`orders/${sub.backupServiceId}/subscriptions/${sub.id}/${sub.resourceKey.resourceType}`);
          } else {
            this.subscriptionService.loadRestoreMachineState();
            this.subscriptionService.loadSnapshotMachineState();
            this.subscriptionService.loadPurgeMachineState();
            this.subscriptionService.loadMaintenanceMachineState();
            this.subscriptionService.loadInitialSnapshotDate();
            this.subscriptionService.loadSnapshotQueueName();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptionSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (!this.subscription ||
          this.subscription.id !== this.activatedRoute.snapshot.params['subscriptionId']) {
      this.updateSubscription();
    }
  }

  updateSubscription(): void {
    const routeParamMap = this.activatedRoute.snapshot.paramMap;
    const orderId = routeParamMap.get('id');
    const subscriptionId = routeParamMap.get('subscriptionId');
    const resourceType = routeParamMap.get('resourceType');
    this.subscriptionService.getSubscription(orderId, subscriptionId, resourceType);
  }
}

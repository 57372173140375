import {
  Directive,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { IfIframeSateBase } from './if-iframe-state.base';

/**
 * Structural Directive to explicitly show data if and only if the application is viewing viewed an iFrame
 */
@Directive({
  selector: '[skIfIframeShow]',
})
export class IfIframeShowDirective extends IfIframeSateBase {
  constructor(templateRef: TemplateRef<unknown>, viewContainer: ViewContainerRef) {
    super(templateRef, viewContainer);
    const remove = !this.windowHasParent();
    this.removeIfIFrame(remove);
  }
}

<div class="component-container">
    <div *ngIf="weeklySnapshotSuccess && lastJobSuccess" class="subscription-health">
        <div class="table-wrapper">
            <div class="table last-snapshot-success">
                <div class="row header labels">
                    <span class="type">Job Type</span>
                    <span>Last Success</span>
                </div>
                <div *ngFor="let lastSuccess of lastJobSuccess" class="row">
                    <span class="type">{{lastSuccess.jobType}}</span>
                    <span>{{lastSuccess.lastSuccess | friendlyDate}}</span>
                </div>
            </div>
        </div>
        <div class="table-wrapper">
            <div class="table weekly-success-rate">
                <div class="row header labels">
                    <span class="week">Week Of</span>
                    <span class="percent">Successful Jobs</span>
                </div>
                <div *ngFor="let percent of weeklySnapshotSuccess" class="row">
                    <span class="week">{{percent.weekOf | date:'MMM dd'}}</span>
                    <span class="percent">{{percent.successfulJobs}}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!weeklySnapshotSuccess || !lastJobSuccess" class="loading-spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>

<table class="details-table">
  <thead>
    <tr>
      <th *ngFor="let column of columnData" [ngClass]="{'action-column': column.renderTemplate == 'actions'}">
        {{column.header}}
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of data" class="row-pointer" (click)="onRowClick(row)">
      <td *ngFor="let column of columnData"
        [ngClass]="{'action-column': column.renderTemplate == 'actions', 'value': column.renderTemplate != 'actions'}">
        <ng-container *ngIf="column.renderTemplate == 'actions'">
          <button class="path-action" *ngFor="let path of row[column.field]" (click)="onActionClick($event, path, row)">
            <span>{{path.name}}</span> <mat-icon>chevron_right</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="column.renderTemplate != 'actions'">
          {{row[column.field]}}
        </ng-container>
      </td>
      <td class="chevron">
        <mat-icon>chevron_right</mat-icon>
      </td>
    </tr>
  </tbody>
</table>

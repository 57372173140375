import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';

import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import {
  filter,
  takeUntil,
} from 'rxjs/operators';

import {
  AuthUser,
  BackupOrder,
  Bookmark,
  SwatTicket,
  SwatTickets,
} from '@sk-models';
import {
  AppSettingsService,
  AppVersionService,
  AuthService,
  BaseRealUserMonitoringService,
  BookmarksService,
  SubscriptionService,
  WindowService,
} from '@sk-services';

import { ApiService } from './api-service/api.service';
import { AppThemeService } from './services/app-theme/app-theme.service';
import { SkyKickComponent } from './shared/core/skykick-component.base';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'sk-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SkyKickComponent implements OnInit, OnDestroy {
  orderId: string;
  orderName: string;
  subscriptionName: string;
  resourceType: string;
  darkTheme: boolean;
  order: BackupOrder;
  swatTickets: SwatTickets;
  isOrderNameLoading = false;
  drawerOpened = false;
  swatTicketsDrawerOpened = false;
  swatTicketsCount: string;
  openSwatTicketsCount: number;
  badgeText: string;
  currentUrl: string;

  private utcToggleEnabled = true;
  private useUtc = false;

  private readonly _destroying$ = new Subject<void>();

  get orderSelected(): boolean {
    return this.isOrderSelected(this.windowService.href);
  }

  get isDeveloper(): boolean {
    return (this.windowService.href.indexOf('developer') > 0);
  }

  get isRestoreDash(): boolean {
    return (this.windowService.href.indexOf('restores') > 0);
  }

  get isPartnersDash(): boolean {
    return (this.windowService.href.indexOf('partners') > 0);
  }

  get isIPCheckerView(): boolean {
    return (this.windowService.href.indexOf('ip-checker') > 0);
  }

  get isDataDogView(): boolean {
    return (this.windowService.href.indexOf('datadog') > 0);
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private windowService: WindowService,
    private titleService: Title,
    private subscriptionService: SubscriptionService,
    private activatedRoute: ActivatedRoute,
    private rumClient: BaseRealUserMonitoringService,
    private authService: AuthService,
    private appVersionService: AppVersionService,
    private bookmarksService: BookmarksService,
    private appThemeService: AppThemeService,
    private appSettingsService: AppSettingsService
  ) {
    super();
    this.utcToggleEnabled = this.appSettingsService.utcToggleEnabled;
    this.subs.sink = this.appSettingsService.useUtc$.subscribe((utcFlag) => {
      this.useUtc = utcFlag;
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.checkForOrderId(this.windowService.pathname);

    this.authService.authData$
      .pipe(
        filter((status: AuthUser) => status?.isAuthenticated),
        takeUntil(this._destroying$)
      )
      .subscribe((data: AuthUser) => {
        this.rumClient.initialize(environment);
        this.rumClient.setUserContext(data.username);
      });

    this.appThemeService.setCurrentTheme();

    if (this.isOrderSelected(this.windowService.href)) {
      this.loadOrder(this.orderId);
    }

    this.subscriptionService.subscription$.subscribe(x => {
      if (x) {
        this.subscriptionName = x.friendlyName;
        this.resourceType = x.resourceKey.resourceType;
        this.updateTitle();
      }
    });

    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.getCurrentUrl();

        this.checkForOrderId(x.urlAfterRedirects);

        if (!this.subscriptionSelected(x.urlAfterRedirects)) {
          this.subscriptionName = null;
          this.resourceType = null;
          this.updateTitle();
        }

        if (!this.isOrderSelected(x.urlAfterRedirects)) {
          this.orderName = null;
          this.updateTitle();
        }

        if (!this.orderName && this.isOrderSelected(x.urlAfterRedirects)) {
          const orderId = this.router.parseUrl(x.urlAfterRedirects).root.children['primary'].segments[1].path;
          this.loadOrder(orderId);
        }

        if (!this.orderName && this.isPartnersDash) {
          if (this.router.parseUrl(x.urlAfterRedirects).root.children['primary'].segments[1].path != null) {
            const partnerId = this.router.parseUrl(x.urlAfterRedirects).root.children['primary'].segments[1].path;
            this.loadPartnerName(partnerId);
          }
        }

        if (this.isRestoreDash) {
          this.loadRestoreContext();
        }
      }
    });
  }

  private checkForOrderId(url: string): void {
    const urlTree = this.router.parseUrl(url);
    const segments = urlTree.root.children['primary']?.segments;

    this.orderId = null;

    if (segments && segments.length > 2) {
      const rootIdType = segments[0].path;
      if (rootIdType == 'orders') {
        this.orderId = segments[1].path;
      }
    }
  }

  goToRoot(): void {
    const url = ``;
    this.router.navigateByUrl(url);
  }

  loadOrder(orderId: string): void {
    if (orderId === '00000000-0000-0000-0000-000000000000' || this.isOrderNameLoading) {
      return;
    }

    this.isOrderNameLoading = true;
    this.apiService.fetchOrder(orderId)
      .subscribe({
        next: o => {
          this.order = o;
          this.orderName = o.name;
          this.updateTitle();
        },
        complete: () => {
          this.isOrderNameLoading = false;
          this.bookmarksService.addToHistory({ orderId: orderId, orderName: this.orderName });
          this.getSwatTickets();
        }
      });
  }

  getSwatTickets(): void {
    this.apiService.getSwatTickets(this.order.managingPartnerName, this.order.name).subscribe({
      next: o => {
        this.swatTickets = o;
        this.openSwatTicketsCount = o.openSwatTickets.length;
      }
    })
  }

  loadPartnerName(partnerId: string): void {
    if (partnerId === '00000000-0000-0000-0000-000000000000' || this.isOrderNameLoading) {
      return;
    }

    this.isOrderNameLoading = true;
    this.apiService.fetchPartnerName(partnerId)
      .subscribe({
        next: o => {
          this.orderName = o;
          this.updateTitle();
        },
        complete: () => {
          this.isOrderNameLoading = false;
        }
      });
  }

  loadRestoreContext(): void {
    let paramOrderId: string;
    let paramPartnerId: string;
    this.activatedRoute.queryParams.subscribe(params => {
      paramOrderId = params['orderId'];
      paramPartnerId = params['partnerId'];
    });

    if (paramOrderId && paramOrderId !== '00000000-0000-0000-0000-000000000000') {
      this.apiService
        .fetchOrderName(paramOrderId)
        .subscribe(o => {
          this.orderName = o;
          this.updateTitle();
        });
    } else if (paramPartnerId) {
      this.apiService
        .fetchPartnerName(paramPartnerId)
        .subscribe(o => {
          this.orderName = o;
          this.updateTitle();
        });
    }
  }

  isOrderSelected(url: string): boolean {
    return (url.indexOf('orders/') > 0);
  }

  subscriptionSelected(url: string): boolean {
    return (url.indexOf('subscriptions/') > 0);
  }

  updateTitle(): void {
    if (this.orderName && !this.subscriptionName) {
      this.titleService.setTitle('BSP: ' + this.orderName);
    } else if (this.orderName && this.subscriptionName) {
      this.titleService.setTitle(`BSP: ${this.orderName} > ${this.subscriptionName} (${this.resourceType})`);
    } else {
      this.titleService.setTitle('Backup Support Portal');
    }
  }

  showVersionInfo(): void {
    this.darkTheme = this.appThemeService.isDarkTheme();
    this.appVersionService.showVersionDialog(this.darkTheme);
  }

  showBookmark(): void {
    this.drawerOpened = true;
  }

  onNavigate(bookmark: Bookmark): void {
    window.location.href = `/orders/${bookmark.orderId}`
    this.closeDrawer();
  }

  closeDrawer(): void {
    this.drawerOpened = false;
  }

  showSwatTickets(): void {
    this.swatTicketsDrawerOpened = true;
  }

  onNavigateSwatTicket(swatTicket: SwatTicket): void {
    window.open(swatTicket.ticketUrl, '_blank');
    this.closeSwatTicketsDrawer();
  }

  onNavigateToSwatBoard(): void {
    window.open('https://dev.azure.com/skykick/SkyKick%201/_boards/board/t/Site%20Reliability%20-%20SWAT/Stories', '_blank');
    this.closeSwatTicketsDrawer();
  }

  closeSwatTicketsDrawer(): void {
    this.swatTicketsDrawerOpened = false;
  }

  getCurrentUrl(): void {
    this.currentUrl = window.location.href;
  }

  onUtcChange(event: MatSlideToggleChange): void {
    this.appSettingsService.setUtc(event.checked);
  }

  isExpanded: boolean;
  selectedMenuItemIndex: number;
  @ViewChild('expansionNav') expansionNav: MatSidenav;
  setNavIndex(menuItemIndex: number, openSubNav: boolean): void {
    this.selectedMenuItemIndex = menuItemIndex;
    if (openSubNav) {
      this.expansionNav.open();
    } else {
      this.expansionNav.close();
    }
  }
}

import { Component, Input } from '@angular/core';
import { Promo } from '@sk-models';

@Component({
  selector: 'sk-promo-details',
  templateUrl: './promo-details.component.html',
  styleUrls: ['./promo-details.component.scss']
})
export class PromoDetailsComponent {

  @Input()
  promo: Promo;

  getIconName(value: boolean): string {
    if (value === true) {
      return 'check';
    } else if (value === false) {
      return 'close';
    } else {
      return 'question';
    }
  }

  getIconColor(value: boolean): string {
    if (value === true) {
      return 'success';
    } else if (value === false) {
      return 'error';
    } else {
      return 'warning';
    }
  }

  getColorClass(value: boolean): string {
    if (value === true) {
      return 'blue';
    } else {
      return 'gray';
    }
  }
}

import { Moment } from 'moment';

export class SearchReindexStatus {
  id: string;
  subscriptionId: string;
  subscriptionType: string;
  status: SearchReindexStatusEnum;
  startedTime: Moment;
  lastUpdatedTime: Moment;
  finishedTime: Moment;
  indexedDocumentCount: number;
  errorMessage: string;
  roleInstanceId: string;
  machineName: string;
  comments: string;

  statusIcon: string;
  statusIconClass: string;
  statusIconTitle: string;

  mappedResourceType?: string;
}

export enum SearchReindexStatusEnum {
  InProgress = 0,
  Success = 1,
  Error = 2,
  Stalled = 3,
}

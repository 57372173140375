import {
  Component,
  Input
} from '@angular/core';

import { BackupOrder } from '@sk-models';

@Component({
  selector: 'sk-order-summary-card',
  templateUrl: './order-summary-card.component.html',
  styleUrls: ['./order-summary-card.component.scss']
})
export class OrderSummaryCardComponent {
  @Input() service: BackupOrder;

  friendlierOrderState(text: string): string {
    return text.replace(/order/gi, '');
  }
}

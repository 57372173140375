<div class="page-content" *ngIf="hasData">
  <sk-widget>
    <div class="table-container">
      <div [hidden]="!dataSource">
        <mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear
          (matSortChange)="onSortChanged($event)" [hiddenColumns]="[0]">

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
              <span>Id</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let ur">
              <sk-click-to-copy hoverText="{{ur.id}}" [copyText]="ur.id">
              </sk-click-to-copy>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let ur">
              <a routerLink="/orders/{{backupServiceId}}/subscriptions/{{ur.id}}/{{ur.resourceKey.resourceType}}/subscription-details" routerLinkActive="active"
                class="clickable">
                {{getFriendlyName(ur)}}
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>
              <span>Type</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let ur">
              {{ur.resourceKey.resourceType}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="protected">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Protected</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let ur">
              <ng-container *ngIf="ur.enabled === true; else notProtected">
                <mat-icon
                  [ngClass]="{'warning': ur.disabledDueToAccess, 'success': !ur.disabledDueToAccess}"
                  [attr.title]="ur.disabledDueToAccess ? ('DisabledDueToAccess') : ('Protected')">
                  check_circle
                </mat-icon>
              </ng-container>
              <ng-template #notProtected>
                <mat-icon
                  class="muted"
                  title="Not Protected">
                  check_circle
                </mat-icon>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef mat-sort-header><span>Created</span></mat-header-cell>
            <mat-cell *matCellDef="let ur"><span>{{ur.created | friendlyDate}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="disabledDueToAccess">
            <mat-header-cell *matHeaderCellDef mat-sort-header><span>Lost Access</span></mat-header-cell>
            <mat-cell *matCellDef="let ur"><span>{{ur.disabledDueToAccess | friendlyDate}}</span></mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let ur; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>

      <div class="loading-spinner" *ngIf="isLoading == true">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="filter.size" [pageIndex]="filter.page"
      [length]="totalResults" (page)="onPageChanged($event)" showFirstLastButtons>
    </mat-paginator>
  </sk-widget>
</div>

<div class="page-content flex-container" *ngIf="isLoading && hasData == false">
  <div class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>

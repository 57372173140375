import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IGraphJourney } from 'app/graph-journey/models/graph-journey.interface';

@Component({
  selector: 'sk-graph-details',
  templateUrl: './graph-details.component.html',
  styleUrls: ['./graph-details.component.scss']
})
export class GraphDetailsComponent implements OnChanges, OnInit {

  @Input() data: unknown;
  @Input() journey: IGraphJourney;

  title: string;
  subtitle: string;
  results = [];

  ngOnInit(): void {
    this.ngOnChanges();
  }

  ngOnChanges(): void {

    this.results = [];
    if (this.journey != null && this.data != null) {
      const t = this.data[this.journey.display];
      if (t == null) {
        this.title = this.journey.name;
      } else {
        this.title = t;
        this.subtitle = this.journey.name;
      }

      for (const property in this.data as Record<string, unknown>) {
        // Ignore some of our internal tracking properties.
        if(property.startsWith("__") == false) {
          this.results.push({ name: property, value: this.data[property] });
        }
      }
    }
  }
}

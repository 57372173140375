import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BackupJob } from '@sk-models';

@Component({
  selector: 'sk-target-restore-items-modal',
  templateUrl: './target-restore-items-modal.component.html',
  styleUrls: ['./target-restore-items-modal.component.scss']
})
export class TargetRestoreItemsModalComponent {
  job: BackupJob;

  constructor(@Inject(MAT_DIALOG_DATA) data: BackupJob) {
    this.job = data;
  }
}


function flattenObj(ob: Record<string, unknown>): Record<string, unknown> {
  // The object which contains the
  // final result
  const result = {};

  // loop through the object "ob"
  for (const i in ob as Record<string, unknown>) {

    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
      const temp = this.flattenObj(ob[i]);
      for (const j in temp as Record<string, unknown>) {

        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    } else if (Array.isArray(ob[i])) {
      const temp = this.flattenObj(ob[i]);
      for (const j in temp as Record<string, unknown>) {

        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
}

function unflatten(data: Record<string, unknown>): Record<string, unknown> {
  const result = {}
  for (const i in data) {
    const keys = i.split('.');
    keys.reduce(function(r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : [])
    }, result)
  }
  return result;
}

export const ObjectUtility = {
  flattenObj,
  unflatten
}

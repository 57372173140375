import { Component, Input } from '@angular/core';

import { BackupSubscription, TreeEntity, BackupEntityType, AuthUser, authUserInRole, AuthRoles } from '@sk-models';
import { AuthService } from '@sk-services';
import { filter, take } from 'rxjs/operators';
import { ApiService } from '../../api-service/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sk-subscription-browser',
  templateUrl: './subscription-browser.component.html',
  styleUrls: ['./subscription-browser.component.scss']
})
export class SubscriptionBrowserComponent {
  private treeRoot: TreeEntity;
  private _subscription: BackupSubscription;
  private parentSkId: string;
  private skId: string;

  public isLimitedVisibilityType = false;
  public isVisible = false;
  private _authUser: AuthUser;

  @Input()
  set subscription(sub: BackupSubscription) {
    if (sub) {
      this.isLimitedVisibilityType = this.buildIsLimitedVisibilityType(sub?.resourceKey?.resourceType);
      if(this.isLimitedVisibilityType) {
        this.isVisible = this.isSubscriptionVisible();
      } else {
        this.isVisible = true;
      }

      if(this.isVisible) {
        const params = this.activatedRoute.snapshot.queryParams;
        this.parentSkId = params['parentSkId'];
        this.skId = params['skId'];
        if(this.parentSkId && this.skId)
          this.loadWalkToElement(sub, this.parentSkId, this.skId);
        else this.loadRoot(sub);
        this._subscription = sub;
      }
    }
  }

  get subscription(): BackupSubscription {
    return this._subscription;
  }

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private authService: AuthService,
      private apiService: ApiService
  ) {
    this.authService.authData$.pipe(filter(x => x != null), take(1)).subscribe((data) => this._authUser = data);
  }

  setSelectedEntityAsQueryParams(selectedTreeNode: TreeEntity): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { parentSkId: selectedTreeNode.parentId, skId: selectedTreeNode.skyKickId },
        queryParamsHandling: 'merge',
      });
  }

  loadRoot(subscription: BackupSubscription): void {
    const defaultRoot = new TreeEntity();
    defaultRoot.name = 'No Data Found';
    defaultRoot.type = BackupEntityType.Note;

    this.apiService
      .findChildrenOfSubscriptionEntity(subscription.backupServiceId, subscription.id, subscription.resourceKey.resourceType)
      .subscribe(children => {
        this.treeRoot = children ? children[0] : defaultRoot;
      }, () => {
        //If we get an error then show the default root
        this.treeRoot = defaultRoot;
      });
  }

  loadWalkToElement(subscription: BackupSubscription, parentSkyKickId: string, skyKickId: string): void {
    const defaultRoot = new TreeEntity();
    defaultRoot.name = 'No Data Found';
    defaultRoot.type = BackupEntityType.Note;

    this.apiService
      .getStructureTreeToSubscriptionEntityWithChildren(
        subscription.backupServiceId,
        subscription.id,
        subscription.resourceKey.resourceType,
        skyKickId,
        parentSkyKickId)
      .subscribe(root => {
        this.treeRoot = root ? root : defaultRoot;
      }, () => {
        //If we get an error then show the default root
        this.treeRoot = defaultRoot;
      });

  }

  buildIsLimitedVisibilityType(resourceType: string): boolean {
    return ['TeamChannelPosts', 'TeamsChats'].includes(resourceType);
  }

  isSubscriptionVisible(): boolean {
    return authUserInRole(this._authUser, AuthRoles.Engineer);
  }
}

import { Moment } from 'moment';

import { OrderState } from '@sk-models';

export class PartnerOrdersFilter {
    createdDateFrom: Moment;
    createdDateTo: Moment;
    orderState: OrderState;
    pageNumber: number;
    pageSize: number;
    sortByColumn: string;
    sortAscending: boolean;
}

<div class="component-container">
  <sk-sub-menu-links *ngIf="subscription"
    [subscription]="subscription"
    style="margin-bottom: 5px;">
  </sk-sub-menu-links>
  <sk-subscription-details *ngIf="subscription && activeTab == 'subscription-details'"
    [subscription]="subscription">
  </sk-subscription-details>

  <sk-subscription-job-history *ngIf="subscription && activeTab == 'jobs'"
    [orderId]="subscription.backupServiceId"
    [subscriptionId]="subscription.id"
    [resourceType]="subscription.resourceKey.resourceType">
  </sk-subscription-job-history>

  <sk-audit *ngIf="subscription && activeTab == 'audit'"
            [domainEntityId]="subscription.id">
  </sk-audit>

  <sk-subscription-health *ngIf="subscription && activeTab == 'subscription-health'"
    [orderId]="subscription.backupServiceId"
    [subscriptionId]="subscription.id"
    [resourceType]="subscription.resourceKey.resourceType">
  </sk-subscription-health>

  <sk-subscription-browser *ngIf="subscription && activeTab == 'subscription-browser'"
    [subscription]="subscription">
  </sk-subscription-browser>

  <div *ngIf="subscription && activeTab == 'subscription-reindex-history'">
    <sk-subscription-reindex-history
      [orderId]="subscription.backupServiceId"
      [subscriptionId]="subscription.id"
      [resourceType]="subscription.resourceKey.resourceType"
    ></sk-subscription-reindex-history>
  </div>

  <div *ngIf="subscription && activeTab == 'subscription-search'">
    <sk-subscription-search [backupSubscription]="subscription"></sk-subscription-search>
  </div>
  <div *ngIf="subscription && activeTab == 'graph-journey'">
    <sk-subscription-graph-journey></sk-subscription-graph-journey>
  </div>
</div>

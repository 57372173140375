<mat-dialog-content>
    <div class="warn-before-execution">
      <div *ngIf="warningActive">
        <h6>Are you sure you want to permanently purge the resource '{{subscription.fullName}}'?</h6>
        <button mat-raised-button class="green-button"
        (click)="showPurgeButton()">
          Yes
        </button>
        <button mat-raised-button class="red-button" [mat-dialog-close]="true">
          No
        </button>
      </div>

      <div *ngIf="canShowPurgeButton">
        <h6>Select Purge below if you are sure you want to purge the resource '{{subscription.fullName}}'.</h6>
        <button mat-raised-button class="red-button"
        (click)="purgeSubscription()">
          Purge
        </button>
        <button mat-raised-button class="green-button" [mat-dialog-close]="true">
          Cancel
        </button>
      </div>
    </div>

    <div *ngIf="isPurgeInProgress" class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="notReadyToPurge">
      <p>
        Please make sure the resource has no jobs running and is disabled before purging.
      </p>
      <button mat-raised-button class="ok-button" [mat-dialog-close]="true">
        Okay
      </button>
    </div>

    <div *ngIf="purgeResult">
      <p>{{purgeResult}}</p>
      <button *ngIf="isPurgeSuccess" mat-raised-button class="ok-button" [mat-dialog-close]="true">
        Okay
      </button>
      <button *ngIf="!isPurgeSuccess" mat-raised-button class="red-button" [mat-dialog-close]="true">
        I Acknowledge that the purge failed
      </button>

    </div>
</mat-dialog-content>

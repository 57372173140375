<div class="job-search-input">
  <form class="search-form" [formGroup]="searchForm">
    <mat-form-field class="search-input">
      <input matInput="jobSearchInput" placeholder="Lookup Job by Job Id" id="jobSearchInput" autofocus
        [matAutocomplete]="auto" formControlName="jobSearchInput">
      <mat-icon class="info-icon" matTooltip="Lookup by Job Id">info</mat-icon>
      <mat-error>Please provide a valid Job ID</mat-error>
    <mat-autocomplete class="auto-complete-panel" #auto="matAutocomplete">
      <mat-option *ngIf="isLoading">
        <mat-spinner class="spinner" diameter="50"></mat-spinner>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  </form>
</div>

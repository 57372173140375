<div class="mat-elevation-z8 grid-container">
  <sk-click-to-refresh
    class="refresh"
    [shouldDisplay]="dataSource"
    [spin]="isLoading"
    (clickEvent)="loadEvents(true)">
  </sk-click-to-refresh>

  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>
          <span>Id</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <sk-click-to-copy
            hoverText="{{row.eventId}}"
            [copyText]="row.eventId">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="eventTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Event Time</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span>{{row.eventTime | friendlyDate}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="eventType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Event Type</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span>{{row.eventType}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="properties">
        <mat-header-cell *matHeaderCellDef>
          <span>Properties</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a *ngIf="row.properties"
            href=""
            title="properties"
            target="_blank"
            (click)="showModal(row.properties, 'Properties'); $event.preventDefault();">
            Properties...
          </a>
          <span *ngIf="!row.properties">--</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <div *ngIf="isLoading" class="loading-spinner">
      <mat-spinner title="looking up events for this request id....."></mat-spinner>
    </div>
  </div>
</div>

import { Component, Directive, EventEmitter, HostListener, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[skSideDrawerContentHost]'
})
export class SideDrawerContentDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'sk-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss']
})
export class SideDrawerComponent {

  @Input() open = false;
  @Output() cancel = new EventEmitter<void>();
  @Input() drawerWidth: string = null;

  @ViewChild(SideDrawerContentDirective,
    { static: true, read: SideDrawerContentDirective }) sideDrawerContentHost: SideDrawerContentDirective;

  onCloseDrawer(): void {
    this.onHidden();
    this.cancel.emit();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.onCloseDrawer();
  }

  destroy(): void {
    // do nothing - required for service
  }

  onHidden(): void{
    // do nothing  - required for service
  }

  show(): void {
    // do nothing  - required for service
  }

}

import { Component, Input } from '@angular/core';

export interface HeaderBreadcrumb {
  title: string;
}

@Component({
  selector: 'sk-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  @Input() title: string;
  @Input() icon: string;
  @Input() breadcrumb: HeaderBreadcrumb[];
}


import { Injectable } from '@angular/core';
import { BackupEntity, BackupEntityType, TreeEntity } from '@sk-models';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class EntityDownloadService {
  private headerStr = "Type,Size,ParentSkId,SkId,NativeId,FriendlyUrl,RelativeUrl,DisplayName\r\n";
  private partialHeaderStr = "Type,Size,ParentSkId,SkId,FriendlyUrl,DisplayName\r\n";

  private saveBlob(dataStr: string, fileName: string) {
    const blob = new Blob([dataStr.toString()], { type: "text/plain;charset=utf-8" });
    saveAs(blob, fileName);
  }

  //downloads all entity fields as a csv file
  downloadEntitiesAsCsv(entities: BackupEntity[], fileName: string): void {
    let dataStr = this.headerStr;
    for (const entity of entities) {
      dataStr = dataStr
        + BackupEntityType[entity.type] + ','
        + entity.size + ','
        + entity.parentSkyKickId + ','
        + entity.skyKickId + ','
        + entity.nativeId + ','
        + entity.relativeUrl + ','
        + entity.friendlyUrl + ','
        + entity.name + '\r\n';
    }
    this.saveBlob(dataStr, fileName);
  }

  //downloads all entity fields except the relative and native id as a csv file
  downloadPartialEntitiesAsCsv(entities: BackupEntity[], fileName: string): void {
    let dataStr = this.partialHeaderStr;
    for (const entity of entities) {
      dataStr = dataStr
        + BackupEntityType[entity.type] + ','
        + entity.size + ','
        + entity.parentSkyKickId + ','
        + entity.skyKickId + ','
        + entity.friendlyUrl + ','
        + entity.name + '\r\n';
    }
    this.saveBlob(dataStr, fileName);
  }

  //downloads all tree entity fields except the relative and native id as a csv file
  downloadTreeEntitiesAsCsv(entities: TreeEntity[], fileName: string): void {
    let dataStr = this.headerStr;
    for (const entity of entities) {
      dataStr = dataStr
        + BackupEntityType[entity.type] + ','
        + entity.size + ','
        + entity.parentId + ','
        + entity.skyKickId + ','
        + entity.nativeId + ','
        + entity.relativeURL + ','
        + entity.friendlyURL + ','
        + entity.name + '\r\n';
    }
    this.saveBlob(dataStr, fileName);
  }
}

<div class="licensing-type-details">
  <div class="header">
    <p class="subtitle">{{itemType}}</p>
    <h3 class="main-title">{{item.displayName || item.id}}</h3>
    <p class="subtitle">{{item.idName}}</p>
  </div>
</div>

<sk-licensing-drawer-details [item]="item" [itemType]="itemType"
(navigateToItem)="onNavigateToItem($event)">
</sk-licensing-drawer-details>

<div class="subscription-search-input">
  <form class="search-form" [formGroup]="searchForm">
    <mat-form-field class="search-input">
      <input matInput="subscriptionSearchInput" placeholder="Lookup Subscription by Subscription Id" id="subscriptionSearchInput" autofocus
        [matAutocomplete]="auto" formControlName="subscriptionSearchInput">
      <mat-icon class="info-icon" matTooltip="Lookup by Subscription Id">info</mat-icon>
      <mat-error>Please provide a valid Subscription ID</mat-error>
    <mat-autocomplete class="auto-complete-panel" #auto="matAutocomplete" (optionSelected)="backupServiceSelected($event)">
      <mat-option *ngIf="isLoading">
        <mat-spinner class="spinner" diameter="50"></mat-spinner>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option class="auto-complete-option" *ngFor="let subscription of filteredSubscriptions" [value]="subscription">
          <sk-subscription-summary-card [subscription]="subscription"></sk-subscription-summary-card>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  </form>
</div>

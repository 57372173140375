import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';

import { JobsService } from '@sk-services';
import { ApiService } from '../../api-service/api.service';
import { BackupJob } from '@sk-models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sk-subscription-job-history',
  templateUrl: './subscription-job-history.component.html',
  styleUrls: ['./subscription-job-history.component.scss']
})
export class SubscriptionJobHistoryComponent {
  @Input() orderId: string;
  @Input() subscriptionId: string;
  @Input() resourceType: string;

  subscriptionJobs: BackupJob[];

  isRefreshingSub = new BehaviorSubject<boolean>(false);
  isRefreshing$ = this.isRefreshingSub.asObservable();

  constructor(
    private apiService: ApiService,
    private jobsService: JobsService) { }

  loadSubscriptionJobs(data: { days: number, refresh: boolean }): void {

    this.isRefreshingSub.next(true);

    this.apiService.findSubscriptionJobs(this.orderId, this.subscriptionId, this.resourceType, data.days, data.refresh)
      .pipe(map(jobs => jobs.map(j => this.jobsService.computeJobProperties(j))))
      .subscribe(jobs => {

        this.isRefreshingSub.next(false);
        this.subscriptionJobs = jobs;
      });
  }
}

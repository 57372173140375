<div class="bsp-content-container" style="width: 100%">
  <div class="d-flex warning-container">
    <span>
      <mat-icon inline class="error">
        warning
      </mat-icon>
      WARNING: This no longer reflects OneDrive discovery, but it is useful info. Use the Graph Explorer for more detailed info.
    </span>
  </div>

  <div class="d-flex flex-grow-1 title-container">
    <span class="title">Visualize OneDrives</span>
  </div>

  <div class="mat-elevation-z8 grid-container">
    <div class="table-container">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Email</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              [hidden]="!emailIsValid(row)"
              [displayText]="row.email"
              [hoverText]="row.email + ' (click to copy)'"
              [copyText]="row.email">
            </sk-click-to-copy>
            <mat-icon
              inline
              [hidden]="emailIsValid(row)"
              class="error"
              [title]="emailInvalidMessage()">
              warning
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="oneDriveDisplayName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Display Name</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              [hidden]="!oneDriveDisplayNameIsValid(row)"
              [hoverText]="row.oneDriveDisplayName + ' (click to copy)'"
              [displayText]="row.oneDriveDisplayName"
              [copyText]="row.oneDriveDisplayName">
            </sk-click-to-copy>
            <mat-icon
              inline
              [hidden]="oneDriveDisplayNameIsValid(row)"
              class="error"
              [title]="oneDriveDisplayNameInvalidMessage()">
              warning
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isOneDriveProvisioned">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Provisioned</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon
              inline
              [hidden]="!row.isOneDriveProvisioned"
              class="success"
              title="Provisioned">
              done
            </mat-icon>
            <mat-icon
              inline
              [hidden]="row.isOneDriveProvisioned"
              title="Unprovisioned">
              done
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="oneDriveUrl">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>OneDrive Url</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              [hidden]="!oneDriveUrlIsValid(row)"
              [hoverText]="row.oneDriveUrl + ' (click to copy)'"
              [displayIcon]="'done'"
              class="success"
              [copyText]="row.oneDriveUrl">
            </sk-click-to-copy>
            <mat-icon
              inline
              [hidden]="oneDriveUrlIsValid(row)"
              class="error"
              [title]="oneDriveUrlInvalidMessage()">
              warning
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="loginName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Login Name</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              [hidden]="!loginNameIsValid(row)"
              [hoverText]="row.loginName + ' (click to copy)'"
              [displayIcon]="'done'"
              class="success"
              [copyText]="row.loginName">
            </sk-click-to-copy>
            <mat-icon
              inline
              [hidden]="loginNameIsValid(row)"
              class="error"
              [title]="loginNameInvalidMessage()">
              warning
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="idIssuer">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Id Issuer</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy
              [hidden]="!nameIdIssuerIsValid(row)"
              [hoverText]="row.idIssuer + ' (click to copy)'"
              [displayIcon]="'done'"
              class="success"
              [copyText]="row.idIssuer">
            </sk-click-to-copy>
            <sk-click-to-copy
              [hidden]="nameIdIssuerIsValid(row)"
              [hoverText]="nameIdIssuerInvalidMessage(row) + ' (click to copy)'"
              [displayIcon]="'warning'"
              class="error"
              [copyText]="row.idIssuer">
            </sk-click-to-copy>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>

      <div *ngIf="isLoading" class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { BackupJob } from '@sk-models';
import { ApiService } from 'app/api-service/api.service';

@Component({
  selector: 'sk-job-requested-by',
  templateUrl: './job-requested-by.component.html',
  styleUrls: ['./job-requested-by.component.scss']
})
export class JobRequestedByComponent implements OnInit {
  @Input() job: BackupJob;
  isLoading = false;
  requesterName: string;
  displayText: string;
  notTracked = 'Requester Not Tracked';

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    if (this.job &&
        this.job.requester &&
        this.job.requester.userId) {
      this.isLoading = true;
      this.apiService.fetchContactName(this.job.requester.userId, '$select=ContactName')
        .subscribe({
          next: result => {
            this.requesterName = result.contactName;
            this.displayText = this.buildDisplayText();
          },
          complete: () => this.isLoading = false
        });
    } else {
      this.displayText = this.notTracked;
    }
  }

  buildDisplayText(): string {
    let returnText = '';
    if (this.requesterName) {
      returnText += this.requesterName;
    }

    if (returnText !== '' && this.job.requester.initiatedFrom) {
      returnText += ' -- ';
    }

    if (this.job.requester && this.job.requester.initiatedFrom) {
      returnText += this.job.requester.initiatedFrom;
    }

    if (returnText !== '') {
      return returnText;
    } else {
      return this.notTracked;
    }
  }
}

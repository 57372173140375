import { Component, Input } from '@angular/core';

@Component({
  selector: 'sk-users-groups-status-indicator',
  templateUrl: './users-groups-status-indicator.component.html',
  styleUrls: ['./users-groups-status-indicator.component.scss']
})
export class UsersGroupsStatusIndicatorComponent {
  @Input() enabled = false;
  get isEnabled(): boolean {
    return this.enabled;
  }

  @Input() overridden = false;
  get isOverridden(): boolean {
    return this.overridden;
  }

  @Input() scope = '';

  @Input() disabledDueToAccess?: moment.Moment;
}

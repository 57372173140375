<div class="swat-tickets-counts">
  <div>
    <div class="swat-tickets-count"> {{swatTickets.openSwatTickets.length}} </div>
    <div>Open</div>
  </div>
  <div>
    <div class="swat-tickets-count"> {{swatTickets.recentlyClosedSwatTickets.length}}</div>
    <div>Recently Closed</div>
  </div>
</div>
<div>
  <div class="side-drawer-sub-section-header">
    Open
  </div>
  <div class="side-drawer-content">
    <div *ngIf="swatTickets.openSwatTickets == null || swatTickets.openSwatTickets.length == 0" class="empty-state">
      <h5>No Open SWAT Tickets</h5>
    </div>
    <div *ngFor="let openSwatTicket of swatTickets.openSwatTickets" class="element mat-elevation-z6 swat-tickets-entry"
      (click)="onNavigateSwatTicket(openSwatTicket)">
      <div class="entry-name">
        <div>
          <div class="swat-tickets-entry-wrapper">
            <div class="swat-ticket-title">{{openSwatTicket.simpleTitle}}</div>
            <span class="swat-ticket-details">
              <mat-icon [ngClass]="{
                'new-ticket-state' : openSwatTicket.state == 'New',
                'triaged-ticket-state' : openSwatTicket.state == 'BeingTriaged',
                'accepted-ticket-state' : openSwatTicket.state == 'Accepted',
                'active-ticket-state' : openSwatTicket.state == 'Active',
                'resolved-ticket-state' : openSwatTicket.state == 'Resolved',
                'blocked-ticket-state' : openSwatTicket.state == 'Blocked'
            }" title="{{openSwatTicket.state}}">
                fiber_manual_record
              </mat-icon>
            </span>
          </div>
          <br>
          <div class="swat-tickets-entry-wrapper">
            <div class="swat-tickets-age">{{getSwatTicketAge(openSwatTicket.creationDate)}}</div>
            <div class="swat-ticket-details">{{openSwatTicket.assignedTo}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="side-drawer-sub-section-header">
    Recently Closed
  </div>
  <div class="side-drawer-content">
    <div *ngIf="swatTickets.recentlyClosedSwatTickets == null || swatTickets.recentlyClosedSwatTickets.length == 0"
      class="empty-state">
      <h5>No Recently Closed SWAT Tickets</h5>
    </div>
      <div *ngFor="let recentlyClosedSwatTicket of swatTickets.recentlyClosedSwatTickets" class="element mat-elevation-z6 swat-tickets-entry"
        (click)="onNavigateSwatTicket(recentlyClosedSwatTicket)">
        <div class="entry-name">
          <div>
            <div class="swat-tickets-entry-wrapper">
              <div class="swat-ticket-title">{{recentlyClosedSwatTicket.simpleTitle}}</div>
              <span class="swat-ticket-details">
                <div class="swat-tickets-age" title="{{recentlyClosedSwatTicket.creationDate}}">
                  {{getSwatTicketAge(recentlyClosedSwatTicket.creationDate)}}</div>
              </span>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<div class="search-form-container">
  <form [formGroup]="searchForm" class="search-form">
    <mat-form-field class="search-input">
      <input matInput placeholder="Find Partner" [matAutocomplete]="auto" formControlName="searchInput">
      <mat-icon class="info-icon" matTooltip="Search by Name or Id">info</mat-icon>
    </mat-form-field>

    <mat-autocomplete
      class="auto-complete-panel"
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="partnerSelected($event)">
      <mat-option *ngIf="isLoading">
        <mat-spinner class="spinner" diameter="50"></mat-spinner>
      </mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option class="auto-complete-option" *ngFor="let partner of filteredPartners" [value]="partner">
          <sk-partner-card [partner]="partner"></sk-partner-card>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </form>
</div>

import { BackupEntityType } from './backup-enums';

export class BackupEntity {
  size: number;
  type: BackupEntityType;
  friendlyUrl: string;
  relativeUrl: string;
  name: string;
  skyKickId: string;
  parentSkyKickId: string;
  nativeId: string;
}

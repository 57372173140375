<div class="widget">
  <ng-container *ngIf="collapsible === true; else nonCollapsibleContent">
    <ng-container *ngTemplateOutlet="collapsibleContent"></ng-container>
  </ng-container>

  <ng-template #collapsibleContent>
    <mat-accordion>
      <mat-expansion-panel [expanded]="expanded" (opened)="onExpand($event)">
        <mat-expansion-panel-header>
          <ng-container *ngTemplateOutlet="header"></ng-container>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="body"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-template>

  <ng-template #nonCollapsibleContent>
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container *ngTemplateOutlet="body"></ng-container>
  </ng-template>

  <ng-template #header>
    <sk-section-header class="section-header" [title]="title" [info]="info">
      <div class="d-flex" slot="title-prefix">
        <ng-content select="[slot=title-prefix]"></ng-content>
      </div>
      <ng-content select="[slot=header-actions]"></ng-content>
    </sk-section-header>
  </ng-template>

  <ng-template #body>
    <div class="widget-content" [ngClass]="{ 'no-border': noBorder }">
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>

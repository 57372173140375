<div class="product-details-container">
  <div *ngIf="!machineState" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!machineState?.machineId">
    <span>Resource not registered.</span>
  </div>

  <div class="product-details">
    <div class="product-title">
      <span>{{machineTitle}}</span>
      <span *ngIf="machineState?.stateBag !=='{}'"
        title="State Bag"
        class="clickable stateBag"
        (click)="openDialog(machineState)">
        <mat-icon class="state-bag">shopping_bag</mat-icon>
      </span>
      <ng-content></ng-content>
    </div>

    <div *ngIf="machineState?.machineId" class="details-table">
      <div id="machineid">
        <span class="label">Machine Id:</span>
        <span class="d-flex machine-id-container">
          <span class="machine-id">{{ machineState.machineId }}</span>
          <sk-click-to-copy
            class="clickable"
            [hoverText]="machineState.machineId"
            [copyText]="machineState.machineId">
          </sk-click-to-copy>
        </span>
      </div>
      <div id="type">
        <span class="label">State:</span>
        <span>{{machineState.state | startCase}}</span>
      </div>
      <div id="updated">
        <span class="label">Updated On:</span>
        <span>{{machineState.updatedTime | friendlyDate}}</span>
      </div>
    </div>
  </div>
</div>

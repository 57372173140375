<div>
	<sk-reindex-history
		[orderId]="orderId"
		[subscriptionId]="subscriptionId"
		[resourceType]="resourceType"
		[filter]="filterData"
		[jobs]="jobs"
		[isRefreshing]="isRefreshing"
		(refreshData)="onRefreshData()">
			<sk-reindex-history-filter
				[jobStatuses]="jobStatuses" (filterChanged)="onFilterChanged($event)">
			</sk-reindex-history-filter>
	</sk-reindex-history>
</div>

import { Injectable } from '@angular/core';

import {
  BackupOrder,
  OrderState,
  PlacementSource,
} from '@sk-models';

@Injectable()
export class OrderService {

  computeOrderProperties(order: BackupOrder): BackupOrder {
    switch (order.state) {
    case OrderState.Active:
      order.stateIconTitle = OrderState.Active;
      order.stateIconClass = 'success';
      order.stateIcon = 'check_circle_outline';
      break;
    case OrderState.Suspended:
      order.stateIconTitle = OrderState.Suspended;
      order.stateIconClass = 'success';
      order.stateIcon = 'pause_circle_outline';
      break;
    case OrderState.Archived:
      order.stateIconTitle = OrderState.Archived;
      order.stateIconClass = 'success';
      order.stateIcon = 'pause_circle_outline';
      break;

    case OrderState.OrderPending:
      order.stateIconTitle = OrderState.OrderPending;
      order.stateIconClass = 'warning';
      order.stateIcon = 'hourglass_empty';
      break;
    case OrderState.StateNotDefined:
      order.stateIconTitle = OrderState.StateNotDefined;
      order.stateIconClass = 'warning';
      order.stateIcon = 'help_outline';
      break;

    case OrderState.DisabledDueToNonpayment:
      order.stateIconTitle = OrderState.DisabledDueToNonpayment;
      order.stateIconClass = 'error';
      order.stateIcon = 'stop_circle';
      break;
    case OrderState.Inactive:
      order.stateIconTitle = OrderState.Inactive;
      order.stateIconClass = 'error';
      order.stateIcon = 'stop_circle';
      break;
    case OrderState.Cancelled:
      order.stateIconTitle = OrderState.Cancelled;
      order.stateIconClass = 'error';
      order.stateIcon = 'stop_circle';
      break;
    case OrderState.Deactivated:
      order.stateIconTitle = OrderState.Deactivated;
      order.stateIconClass = 'error';
      order.stateIcon = 'stop_circle';
      break;

    case OrderState.PurgeRequested:
      order.stateIconTitle = OrderState.PurgeRequested;
      order.stateIconClass = 'warning';
      order.stateIcon = 'cancel';
      break;
    case OrderState.Purging:
      order.stateIconTitle = OrderState.Purging;
      order.stateIconClass = 'warning';
      order.stateIcon = 'cancel';
      break;
    case OrderState.Purged:
      order.stateIconTitle = OrderState.Purged;
      order.stateIconClass = 'error';
      order.stateIcon = 'cancel';
      break;
    }

    switch (order.placementSource) {
    case PlacementSource.WebPlanner:
      order.placementSourceTitle = 'Partner Portal';
      order.placementSourceAbbreviation = 'PP';
      break;
    case PlacementSource.Attach:
      order.placementSourceTitle = 'Backup Attach';
      order.placementSourceAbbreviation = 'Att';
      break;
    case PlacementSource.Syndication:
      order.placementSourceTitle = 'Syndication';
      order.placementSourceAbbreviation = 'Syn';
      break;
    case PlacementSource.PI:
      order.placementSourceTitle = 'Partner Integration';
      order.placementSourceAbbreviation = 'PI';
      break;
    case PlacementSource.PIAmbigueous:
      order.placementSourceTitle = 'Partner Integration/Ambiguous';
      order.placementSourceAbbreviation = 'PI?';
      break;
    case PlacementSource.Marketplace:
      order.placementSourceTitle = 'Marketplace';
      order.placementSourceAbbreviation = 'Mkt';
      break;
    case PlacementSource.SourceNotDefined:
      order.placementSourceTitle = 'Order source not recognized';
      order.placementSourceAbbreviation = '?';
      break;
    }

    return order;
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'sk-users-groups-search-filter',
  templateUrl: './users-groups-search-filter.component.html',
  styleUrls: ['./users-groups-search-filter.component.scss']
})
export class UsersGroupsSearchFilterComponent implements OnInit, AfterViewInit {

  @Output() filterChange = new EventEmitter<SearchFilter>();
  @ViewChild('input') input: ElementRef;

  filterForm: FormGroup;
  debounceTime = 300;
  value: string;

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      term: new FormControl("")
    });
  }

  ngAfterViewInit(): void {
    merge(
      fromEvent(this.input.nativeElement, 'keyup'),
      this.filterForm.valueChanges)
      .pipe(
        // Delay all form submits, need for search and
        // unnoticable delay for filters
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.filterChange.emit(this.filterForm.value);
        })
      )
      .subscribe();
  }

  resetFilterControl(): void {
    this.filterForm.reset();
  }
}

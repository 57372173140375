<div *ngIf="isVisible">
  <h3>Elastic Search</h3>
  <p>Here you can query the entity fields in the elastic search index. Wildcards can be used in place of non alpha-numeric characters.</p>
</div>
<div class="bsp-content-container" [hidden]="!isVisible"> <!--Using hidden as mat paginator doesnt support ngIf-->
    <form [formGroup]="searchForm">
    <mat-grid-list [cols]="5" rowHeight="75px" class="filter-selectors">
      <mat-grid-tile [rowspan]="1" [colspan]="2">
        <mat-form-field class="search-form-field">
            <input matInput placeholder="Search entity name and skId or all fields" formControlName="searchInput" #input>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [rowspan]="1" [colspan]="1">
        <button title="Search the name and SkyKick Id in the elastic search data" mat-raised-button (click)="searchElastic(false)">
          <mat-icon>search</mat-icon>
          <span>Name & SkId</span>
        </button>
      </mat-grid-tile>

      <mat-grid-tile [rowspan]="1" [colspan]="1">
        <button title="Search against all entity fields in the elastic search data" mat-raised-button (click)="searchElastic(true)">
          <mat-icon>search</mat-icon>
          <span>All Fields</span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form *ngIf="!isRefreshing" [formGroup]="filterForm" class="filter-form-content">
        <mat-grid-list [cols]="5" rowHeight="75px" class="filter-selectors">
          <mat-grid-tile [rowspan]="1" [colspan]="2">
            <mat-form-field class="search-form-field">
              <input matInput placeholder="Filter on entity name contains text" formControlName="textInput">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <mat-form-field>
              <mat-label>Resource Type</mat-label>
              <mat-select formControlName="resourceType">
                <mat-option [value]="'All'">All</mat-option>
                <mat-option *ngFor="let resourceType of resourceTypes" [value]="resourceType">
                  {{resourceType ? resourceType : '--'}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <button mat-fab extended (click)="resetCurrentFilter()">
              <mat-icon>clear</mat-icon>
              Reset Filters
            </button>
          </mat-grid-tile>
          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <button title="Download the filtered result set as a comma seperated file" mat-raised-button (click)="downloadData()">
              <mat-icon>save_alt</mat-icon>
              <span>Download</span>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="mat-elevation-z8 grid-container">
    <div class="table-container">
      <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container matColumnDef="iconName">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Type</span></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="icon">
              <mat-icon
                class="clickable expand-icon"
                title="Click to see more details."
                (click)="clickedChevron(row)"
                *ngIf="!row.expandedRow">
                keyboard_arrow_down
              </mat-icon>
              <mat-icon
                class="clickable expand-icon"
                title="Click to close."
                (click)="clickedChevron(row)"
                *ngIf="row.expandedRow">
                keyboard_arrow_up
              </mat-icon>
            </div>
            <mat-icon>{{row.iconName}}</mat-icon></mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Name</span></mat-header-cell>
          <mat-cell *matCellDef="let row"><span>{{row.name}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="friendlyUrl">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Friendly Url</span></mat-header-cell>
          <mat-cell *matCellDef="let row"><span>{{row.friendlyUrl}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="skyKickId">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>SkId</span></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy hoverText="{{row.skyKickId}}" [copyText]="row.skyKickId">
            </sk-click-to-copy>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="parentSkyKickId">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Parent SkId</span></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <sk-click-to-copy hoverText="{{row.parentSkyKickId}}" [copyText]="row.parentSkyKickId">
            </sk-click-to-copy>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="size">
          <mat-header-cell *matHeaderCellDef mat-sort-header><span>Size</span></mat-header-cell>
          <mat-cell *matCellDef="let row"><span>{{row.size}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="browseSubscriptionLink">
          <mat-header-cell *matHeaderCellDef><span>Mirror Tree</span></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>
              <a href="{{row.browseSubscriptionLink}}" target="_blank">browse</a>
            </span>
          </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" class="expand-element-detail" [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
            <sk-entity-detail
              *ngIf="row == expandedRow"
              [entity]="row"
              (expandEvent)="expandEventHandler($event)"></sk-entity-detail>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;"
          class="expand-element-row"
          [class.expand-expanded-row]="expandedRow === row">
        </mat-row>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
          class="expand-detail-row">
        </tr>


      </mat-table>

      <div *ngIf="loading$ | async" class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>

    </div>
    <div class="pager-div">
      <mat-paginator [pageSizeOptions]="[12, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
<sk-no-data *ngIf="!isVisible" header="Not Authorized" subheader="You are not authorized to use this tool"></sk-no-data>

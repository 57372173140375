/** Returns the stringified property name of given class' property.
  *
  * @remarks
  * The benefit of using this over hard coding is that you get type-checking,
  * intellisense, and compil-time errors if the given proprty is not a part
  * of the given class.
  *
  * @param property - The name of the property to stringify
  * @param T - The class type the property is a part of
  * @returns The stringified property name
*/
export function GetPropertyName<T>(property: keyof T): keyof T {
  return property;
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SkyKickApiService } from 'app/services/skykick-api/skykick-api.service';
import { UsersGroupsSearchFilter } from '../models/users-groups-search.model';
import { TeamsGroups } from '../models/teamsgroups.model';
import { ODataResponse } from '@sk-models';
import { odataQuery } from 'odata-fluent-query';
import { CollaborationResource } from '../models/collaboration-resource.model';

@Injectable()
export class CollaborationService {
  constructor(private apiService: SkyKickApiService) { }

  public searchTeamsGroups(orderId: string, isTeam: boolean, filter: UsersGroupsSearchFilter, reload = false): Observable<ODataResponse<TeamsGroups>> {
    const path = `${environment.odata_url()}/teamsgroups`;

    let query = odataQuery<TeamsGroups>()
      .filter(x => x.backupServiceId.equals(orderId))
      .paginate(filter.size, filter.page);

    query = query
      .filter(tg => tg.isTeam.equals(isTeam));

    if (filter.term && filter.term.length > 0) {
      const encodedSearchText = encodeURIComponent(filter.term);
      query = query
        .filter(tg => tg.name.contains(encodedSearchText).or(
          tg.alias.contains(encodedSearchText)).or(
          tg.displayName.contains(encodedSearchText)).or(
          tg.searchableMetadata.contains(encodedSearchText)).or(
          tg.id.equals(this.isGuid(encodedSearchText) ? encodedSearchText : null)).or(
          tg.nativeId.equals(this.isGuid(encodedSearchText) ? encodedSearchText : null)));
    }

    query = query.select(
      x => x.id,
      x => x.backupServiceId,
      x => x.nativeId,
      x => x.name,
      x => x.alias,
      x => x.displayName,
      x => x.created,
      x => x.enabled,
      x => x.isTeam,
      x => x.providerInfo);

    if (filter.sortDirection === 'desc' ||
        filter.sortDirection === 'asc') {
      if (filter.sort == "protected"){
        query = query
          .orderBy("enabled" as keyof TeamsGroups, filter.sortDirection);
      } else {
        query = query.orderBy(filter.sort as keyof TeamsGroups, filter.sortDirection)
      }
    }

    return this.apiService.makeRequest('GET',
      `${path}?${query.toString()}`,
      null,
      null,
      reload);
  }

  public getTeamsGroups(orderId: string, teamsGroupsId: string, reload = false): Observable<ODataResponse<TeamsGroups>> {
    const path = `${environment.odata_url()}/teamsgroups`;

    const query = odataQuery<TeamsGroups>()
      .filter(x =>
        x.backupServiceId.equals(orderId).and(
          x.id.equals(teamsGroupsId))
      );

    return this.apiService.makeRequest('GET',
      `${path}?${query.toString()}`,
      null,
      null,
      reload);
  }

  public searchCollaborationResources(orderId: string, filter: UsersGroupsSearchFilter, reload = true): Observable<ODataResponse<CollaborationResource>> {
    const path = `${environment.odata_url()}/collaborationresources`;

    let query = odataQuery<CollaborationResource>()
      .filter(x => x.backupServiceId.equals(orderId))
      .paginate(filter.size, filter.page);

    if (filter.term && filter.term.length > 0) {
      const encodedSearchText = encodeURIComponent(filter.term);

      if (this.isGuid(encodedSearchText)) {
        query = query
          .filter(userRes => userRes.name.contains(encodedSearchText).or(
            userRes.searchableMetadata.contains(encodedSearchText)).or(
            userRes.id.equals(encodedSearchText)).or(
            userRes.nativeId.equals(encodedSearchText)));
      } else {
        query = query
          .filter(userRes => userRes.name.contains(encodedSearchText).or(
            userRes.searchableMetadata.contains(encodedSearchText)));
      }
    }

    if (filter.subscriptionType) {
      query = query
        .filter(userRes => userRes.resourceKey.subscriptionType.equals(filter.subscriptionType));
    }

    if (filter.sortDirection === 'desc' ||
      filter.sortDirection === 'asc') {
      if (filter.sort == "protected"){
        query = query
          .orderBy("enabled" as keyof CollaborationResource, filter.sortDirection);
      } else {
        query = query.orderBy(filter.sort as keyof CollaborationResource, filter.sortDirection)
      }
    }

    return this.apiService.makeRequest('GET',
      `${path}?${query.toString()}`,
      null,
      null,
      reload);
  }

  private isGuid(str: string): boolean {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return regex.test(str);
  }
}

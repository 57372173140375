<div *ngIf="hasData">
  <sk-widget>
    <div class="table-container">
      <div [hidden]="!dataSource">
        <mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear
          (matSortChange)="onSortChanged($event)" [hiddenColumns]="[0]">

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
              <span>Id</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <sk-click-to-copy hoverText="{{uag.id}}" [copyText]="uag.id">
              </sk-click-to-copy>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <a routerLink="/orders/{{backupServiceId}}/usersgroups/groups/{{uag.id}}" routerLinkActive="active"
                class="clickable">
                {{uag.name}}
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef><span>Type</span></mat-header-cell>
            <mat-cell *matCellDef="let uag"><span>{{getGroupTypeName(uag.groupType)}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="protected">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Protected</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let uag">
              <sk-users-groups-status-indicator
                [enabled]="uag.enabled"
                [overridden]="uag.overridden"
                [disabledDueToAccess]="uag.disabledDueToAccess"
                scope="Group"
              ></sk-users-groups-status-indicator>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef mat-sort-header><span>Created</span></mat-header-cell>
            <mat-cell *matCellDef="let uag"><span>{{uag.created | friendlyDate}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="disabledDueToAccess">
            <mat-header-cell *matHeaderCellDef mat-sort-header><span>Lost Access</span></mat-header-cell>
            <mat-cell *matCellDef="let uag"><span>{{uag.disabledDueToAccess | friendlyDate}}</span></mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let uag; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>

      <div class="loading-spinner" *ngIf="isLoading == true">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="filter.size" [pageIndex]="filter.page"
      [length]="totalResults" (page)="onPageChanged($event)" showFirstLastButtons>
    </mat-paginator>
  </sk-widget>
</div>
<div class="page-content flex-container" *ngIf="isLoading && hasData == false">
  <div class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>

import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'sk-tree-viewer-search',
  templateUrl: './tree-viewer-search.component.html',
  styleUrls: ['./tree-viewer-search.component.scss']
})
export class TreeViewerSearchComponent {

  searchForm: FormGroup = new FormGroup({ childItemName: new FormControl('') });

  @Output() Search = new EventEmitter<string>();
  @Output() Download = new EventEmitter<void>();

  onFormSubmit(): void {
    this.emitChange();
  }

  downloadClicked(): void {
    this.Download.emit();
  }

  resetSearch(): void {
    this.searchForm.reset();
    this.emitChange();
  }

  private emitChange() {
    this.Search.emit(this.searchForm.value.childItemName);
  }
}

import { Component, OnInit, ViewChild, Input, HostBinding } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ApiService } from '../../api-service/api.service';
import { TenantUser } from '@sk-models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sk-tenant-users',
  templateUrl: './tenant-users.component.html',
  styleUrls: ['./tenant-users.component.scss']
})
export class TenantUsersComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  @Input() orderId: string;
  @ViewChild(MatSort) sort: MatSort;
  isLoading = false;
  displayedColumns: string[] = [
    'email',
    'oneDriveDisplayName',
    'isOneDriveProvisioned',
    'oneDriveUrl',
    'loginName',
    'idIssuer'
  ];
  dataSource: MatTableDataSource<TenantUser>;

  constructor(private apiService: ApiService,
    private route: ActivatedRoute) {
    if (!this.orderId) {
      this.orderId = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
    this.loadTenantUsers();
  }

  loadTenantUsers(): void {
    this.isLoading = true;
    this.apiService.findTenantUsers(this.orderId)
      .subscribe({
        next: users => {
          this.dataSource = new MatTableDataSource(users);
          this.dataSource.sort = this.sort;
        },
        complete: () => this.isLoading = false
      });
  }

  emailInvalidMessage(): string {
    return 'SharePoint User Profile did not return an email. ' +
      'This field correspondes to the Work Email field, and we filter out records that do not have this value.';
  }
  emailIsValid(user: TenantUser): boolean {
    return user.email ? true : false;
  }

  oneDriveDisplayNameInvalidMessage(): string {
    return 'SharePoint People Manager did not return a Display Name. ' +
      'User may not be licensed for OneDrive';
  }
  oneDriveDisplayNameIsValid(user: TenantUser): boolean {
    return user.oneDriveDisplayName ? true : false;
  }

  oneDriveUrlInvalidMessage(): string {
    return 'SharePoint People Manager did not return a Personal Url. ' +
      'User may not be licensed for OneDrive';
  }
  oneDriveUrlIsValid(user: TenantUser): boolean {
    return user.oneDriveUrl ? true : false;
  }

  loginNameInvalidMessage(): string {
    return 'SharePoint User Profile did not return a Login Name. ' +
      'We can\'t query the People Manager for OneDrive properties.';
  }
  loginNameIsValid(user: TenantUser): boolean {
    return user.loginName ? true : false;
  }

  nameIdIssuerInvalidMessage(user: TenantUser): string {
    if (user.idIssuer) {
      return `SharePoint User Profile returned ${user.idIssuer}, which is not a valid Name Id Issuer. ` +
        'This may not be a valid AAD User.';
    } else {
      return 'SharePoint User Profile did not return a valid Name Id Issuer. ' +
        'This may not be a valid AAD User.';
    }
  }
  nameIdIssuerIsValid(user: TenantUser): boolean {
    return user.idIssuer && user.idIssuer === 'urn:federation:microsoftonline'
      ? true : false;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '@sk-services';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { Group } from 'app/users-groups/models/group.model';
import { UserProviderInfo, User } from 'app/users-groups/models/user.model';
import { UsersGroupsService } from 'app/users-groups/services/users-groups.service';
import { GroupsSearchGridComponent } from '../../users-groups-group/users-groups-group-search/groups-search-grid/groups-search-grid.component';
import { SearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { ResourceKey } from '@sk-models';

@Component({
  selector: 'sk-users-groups-user-detail',
  templateUrl: './users-groups-user-detail.component.html',
  styleUrls: ['./users-groups-user-detail.component.scss']
})
export class UsersGroupsUserDetailComponent extends SkyKickComponent implements OnInit{
  public isLoading = true;
  public backupServiceId: string;
  public userId: string;
  public userDetails: User = null;
  public availableResources: ResourceKey[] = [];
  public groupMembership: Group[];

  public isUserActionRunning = false;
  public userActionMessage: string = null;

  @ViewChild('groupMembershipSearch') groupMembershipSearch: GroupsSearchGridComponent;

  constructor(
    private route: ActivatedRoute,
    private usersGroupsService: UsersGroupsService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.backupServiceId = this.route.parent.snapshot.paramMap.get('id');
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loadUserData(this.backupServiceId, this.userId);
  }

  loadUserData(backupServiceId: string, userId: string, reload = false): void {
    this.isLoading = true;
    this.subs.sink = this.usersGroupsService
      .getUser(backupServiceId, userId, reload)
      .subscribe((details) => {
        this.userDetails = details.value[0];
        this.availableResources = this.buildAvailableResources(this.userDetails.providerInfo);
      },
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      });
  }

  buildAvailableResources(providerInfo: UserProviderInfo): ResourceKey[] {
    const resources: ResourceKey[] = [];
    if (providerInfo.oneDrive?.resourceKey) {
      resources.push(providerInfo.oneDrive.resourceKey);
    }
    if (providerInfo.exchange?.resourceKey) {
      resources.push(providerInfo.exchange.resourceKey);
    }
    if (providerInfo.teamsChats?.resourceKey) {
      resources.push(providerInfo.teamsChats.resourceKey);
    }
    return resources;
  }

  confirmUserPurge(): void {
    const message = "Are you sure you want to PURGE this user?  This action will preform a purge of this users resources and user entry.  This cannot be undone.";
    this.userActionMessage = "Purging User Data...";
    this.messageService.openConfirmModal(message).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isUserActionRunning = true;
        this.usersGroupsService.purgeUser(this.backupServiceId, this.userId).subscribe(() => {
          this.isUserActionRunning = false;
          this.loadUserData(this.backupServiceId, this.userId, true);
          this.messageService.openModal("User purge success");
        }, () => {
          this.isUserActionRunning = false;
          this.messageService.openModal("User purge failed, please contact Backup.");
        });
      }
    });
  }

  private groupMembershipExpanded = false;
  refreshGroupMembership(): void {
    if (this.groupMembershipExpanded === false) {
      this.groupMembershipSearch.refresh({ term: '' });
      this.groupMembershipExpanded = true;
    }
  }

  onGroupSearchFilterChanged(filter: SearchFilter): void {
    this.groupMembershipSearch.refresh(filter);
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BackupOrder } from '@sk-models';
import { OrderService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';


interface OrderTypeMappingData {
  placeholder: string,
  tooltip: string,
  dataRequest: (term: string) => Observable<BackupOrder[]>;
}

interface OrderTypeMapping {
  [key: string]: OrderTypeMappingData
}

@Component({
  selector: 'sk-order-search-input',
  templateUrl: './order-search-input.component.html',
  styleUrls: ['./order-search-input.component.scss']
})
export class OrderSearchInputComponent implements OnInit, OnChanges {

  @Input() mode: 'backup' | 'vanityDomain' | 'marketplaceOrder';
  @Output() backupSelect = new EventEmitter<BackupOrder>();

  searchForm: FormGroup = new FormGroup({ backupSearchInput: new FormControl('') });
  filteredBackupServices: BackupOrder[] = [];
  isLoading = false;
  selected = 'backup';
  timeDelay = 300;
  isSubscriptionSearch: boolean;

  public inputMapping: OrderTypeMapping = {
    backup: {
      placeholder: 'Find Backup Service',
      tooltip: 'Search by Name, O365 Domain, Backup Service Id, or Crm Order Id',
      dataRequest: (term: string): Observable<BackupOrder[]> => this.apiService.findBackupServices(term)
    },
    vanityDomain: {
      placeholder: 'Find Backup Service by Vanity Domain',
      tooltip: 'Search by Vanity Domain of all discovered email addresses',
      dataRequest: (term: string): Observable<BackupOrder[]> => this.apiService.findBackupServicesByVanityDomain(term)
    },
    marketplaceOrder: {
      placeholder: 'Find Backup Service by Marketplace Order Id',
      tooltip: 'Search by Marketplace Order Id',
      dataRequest: (term: string): Observable<BackupOrder[]> => this.apiService.findMarketplaceOrders(term)
    }
  }

  constructor(
    private apiService: ApiService,
    private orderService: OrderService) {
    // Do nothing more
  }

  ngOnChanges(): void {
    this.resetData();
  }

  get inputSearch(): AbstractControl {
    return this.searchForm.get('backupSearchInput');
  }

  resetData(): void {
    this.inputSearch.patchValue('');
    this.filteredBackupServices = [];
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.inputSearch
      .valueChanges
      .pipe(
        debounceTime(this.timeDelay),
        tap(() => this.isLoading = true),
        switchMap((term) => {
          if (!term || typeof term !== 'string') {
            return of([]);
          } else {
            return this.inputMapping[this.mode].dataRequest(term);
          }
        })
      )
      .subscribe(backupServices => {
        this.filteredBackupServices = backupServices
          .map(service => this.orderService.computeOrderProperties(service));
        this.isLoading = false;
      });
  }

  backupServiceSelected(event: MatAutocompleteSelectedEvent): void {
    this.backupSelect.emit(<BackupOrder>(event.option.value));
  }

  displayFn(backupService: BackupOrder): string {
    if (backupService) {
      return backupService.name;
    }
  }
}

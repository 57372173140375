<div class="order-search-input">
  <form class="search-form" [formGroup]="searchForm">
    <mat-form-field class="search-input">
      <input matInput placeholder="{{inputMapping[mode].placeholder}}" formControlName="backupSearchInput"
        [matAutocomplete]="auto">
      <mat-icon class="info-icon" matTooltip="{{inputMapping[mode].tooltip}}">info</mat-icon>

      <mat-autocomplete class="auto-complete-panel" #auto="matAutocomplete" [displayWith]="displayFn"
        (optionSelected)="backupServiceSelected($event)">
        <mat-option *ngIf="isLoading">
          <mat-spinner class="spinner" diameter="50"></mat-spinner>
        </mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option class="auto-complete-option" *ngFor="let service of filteredBackupServices" [value]="service">
            <sk-order-summary-card [service]="service"></sk-order-summary-card>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>

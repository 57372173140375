<div class="component-container">
  <sk-order-details *ngIf="activeTab == 'order-details'"
    [order]="order"
    [promo]="promo">
  </sk-order-details>

  <sk-order-job-history *ngIf="activeTab == 'jobs'"
    [orderId]="orderId">
  </sk-order-job-history>

  <sk-order-restore-history *ngIf="activeTab == 'restores'"
    [orderId]="orderId">
  </sk-order-restore-history>

  <sk-order-reindex-history *ngIf="activeTab == 'reindex-history'"
    [orderId]="orderId">
  </sk-order-reindex-history>

  <sk-snapshot-health *ngIf="activeTab == 'snapshot-health'"
    [orderId]="orderId">
  </sk-snapshot-health>

  <sk-order-actions *ngIf="activeTab == 'order-actions'"
    [order]="order">
  </sk-order-actions>

  <sk-order-licensing *ngIf="activeTab == 'licensing'"
    [orderId]="orderId">
  </sk-order-licensing>

  <sk-audit *ngIf="activeTab == 'audit'"
    [domainEntityId]="orderId">
  </sk-audit>
</div>

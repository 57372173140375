import { Injectable } from '@angular/core';

@Injectable()
export class CrmIframeUtil {
  private WindowHasParent(): boolean {
    return window.frames.parent.length > 0;
  }

  get isInIframe(): boolean  {
    return this.WindowHasParent();
  }
}

import {
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

export abstract class IfIframeSateBase {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) { }

  protected removeIfIFrame(shouldRemove: boolean): void {
    if (shouldRemove) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  protected windowHasParent(): boolean {
    return window.frames.parent.length > 0;
  }
}

import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class GenericConfirmationConfig {
  message: string;
  buttonText: {
    ok: string;
    cancel: string;
  }
}

@Component({
  selector: 'sk-generic-confirmation',
  templateUrl: './generic-confirmation.component.html'
})
export class GenericConfirmationComponent {
  @Input() message = "Are you sure?"
  @Input() confirmButtonText = "Yes"
  @Input() cancelButtonText = "Cancel"

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GenericConfirmationConfig,
    private dialogRef: MatDialogRef<GenericConfirmationComponent>) {
    if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}

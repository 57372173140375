import { Component, HostBinding, Input } from '@angular/core';
import { startCase } from 'lodash';

@Component({
  selector: 'sk-user-resource-status-indicator',
  templateUrl: './user-resource-status-indicator.component.html',
  styleUrls: ['./user-resource-status-indicator.component.scss']
})
export class UserResourceStatusIndicatorComponent {
  @HostBinding('class') class ='d-flex';

  @Input() enabled = false;
  @Input() type: string;
  @Input() disabledDueToAccess?: moment.Moment;

  getTitle(): string {
    let titleText = startCase(this.type);

    if (this.enabled && !this.disabledDueToAccess) {
      titleText += ' Enabled'
    } else if (this.enabled && this.disabledDueToAccess) {
      titleText += ' Lost Access'
    } else if (!this.enabled) {
      titleText += ' Disabled'
    }

    return titleText;
  }
}

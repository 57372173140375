<!-- <div title="Enqueue a job"> -->
    <mat-icon
        inline
        class="clickable"
        title="Enqueue a job"
        [matMenuTriggerFor]="jobMenu">
        menu
    </mat-icon>
<!-- </div> -->

<mat-menu #jobMenu="matMenu">
    <ng-container *ngFor="let jobDefinition of jobDefinitions">
        <button mat-menu-item
            *ngIf="!jobDefinition.subTypes"
            (mouseenter)="selectedJobType = jobDefinition.type"
            (click)="enqueueJob()">
            {{jobDefinition.type | startCase}}
        </button>
        <button mat-menu-item
            *ngIf="jobDefinition.subTypes"
            (mouseenter)="selectedJobType = jobDefinition.type"
            [matMenuTriggerFor]="jobSubmenu">
            {{jobDefinition.type | startCase}}
        </button>
        <mat-menu #jobSubmenu="matMenu">
            <button mat-menu-item
                *ngFor="let subType of jobDefinition.subTypes"
                (mouseenter)="selectedJobSubType = subType"
                (click)="enqueueJob()">
                {{subType | startCase}}
            </button>
        </mat-menu>
    </ng-container>
</mat-menu>

import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Moment } from 'moment';

import { BackupSubscription } from '@sk-models';

import { ApiService } from '../../../api-service/api.service';

@Component({
  selector: 'sk-elastic-details-modal',
  templateUrl: './elastic-details-modal.component.html',
  styleUrls: ['./elastic-details-modal.component.scss']
})
export class ElasticDetailsModalComponent implements OnInit {
  subscription: BackupSubscription;
  elasticSerachLastIndexDate: Moment;
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { subscription: BackupSubscription },
    private apiService: ApiService) {
    this.subscription = data?.subscription;
  }

  ngOnInit(): void {
    this.apiService.getElasticSearchLastIndexDate(this.subscription.backupServiceId, this.subscription.resourceKey).subscribe(x => {
      this.elasticSerachLastIndexDate = x;
      this.isLoading = false;
    });
  }
}

<div class="side-drawer-sub-section-header">
  {{title}}
</div>
<div *ngIf="bookmarks == null || bookmarks.length == 0" class="empty-state">
  <h5>You don't have any {{title}}</h5>
</div>
<div *ngIf="bookmarks?.length > 0" class="side-drawer-content">
  <div *ngFor="let bookmark of bookmarks" class="element mat-elevation-z6" [ngClass]="{'edit-mode': editMode}"
    (click)="onNavigate(bookmark)">
    <div class="element-name">
      {{bookmark.orderName}}
    </div>
    <div class="actions">
      <mat-icon *ngIf="editMode == false">chevron_right</mat-icon>
      <mat-icon (click)="onRemove(bookmark)" *ngIf="editMode && canBeRemoved" class="navigatable">close
      </mat-icon>
    </div>
  </div>
</div>

import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {
  ClickToCopyService,
} from 'app/services/click-to-copy-service/click-to-copy.service';

@Component({
  selector: 'sk-click-to-copy',
  templateUrl: './click-to-copy.component.html',
  styleUrls: ['./click-to-copy.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ClickToCopyComponent {
  @HostBinding('class') class ='d-flex align-items-center';

  @Input() copyText: string;
  @Input() displayText: string;
  @Input() hoverText: string;
  @Input() displayIcon: string;
  @Input() dlgTitle: string;

  constructor(private copyService: ClickToCopyService)   {  }

  copyToClipboard(): void {
    this.copyService.copyToClipboard(this.copyText);
  }

  determineIconFontSet(): string {
    return this.displayIcon ? 'material-icons' : 'material-icons-outlined';
  }
}

<div *ngIf="promo" class="details-container">
    <div class="details-title">
        <span class="d-flex">{{promo.name}}
            <sk-click-to-copy
                hoverText="Click to copy name"
                [copyText]="promo.name">
            </sk-click-to-copy>
        </span>
    </div>

    <div class="details-badges">
        <mat-icon inline
            [class.success]="promo.active"
            [class.error]="!promo.active"
            title="{{ promo.active ? 'Active' : 'Inactive' }}">
            power_settings_new
        </mat-icon>
        <sk-badge *ngIf="promo.source"
            [text]="promo.sourceAbbreviation"
            colorClass="blue"
            [title]="'Promo Source: ' + promo.sourceTitle">
        </sk-badge>
        <sk-badge *ngIf="promo.orderType"
            [text]="promo.orderType"
            colorClass="blue"
            [title]="'Promo Order Type: ' + promo.orderType">
        </sk-badge>
    </div>

    <div class="details-table">
        <div id="id">
            <span class="label">Id:</span>
            <span class="d-flex">{{promo.id}}
                <sk-click-to-copy
                    class="clickable break-word"
                    [copyText]="promo.id"
                    hoverText="Click to copy id">
                </sk-click-to-copy>
            </span>
        </div>
        <div id="crm-promo" *ngIf="promo.distributorUrl">
            <span class="label">Crm Disti:</span>
            <a [href]="promo.distributorUrl" class="clickable pop-out break-word" target="_blank" title="Open CRM disti in new tab">
                {{promo.distributorName}}
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
        <div id="crm-promo">
            <span class="label">Crm Promo:</span>
            <a [href]="promo.promoUrl" class="clickable pop-out break-word" target="_blank" title="Open CRM promo in new tab">
                {{promo.name}}
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
        <div id="code">
            <span class="label">Code:</span>
            <span>{{promo.promoCode}}</span>
        </div>
        <div id="special-id">
          <span class="label">Special Id:</span>
          <span>{{promo.specialPromoId}}</span>
      </div>
        <div id="active-date">
            <span class="label">Active Date:</span>
            <span>{{promo.startDate | friendlyDate}} -> {{promo.endDate | friendlyDate}}</span>
        </div>
        <div id="pricing-method">
            <span class="label">PricingMethod:</span>
            <span>{{promo.pricingMethod}}</span>
        </div>
        <div id="sku">
            <span class="label">Invoice SKU:</span>
            <span [title]="promo.invoiceDescription">{{promo.invoiceSku}}</span>
        </div>
    </div>

    <div class="product-details-container">
        <div class="product-details">
          <div class="product-title">
              <span>Users & Groups</span>
          </div>
          <div class="details-table">
            <div>
              <span class="label">Manage Users:</span>
              <mat-icon [class]="getIconColor(promo.allowUsersManagement)">
                {{ getIconName(promo.allowUsersManagement) }}
              </mat-icon>
            </div>
            <div>
              <span class="label">Manage Groups:</span>
              <mat-icon [class]="getIconColor(promo.allowGroupsManagement)">
                {{ getIconName(promo.allowGroupsManagement) }}
              </mat-icon>
            </div>
          </div>
        </div>

        <div class="product-details">
          <span class="product-title">Exchange (User)</span>
          <div class="details-table">
            <div>
              <span class="label">Allow Mailboxes:</span>
              <mat-icon [class]="getIconColor(promo.allowExchangeBackup)">
                {{ getIconName(promo.allowExchangeBackup) }}
              </mat-icon>
            </div>
            <div>
              <span class="label">Manage Mailboxes:</span>
              <mat-icon [class]="getIconColor(promo.manageExchangeIndividual)">
                {{ getIconName(promo.manageExchangeIndividual) }}
              </mat-icon>
            </div>
          </div>
        </div>

        <div class="product-details">
          <div class="product-title">
            <span>Exchange (Shared)</span>
            <mat-icon [class]="getIconColor(!promo.excludeExchangeSharedResourcesCharges)"
              [title]="promo.excludeExchangeSharedResourcesCharges
                ? 'No Charge For Shared Mailboxes'
                : 'Charge For Shared Mailboxes'">
              {{ promo.excludeExchangeSharedResourcesCharges ? 'money_off' : 'price_check' }}
            </mat-icon>
          </div>
          <div class="details-table">
            <div>
              <span class="label">Allow Mailboxes:</span>
              <mat-icon [class]="getIconColor(promo.allowExchangeSharedBackup)">
                {{ getIconName(promo.allowExchangeSharedBackup) }}
              </mat-icon>
            </div>
            <div>
              <span class="label">Manage Mailboxes:</span>
              <mat-icon [class]="getIconColor(promo.manageExchangeShared)">
                {{ getIconName(promo.manageExchangeShared) }}
              </mat-icon>
            </div>
          </div>
        </div>

        <div class="product-details">
          <span class="product-title">OneDrive</span>
          <div class="details-table">
            <div>
              <span class="label">Allow OneDrive:</span>
              <mat-icon [class]="getIconColor(promo.allowOneDriveBackup)">
                {{ getIconName(promo.allowOneDriveBackup) }}
              </mat-icon>
            </div>
            <div>
              <span class="label">Manage OneDrive:</span>
              <mat-icon [class]="getIconColor(promo.manageOneDrive)">
                {{ getIconName(promo.manageOneDrive) }}
              </mat-icon>
            </div>
          </div>
        </div>

        <div class="product-details">
          <span class="product-title">SharePoint</span>
          <div class="details-table">
            <div>
              <span class="label">Allow SharePoint:</span>
              <mat-icon [class]="getIconColor(promo.allowSharePointBackup)">
                {{ getIconName(promo.allowSharePointBackup) }}
              </mat-icon>
            </div>
            <div>
              <span class="label">Manage SharePoint:</span>
              <mat-icon [class]="getIconColor(promo.manageSharePoint)">
                {{ getIconName(promo.manageSharePoint) }}
              </mat-icon>
            </div>
          </div>
        </div>

        <div class="product-details">
          <span class="product-title">Groups & Teams</span>
          <div class="details-table">
            <div>
              <span class="label">Allow Groups:</span>
              <mat-icon [class]="getIconColor(promo.allowGroupsBackup)">
                {{ getIconName(promo.allowGroupsBackup) }}
              </mat-icon>
            </div>
            <div>
              <span class="label">Manage Groups:</span>
              <mat-icon [class]="getIconColor(promo.manageGroups)">
                {{ getIconName(promo.manageGroups) }}
              </mat-icon>
            </div>
          </div>
        </div>
    </div>
</div>

import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from '../msg-service/message.service';
import { catchError, share } from 'rxjs/operators';
import { BaseRealUserMonitoringService } from '../real-user-monitoring/base-real-user-monitoring.service';
import { GenericModalComponent } from 'app/shared/generic-modal/generic-modal.component';

export interface IRequestOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class SkyKickApiService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private rumClient: BaseRealUserMonitoringService) {

  }

  // I'm sure there's a way to tighten this up, but it's meant to be extremely generic
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  public makeRequest<T>(
    method: string,
    url: string,
    body: any,
    responseType: any,
    reload: boolean,
    timeout?: string,
    handleErrors = true,
    handleErrorExplicitly = false
  ): Observable<T> {
    const options: {
      body?: any;
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      responseType?: any
    } = {};

    options.headers = new HttpHeaders();
    if (reload) {
      options.headers = options.headers.append('X-Clear-Cache', 'true');
    }

    if (timeout) {
      options.headers = options.headers.append('timeout', timeout);
    }

    if (body) {
      options.body = body;
    }

    if (responseType) {
      options.responseType = responseType;
    }

    if (handleErrors) {
      return this.http.request<T>(method, url, options)
        .pipe(
          share(),
          catchError(this.handleError(handleErrorExplicitly, undefined))
        );
    } else {
      return this.http.request<T>(method, url, options)
        .pipe(
          share()
        );
    }
  }

  public handleError<T>(handleErrorExplicitly: boolean, result?: T) {
    return (error: any): Observable<T> => {
      this.rumClient.logError(error);
      const httpError = error as HttpErrorResponse;
      const statusCode = httpError.status;
      let errorMessage: string;
      if (httpError.error) {
        if (httpError.error.errorMessage) {
          errorMessage = httpError.error.errorMessage;
        } else if (httpError.error.message) {
          errorMessage = httpError.error.message;
        }
      }
      if (!errorMessage) {
        errorMessage = httpError.message;
      }

      if (statusCode === 401) {
        this.messageService.openCustomModal(GenericModalComponent, 'Not logged in or login expired.  Redirecting to login provider', "Internal API Exception");
      } else if (statusCode === 403) {
        this.messageService.openCustomModal(GenericModalComponent, `UNAUTHORIZED - ${errorMessage}`, "Internal API Exception");
      } else if (
        error?.errorCode === 'user_login_error' ||
        error?.errorCode === 'invalid_state_error') {
        // Skip these because they are an expected response when we need to do a quick auth loop.
        // The user doesn't need to know about it because we will redirect back to where they are.
      } else {
        if (handleErrorExplicitly) {
          throw error;
        } else {
          const parsedMessage = JSON.parse(errorMessage);
          let message = errorMessage;
          if (parsedMessage.message) {
            message = parsedMessage.message
          }
          if (parsedMessage.error) {
            message = parsedMessage.error;
          }
          this.messageService.openCustomModal(GenericModalComponent, message, "Internal API Exception");
        }
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';

import {
  BackupOrder,
  PartnerOrdersFilter,
  PartnerOrdersPage,
} from '@sk-models';
import { environment } from 'environments/environment';

@Component({
  selector: 'sk-orders-grid',
  templateUrl: './orders-grid.component.html',
  styleUrls: ['./orders-grid.component.scss']
})
export class OrdersGridComponent implements OnInit, OnChanges {
  @Input() apiFilters: PartnerOrdersFilter;
  @Input() partnerOrdersPage: PartnerOrdersPage;
  @Input() isRefreshing: boolean;
  @Input() displayedColumnsOverride: string[];
  @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  partnerId: string;
  isLoading: boolean;
  dataSource: MatTableDataSource<BackupOrder>;
  baseUrl: string;

  displayedColumns: string[] = [
    'id',
    'state',
    'name',
    'orderType',
    'createdOn',
    'placedOn',
    'exchangeEnabledSeatsTotal',
    'exchangeEnabledSeatsEnabled',
    'exchangeEnabledSeatsDisabledDueToAccess',
    'exchangeEnabledSeatsDisabled',
    'sharePointEnabledSeatsTotal',
    'sharePointEnabledSeatsEnabled',
    'sharePointEnabledSeatsDisabledDueToAccess',
    'sharePointEnabledSeatsDisabled',
    'groupsEnabledSeatsTotal',
    'groupsEnabledSeatsEnabled',
    'groupsEnabledSeatsDisabledDueToAccess',
    'groupsEnabledSeatsDisabled'
  ];

  // filtering variables
  filterForm: FormGroup;

  pageSize: number;
  pageSizeOptions: number[];
  totalCount: number;

  private defaultPageSizeOptions = [10, 50, 100];
  private totalCountMax = 100;

  get selectedCreatedDateFrom(): moment.Moment { return moment(this.filterForm.get('createdDateFrom').value); }
  get selectedCreatedDateTo(): moment.Moment { return moment(this.filterForm.get('createdDateTo').value); }

  ngOnInit(): void {
    this.baseUrl = environment.client_url();
    this.initFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.partnerOrdersPage &&
      changes.partnerOrdersPage.currentValue) {
      this.setDataSource();
      this.setPagingData();
      this.isRefreshing = false;
    }
  }

  initFilter(): void {
    this.filterForm = new FormGroup({
      orderState: new FormControl(),
      createdDateFrom: new FormControl(),
      createdDateTo: new FormControl()
    });

    this.filterForm.controls.orderState.setValue(
      this.apiFilters.orderState === null ? '' : this.apiFilters.orderState
    );

    this.filterForm.valueChanges
      .subscribe(() => this.updateCurrentFilter());
  }

  setDataSource(): void {
    if ((this.partnerOrdersPage) &&
      (this.partnerOrdersPage.partnerOrders)) {
      this.dataSource = new MatTableDataSource(this.partnerOrdersPage.partnerOrders);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.filterData;
      this.dataSource.sortingDataAccessor = (item, property) => this.sortingAccessorFunction(item, property);
    }
  }

  setPagingData(): void {
    if ((this.partnerOrdersPage) &&
      (this.partnerOrdersPage.partnerOrders)) {
      this.pageSize = this.partnerOrdersPage.pageSize;
      this.totalCount = this.partnerOrdersPage.totalCount;
      this.pageSizeOptions = this.setPageSizeOptions();
    }
  }

  setPageSizeOptions(): number[] {
    if (this.partnerOrdersPage.totalCount > this.totalCountMax) {
      return this.defaultPageSizeOptions
        .filter(x => x <= this.partnerOrdersPage.totalCount);
    } else {
      return this.defaultPageSizeOptions
        .filter(x => x <= this.partnerOrdersPage.totalCount)
        .concat(this.partnerOrdersPage.totalCount);
    }
  }

  pageChanged(event: PageEvent): void {
    this.apiFilters.pageSize = event.pageSize;
    this.apiFilters.pageNumber = event.pageIndex + 1;
    this.isRefreshing = true;
    this.refreshEvent.emit();
  }

  stateSelected(event: MatSelectChange): void {
    this.apiFilters.orderState = event.value;
    this.refreshEvent.emit();
  }

  createdDateFromChange(event: MatDatepickerInputEvent<Date>): void {
    this.apiFilters.createdDateFrom = moment(event.value);
  }

  createdDateToChange(event: MatDatepickerInputEvent<Date>): void {
    this.apiFilters.createdDateTo = moment(event.value);
    this.refreshEvent.emit();
  }

  // date filters are not well implemented and need to be reworked (probably server-side)
  updateCurrentFilter(): void {
    this.dataSource.filter =
      + `,${this.selectedCreatedDateFrom && this.selectedCreatedDateTo.isValid()
        ? this.selectedCreatedDateFrom.format('YYYY-MM-DD')
        : ''}`
      + `,${this.selectedCreatedDateTo && this.selectedCreatedDateTo.isValid()
        ? this.selectedCreatedDateTo.format('YYYY-MM-DD')
        : ''}`;
  }

  orderCompareFn(left: BackupOrder, right: BackupOrder): number {
    return right.createdOn.valueOf() - left.createdOn.valueOf();
  }

  sortingAccessorFunction(item: BackupOrder, property: string): number {
    switch (property) {
    case 'exchangeEnabledSeatsTotal':
      return this.seatTotalSortFunction(item, 'exchange');
    case 'exchangeEnabledSeatsEnabled':
      return item.products['exchange'] ? item.products['exchange'].enabledSeats : -1;
    case 'exchangeEnabledSeatsDisabledDueToAccess':
      return item.products['exchange'] ? item.products['exchange'].disabledDueToAccessSeats : -1;
    case 'exchangeEnabledSeatsDisabled':
      return item.products['exchange'] ? item.products['exchange'].disabledSeats : -1;
    case 'sharePointEnabledSeatsTotal':
      return this.seatTotalSortFunction(item, 'sharePoint');
    case 'sharePointEnabledSeatsEnabled':
      return item.products['sharePoint'] ? item.products['sharePoint'].enabledSeats : -1;
    case 'sharePointEnabledSeatsDisabledDueToAccess':
      return item.products['sharePoint'] ? item.products['sharePoint'].disabledDueToAccessSeats : -1;
    case 'sharePointEnabledSeatsDisabled':
      return item.products['sharePoint'] ? item.products['sharePoint'].disabledSeats : -1;
    case 'groupsEnabledSeatsTotal':
      return this.seatTotalSortFunction(item, 'groups');
    case 'groupsEnabledSeatsEnabled':
      return item.products['groups'] ? item.products['groups'].enabledSeats : -1;
    case 'groupsEnabledSeatsDisabledDueToAccess':
      return item.products['groups'] ? item.products['groups'].disabledDueToAccessSeats : -1;
    case 'groupsEnabledSeatsDisabled':
      return item.products['groups'] ? item.products['groups'].disabledSeats : -1;
    default:
      return item[property];
    }
  }

  seatTotalSortFunction(order: BackupOrder, productType: string): number {
    if (order.products[productType]) {
      const product = order.products[productType];
      return product.enabledSeats +
        product.disabledDueToAccessSeats +
        product.disabledSeats;
    }
    return -1;
  }

  filterData(order: BackupOrder, filterString: string): boolean {
    // to figure out what's in this delimited list, check
    // wherever the MatTableDataSource.filter is set.
    const filters = filterString.split(',');

    // check createdDateFrom
    const selectedCreatedDateFrom = filters[0];
    if (selectedCreatedDateFrom && order.createdOn < moment(selectedCreatedDateFrom)) {
      return false;
    }

    // check createdDateFrom
    const selectedCreatedDateTo = filters[1];
    if (selectedCreatedDateTo && order.createdOn > moment(selectedCreatedDateTo)) {
      return false;
    }

    return true;
  }

  refreshOrders(reload?: boolean): void {
    this.isRefreshing = true;
    this.refreshEvent.emit(reload);
  }
}

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Bookmark } from '@sk-models';

import { BookmarksService } from '../../services/bookmarks/bookmarks.service';

@Component({
  selector: 'sk-bookmark-list',
  templateUrl: './bookmark-list.component.html'
})
export class BookmarkListComponent implements OnInit {

  @Output() navigate = new EventEmitter<Bookmark>();
  editMode = false;
  bookmarks: Bookmark[] = [];
  history: Bookmark[] = [];
  bookMarksTitle = 'Bookmarks';
  historyTitle = 'History';

  constructor(private bookmarksService: BookmarksService) {
  }

  ngOnInit(): void {
    this.bookmarks = this.bookmarksService.getAll();
    this.history = this.bookmarksService.getHistory();
  }

  onRemoveBookmark(bookmark: Bookmark): void {
    this.bookmarks = this.bookmarksService.removeOrder(bookmark.orderId);
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  onNavigate(bookmark: Bookmark): void {
    if (this.editMode === false) {
      this.navigate.emit(bookmark);
    }
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ResourceKey } from '@sk-models';

@Component({
  selector: 'sk-skipped-restore-items-modal',
  templateUrl: './skipped-restore-items-modal.component.html',
  styleUrls: ['./skipped-restore-items-modal.component.scss']
})
export class SkippedRestoreItemsModalComponent {
  resourceKey: ResourceKey;
  redirectSourceKey: ResourceKey;
  jobId: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { resourceKey: ResourceKey, redirectSourceKey: ResourceKey, jobId: string }) {
    if (data) {
      this.resourceKey = data.resourceKey;
      this.redirectSourceKey = data.redirectSourceKey;
      this.jobId = data.jobId;
    }
  }
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { BackupOrder, Promo } from '@sk-models';
import { ApiService } from '../api-service/api.service';
import { OrderService, PromoService } from '@sk-services';

@Component({
  selector: 'sk-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  activeTab: string;
  orderId: string;
  order: BackupOrder;
  promo: Promo;
  isExpanded = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private apiService: ApiService,
      private orderService: OrderService,
      private promoService: PromoService
  ) { }

  ngOnInit(): void {
    const lastUrlSegment = this.route.snapshot.url[3] &&
      this.route.snapshot.url[3].path;
    if (lastUrlSegment === 'order-details' ||
        lastUrlSegment === 'jobs' ||
        lastUrlSegment === 'restores' ||
        lastUrlSegment === 'reindex-history' ||
        lastUrlSegment === 'queue-jobs' ||
        lastUrlSegment === 'snapshot-health' ||
        lastUrlSegment === 'order-actions' ||
        lastUrlSegment === 'licensing' ||
        lastUrlSegment === 'audit') {
      this.activeTab = lastUrlSegment;
    } else {
      this.router.navigateByUrl(this.router.url + `/order-details`);
    }

    this.orderId = this.route.snapshot.paramMap.get('id');
    // BSP is in iFrame for migration order in CRM
    if (this.orderId === '00000000-0000-0000-0000-000000000000') {
      // do not load order nor promo in this case
      return;
    }

    this.loadOrder();
    this.loadPromo();
  }

  loadOrder(): void {
    this.apiService.fetchOrder(this.orderId)
      .pipe(map(order => this.orderService.computeOrderProperties(order)))
      .subscribe(o => this.order = o);
  }

  loadPromo(): void {
    this.apiService.findOrderPromo(this.orderId, false)
      .pipe(map(p => {
        if (p) {
          return this.promoService.computePromoProperties(p);
        }
      }))
      .subscribe(p => this.promo = p);
  }
}

import { Component, OnInit, Input, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ApiService } from '../../api-service/api.service';
import { ClickToCopyService } from '../../services/click-to-copy-service/click-to-copy.service';
import { GraphQueryPage, GraphQueryResponse } from '@sk-models';
import { ActivatedRoute, Router } from "@angular/router";
import { of } from "rxjs";
import { MatSelectChange } from '@angular/material/select';
import { MessageService } from '@sk-services';
import { GraphTokenModalComponent } from '../graph-token-modal/graph-token-modal.component';

@Component({
  selector: 'sk-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss']
})
export class GraphExplorerComponent implements OnInit {
  @HostBinding('class') class = 'd-flex flex-column flex-grow-1';

  @Input() orderId: string;

  isLoading = false;

  queryResultsCurrentPage: GraphQueryPage;
  queryResultPages: GraphQueryPage[] = [];
  endpoints: string[][] = [];
  betaEndpoint = false;
  selectedEndpoint: string;
  customUri = '';
  selectedOption: string | null = null;

  @ViewChild('queryInput') queryInput!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('paginator') paginator: MatPaginator;
  currentPageIndex = 0;

  constructor(
    private apiService: ApiService,
    private copyService: ClickToCopyService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get('id');
    const loadingSubscription = of(this.isLoading = false).subscribe();

    if (this.route.snapshot?.queryParamMap?.get('uri')) {
      const version = this.route.snapshot?.queryParamMap?.get('version');
      this.betaEndpoint = version === 'beta';
      loadingSubscription.add(() => {
        this.customUri = this.route.snapshot?.queryParamMap?.get('uri') || '';
      });
    }
  }

  querySelected(event: MatSelectChange): void {
    if (event.value === 'Users') {
      this.customUri = 'users';
    } else if (event.value === 'Groups') {
      this.customUri = 'groups';
    } else if (event.value === 'SharePoint Sites') {
      this.customUri = 'sites';
    } else if (event.value === 'SharePoint Site Root') {
      this.customUri = 'sites/root';
    } else if (event.value === 'Applications') {
      this.customUri = 'applications';
    } else if (event.value === 'Device Roles') {
      this.customUri = 'deviceManagement/roleDefinitions/62e90394-69f5-4237-9190-012177145e10/roleAssignments';
    } else {
      this.customUri = '';
    }

    setTimeout(() => {
      this.selectedOption = undefined;
      this.queryInput.nativeElement.focus();
    });
  }

  sendRequestClick(event: KeyboardEvent): void {
    event.preventDefault();
    this.sendRequest();
  }

  sendRequest(): void {
    this.queryResultsCurrentPage = null;
    this.queryResultPages = [];

    if (this.customUri && this.customUri.trim().length > 1) {
      this.queryGraph(this.customUri, this.betaEndpoint);
    }
  }

  pageBackwards(): void {
    this.queryResultsCurrentPage = this.queryResultPages[this.currentPageIndex-1];
  }

  canPageForwards(): boolean {
    return !this.isLoading &&
      (this.queryResultsCurrentPage.pageNumber < this.queryResultPages.length ||
      !!this.queryResultsCurrentPage.nextPageUri);
  }

  pageForwards(): void {
    if (this.queryResultsCurrentPage.pageNumber < this.queryResultPages.length) {
      this.queryResultsCurrentPage = this.queryResultPages[this.currentPageIndex+1];
    } else if (this.queryResultsCurrentPage.nextPageUri) {
      this.queryGraph(this.queryResultsCurrentPage.nextPageUri, this.betaEndpoint);
    }
  }

  queryGraph(uri: string, betaEndpoint: boolean): void {
    this.isLoading = true;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { version: this.betaEndpoint ? 'beta' : 'v1.0', uri: uri },
      replaceUrl: true
    });

    this.apiService.queryGraphCustom(this.orderId, uri, betaEndpoint)
      .subscribe({
        next: rawResult => this.parseGraphQueryResults(rawResult),
        complete: () => this.isLoading = false
      });
  }

  parseGraphQueryResults(response: GraphQueryResponse): void {
    const json = JSON.parse(response.content);

    const newPage: GraphQueryPage = {
      pageNumber: this.queryResultPages.length + 1,
      content: json,
      nextPageUri: response.nextPageUri
    };
    this.queryResultPages.push(newPage);
    this.queryResultsCurrentPage = newPage;
  }

  onPageChange(event: PageEvent): void {
    if (event.pageIndex > this.currentPageIndex) {
      this.pageForwards();
    } else {
      this.pageBackwards();
    }
    this.currentPageIndex = event.pageIndex;
  }

  copyJson(): void {
    if (this.queryResultsCurrentPage) {
      this.copyService.copyToClipboardJSON(this.queryResultsCurrentPage.content);
    }
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }

  showToken(): void {
    this.messageService.openCustomModal(GraphTokenModalComponent, { orderId: this.orderId });
  }
}

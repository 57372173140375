function buildUrl(url: string, params: Record<string, unknown>): string {

  getUrlParams(url)?.forEach(val => {
    if (params[val] != undefined) {
      url = url.replace(`\{${val}\}`, params[val] as string);
    }
  });

  return url;
}


function getUrlParams(url: string): string[] {
  return url?.match(/{([^}]+)}/g)?.map(res => res.replace(/{|}/g, ''))
}

export const UrlUtility = {
  buildUrl,
  getUrlParams
}

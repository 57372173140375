<div [hidden]="!dataSource" [class.mat-elevation-z8]="!dataSource" class="grid-container">
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="skyKickId">
        <mat-header-cell *matHeaderCellDef>
          <span>SK ID</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <sk-click-to-copy
            hoverText="Click to copy SkyKick ID"
            [copyText]="item.skyKickId">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="parentSkyKickId">
        <mat-header-cell *matHeaderCellDef>
          <span>PSK ID</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <sk-click-to-copy
            hoverText="Click to copy Parent SkyKick ID"
            [copyText]="item.parentSkyKickId">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>File Path</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <sk-click-to-copy
            [displayText]="item.displayName"
            hoverText="Click to copy File Path"
            [copyText]="item.displayName">
          </sk-click-to-copy>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="entityType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Type</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.entityType}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="abandonedReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Abandoned Reason</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <a *ngIf="item.abandonedReason"
            href=""
            [title]="item.abandonedReason"
            target="_blank"
            (click)="showModal(item.abandonedReason, 'Abandoned Reason'); $event.preventDefault();">
            {{ item.abandonedReason.length > 400
              ? item.abandonedReason.substring(0,400) + '...'
              : item.abandonedReason }}
						</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Time Stamp</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.timestamp | friendlyDate}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="size">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Size</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.size | fileSize}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
</div>

<div *ngIf="!isLoading && skippedItems?.length == 0" class="no-results">
  <span>There were no skipped items in this restore job!</span>
</div>

<div *ngIf="!isLoading && !skippedItems" class="no-results">
  <span>No Results Found</span>
</div>

<div *ngIf="isLoading" class="loading-spinner-modal">
  <mat-spinner></mat-spinner>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { RestoreJobFilter, SearchReindexStatus } from '@sk-models';
import { ApiService } from '../../api-service/api.service';


@Component({
  selector: 'sk-subscription-reindex-history',
  templateUrl: './subscription-reindex-history.component.html'
})
export class SubscriptionReindexHistoryComponent implements OnInit {
  @Input() orderId: string;
  @Input() subscriptionId: string;
  @Input() resourceType: string;

  jobs: SearchReindexStatus[];
  isRefreshing: boolean;
  filterData: RestoreJobFilter = new RestoreJobFilter();

  jobStatuses: string[];

  constructor(
    private apiService: ApiService,

  ) { }


  ngOnInit(): void {
    this.loadSearchReindexStatuses(false);
  }

  loadSearchReindexStatuses(reload: boolean): void {
    this.isRefreshing = true;

    this.apiService.findBackupOrderSubscriptionSearchIndexStatuses(this.orderId, this.subscriptionId, this.resourceType, reload)
      .subscribe(jobs => {
        this.jobs = jobs;
        this.isRefreshing = false;
        this.jobStatuses = Array.from(new Set(this.jobs.map((item: SearchReindexStatus) => item.statusIconTitle)));
      });
  }
 
  onFilterChanged(filter: RestoreJobFilter): void {
    this.filterData = filter;
  }

  onRefreshData(): void {
    this.loadSearchReindexStatuses(true);
  }
 
}

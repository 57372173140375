<div *ngIf="!isLoading && dataSource?.data" class="download-button-container">
  <button mat-raised-button (click)="downloadData()">Download</button>
</div>

<div [hidden]="!dataSource" [class.mat-elevation-z8]="!dataSource" class="grid-container">
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Type</span>
        </mat-header-cell>
        <!-- Need to check -->
        <mat-cell *matCellDef="let item">
          <mat-icon>{{item.iconName}}</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.displayName}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="browseSubscription">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Browse Subscription</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span><a href={{item.browseSubscriptionLink}}>browse</a></span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
</div>

<div *ngIf="!isLoading && (!restoreTargets || restoreTargets?.length == 0)">
  No Restore Targets Found
</div>

<div *ngIf="isLoading" class="loading-spinner-modal">
  <mat-spinner></mat-spinner>
</div>

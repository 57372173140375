import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthUser } from '@sk-models';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private subscriptions: Subscription[] = [];
  private authDataSubj: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(null);

  public readonly authData$ = this.authDataSubj.asObservable();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.handleSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  handleSubscription(): void {

    this.subscriptions.push(
      this.msalBroadcastService.inProgress$
        .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)
        )
        .subscribe(() => {
          // Not sure in what situations would there be more than one account.
          if (this.authService.instance.getAllAccounts().length >= 1) {            
            const activeAccount: AccountInfo = this.authService.instance.getAllAccounts()[0];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const roles: string[] = ((<any>activeAccount)?.idTokenClaims)?.roles ?? [];

            const authUser = <AuthUser>{
              isAuthenticated: true,
              username: activeAccount.username,
              roles: roles
            };

            this.authDataSubj.next(authUser);

          } else {
            // Not sure in what situations this would NOT be set at this point.
            throw new Error('Unable to set useraccount');
          }
        })
    );
  }

}

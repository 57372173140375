import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BackupOrder, BackupSubscriptionTypes, BulkOperationPrecheckResult, BulkOperationRequest, BulkOperationType } from '@sk-models';
import { ApiService } from 'app/api-service/api.service';

@Component({
  selector: 'sk-bulk-operations',
  templateUrl: './bulk-operations.component.html',
  styleUrls: ['./bulk-operations.component.scss']
})
export class BulkOperationsComponent {
  csv: string;
  file: File;

  orderId: string;
  operationType: BulkOperationType;
  subscriptionType: BackupSubscriptionTypes;

  dataSource: MatTableDataSource<BulkOperationPrecheckResult>;
  totalLength = 0;
  displayedColumns = ['entityName', 'outcome'];
  isLoading = false;

  formValidationErrors: string[] = [];
  precheckErrors: string[] = [];
  actionErrors: string[] = [];

  readonly bulkOperationUiStates = BulkOperationUiState;
  uiState: BulkOperationUiState = this.bulkOperationUiStates.Unchecked;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly bulkOperationTypeEnum = BulkOperationType;
  readonly backupSubscriptionTypeEnum = BackupSubscriptionTypes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: BackupOrder,
    public dialogRef: MatDialogRef<BulkOperationsComponent>,
    private apiService: ApiService) {
    this.orderId = data.id;
  }

  changeListener(files: FileList): void {
    if (files && files.length > 0) {
      const file: File = files.item(0);
      this.file = file;
    }
    this.onFormChanged();
  }

  onFormChanged(): void {
    this.uiState = this.bulkOperationUiStates.Unchecked;
    this.isLoading = false;
    this.actionErrors = [];
    this.precheckErrors = [];
    this.formValidationErrors = [];
  }

  submitPrecheck(): void {
    this.validatePrecheck();
    if (!this.formValidationErrors || this.formValidationErrors.length === 0) {
      this.isLoading = true;
      const reader: FileReader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = () => {
        this.csv = reader.result as string;
        const request = new BulkOperationRequest();
        request.operationType = this.operationType;
        request.entityList = this.fileToEntityList();
        request.subscriptionType = this.subscriptionType;

        this.apiService.bulkOperationsPrecheck(this.orderId, request, true)
          .subscribe(res => {
            this.uiState = this.bulkOperationUiStates.Checked;
            this.dataSource = new MatTableDataSource<BulkOperationPrecheckResult>(res);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.customFilterPredicate;
            this.totalLength = this.dataSource.data.length;
          })
          .add(() => {
            this.isLoading = false;
          });
      };
    }
  }

  submitAct(): void {
    this.isLoading = true;
    const request = new BulkOperationRequest();
    request.operationType = this.operationType;
    request.entityList = this.fileToEntityList();
    request.subscriptionType = this.subscriptionType;

    this.apiService.bulkOperationsAct(this.orderId, request, true)
      .subscribe(() => {
        this.actionErrors = [];
      }, err => {
        this.handleBulkActErrors(err);
        this.uiState = this.bulkOperationUiStates.PostAction;
      }, () => {
        this.uiState = this.bulkOperationUiStates.PostAction;
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  applyFilter(event: MatSelectChange): void {
    const filterValue = event.value.join();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private customFilterPredicate(data: BulkOperationPrecheckResult, filter: string) {
    return filter.includes(data.outcome.toLowerCase());
  }

  isSuccess(): boolean {
    return !this.actionErrors || this.actionErrors.length === 0;
  }

  // ignoring any type because we don't have a defined model for the exception
  // we're expecting. Should we? idfk, probably.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleBulkActErrors(err: any) {
    if (err && err.error && err.error.errorMessage) {
      if (err.error.errorMessage.toLowerCase().includes('timed out')) {
        this.actionErrors.push(
          'Portal timed out waiting for bulk action. '
          + 'This action may still be running. '
          + 'Please wait 10 minutes and re-check this file. '
          + 'Then repeat the action if needed'
        );
      } else {
        this.actionErrors.push(err.error.errorMessage);
      }
    } else if (err) {
      this.actionErrors.push(err.message);
    } else {
      this.actionErrors.push('An unknown error occured');
    }
  }

  private validatePrecheck() {
    this.formValidationErrors = [];
    if (!this.file) {
      this.formValidationErrors.push('Must upload file');
    } else if (!this.isValidFileType(this.file.name)) {
      this.formValidationErrors.push('Invalid File: Type must be CSV');
    }
    if (!this.operationType) {
      this.formValidationErrors.push('Must select operation type');
    }
    if (!this.subscriptionType) {
      this.formValidationErrors.push('Must select subscription type');
    }
  }

  private isValidFileType(fileName: string): boolean {
    const acceptedFileExtension = 'csv';
    return fileName.endsWith(acceptedFileExtension);
  }

  private fileToEntityList(): string[] {
    if (this.csv && this.csv != null && this.csv !== '') {
      // split by new line and remove empty
      return this.csv.split(/\r\n|\n/).filter(element => element);
    }
    return [];
  }
}

export enum BulkOperationUiState {
  Unchecked = 'unchecked',
  Checked = 'checked',
  PostAction = 'postaction'
}

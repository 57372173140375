<div class="search-form-container d-flex flex-column">
  <mat-form-field class="search-type-dropdown">
    <mat-select [(value)]="selected" placeholder="Select Search Type" (selectionChange)="resetSearch()">
      <mat-option value="backup">Backup Search</mat-option>
      <mat-option value="vanityDomain">Vanity Domain Search</mat-option>
      <mat-option value="marketplaceOrder">Marketplace Order Id Search</mat-option>
      <mat-option value="subscription">Subscription Id Lookup</mat-option>
      <mat-option value="jobs">Job Id Lookup</mat-option>
    </mat-select>
  </mat-form-field>

  <sk-subscription-search-input *ngIf="isSubscriptionSearch"
    class="full-width"
    [searchTerm]="defaultSubscriptionId"
    (subscriptionSelect)="onSubscriptionSelect($event)">
  </sk-subscription-search-input>

  <sk-job-search-input *ngIf="isJobSearch"
    class="full-width"
    [searchTerm]="defaultJobId"
    (jobSelect)="onJobSelect($event)">
  </sk-job-search-input>

  <sk-order-search-input *ngIf="!isSubscriptionSearch && !isJobSearch"
    class="full-width"
    [mode]="selected"
    (backupSelect)="onBackupSelect($event)">
  </sk-order-search-input>
</div>

import { Injectable } from '@angular/core';
import { BackupSubscription, BackupSubscriptionTypes } from '@sk-models';
import { IGraphJourney } from '../models/graph-journey.interface';
import { GraphJourneyRegisterService } from 'app/graph-journey/services/graph-journey-register.service';


@Injectable()
export class SubscriptionGraphJourneyService {

  constructor(
    private registar: GraphJourneyRegisterService
  ) {}

  public getInitialJourney(sub: BackupSubscription): { journey: IGraphJourney, params: { [key: string]: unknown } } {

    let journeyName: string = null;
    let params = {};
    switch (sub.subscriptionType) {
    case BackupSubscriptionTypes.SharePoint:
      journeyName = 'siteJourney';
      params = { siteId: sub.o365Id };
      break;
    case BackupSubscriptionTypes.OneDrive:
      journeyName = 'oneDriveJourney';
      params = { userId: sub.o365Id };
      break;
    case BackupSubscriptionTypes.Teams:
      journeyName = 'teamJourney';
      params = { teamId: sub.o365Id }
      break;
    case BackupSubscriptionTypes.Groups:
      journeyName = 'groupsJourney';
      params = { groupId: sub.o365Id }
      break;
    case BackupSubscriptionTypes.Exchange:
    default:
      journeyName = null;
    }
    if (journeyName != null) {
      return {
        journey: this.registar.getRegistration(journeyName),
        params: params
      }
    }
  }

}

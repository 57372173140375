<div class="order-card">
  <div class="card-row">
    <div class="card-section">
        <span class="name">{{service.name}}</span>
        <sk-badge [text]="friendlierOrderState(service.state)" [colorClass]="service.stateIconClass"></sk-badge>
        <sk-badge *ngIf="!service.orderType" text="V1" colorClass="red"></sk-badge>
        <sk-badge *ngIf="service.orderType" [text]="service.orderType" colorClass="blue"></sk-badge>
    </div>

    <div *ngIf="service.placedOn" class="card-section">
      <span class="label">Placed: </span>
      <span class="created">{{service.placedOn | friendlyDate}}</span>
    </div>

    <div *ngIf="!service.placedOn" class="card-section">
      <span class="label">Created: </span>
      <span class="created">{{service.createdOn | friendlyDate}}</span>
    </div>
  </div>

  <div class="card-row">
    <div class="card-section">
      <span class="domain">{{service.domain}}</span>
    </div>

    <div class="card-section">
      <mat-icon inline class="icon resource" [class.enabled]="service.exchangeEnabled" title="Exchange Enabled?">mail</mat-icon>
      <mat-icon inline class="icon resource" [class.enabled]="service.sharePointEnabled" title="SharePoint Enabled?">language</mat-icon>
      <mat-icon inline *ngIf="service.disabledDueToNonPaymentOn" class="icon">credit_card_off</mat-icon>
      <sk-badge [text]="'Placed: ' + service.placementSourceAbbreviation" colorClass="blue"></sk-badge>
    </div>
  </div>
</div>

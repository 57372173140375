import { Injectable } from '@angular/core';
import { GraphQueryPage, GraphQueryResponse } from '@sk-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGraphJourney, IGraphJourneyError, IGraphJourneyResult, PathReturnType } from '../models/graph-journey.interface';
import { GraphJourneyApiService } from './graph-journey-api.service';
import { GraphJourneyRegisterService } from './graph-journey-register.service';
import { ObjectUtility } from '../util/object.utility';
import { UrlUtility } from '../util/url.utility';

@Injectable()
export class GraphJourneyService {

  constructor(
    private apiService: GraphJourneyApiService,
    private journeyRegister: GraphJourneyRegisterService) { }

  public buildNextRequestParams(currentJourney: IGraphJourney, currentParams: unknown, nextJourney: IGraphJourney, selectedData: unknown): unknown {

    const combined = this.buildParams(currentJourney, currentParams, selectedData);

    const result = {};
    for (const property of UrlUtility.getUrlParams(nextJourney.url)) {
      if (combined[property] === null) {
        throw ("Could not find " + property);
      } else {
        result[property] = combined[property];
      }
    }

    return result;
  }

  private buildParams(currentJourney: IGraphJourney, currentParams: unknown, selectedData: unknown) : unknown {
    const combined = { ...currentParams as Record<string, unknown>, ...selectedData as Record<string, unknown> };

    // If any fields have alias - add that data to the object as well.
    currentJourney.fields.filter(x => x.alias != null).forEach(x => {
      combined[x.alias] = selectedData[x.field];
    });
    return combined;
  }


  public buildNextRequest(pathJourney: IGraphJourney): IGraphJourney {
    const query: string = pathJourney.url;
    const result = { ...pathJourney, url: query };
    return result;
  }

  public buildNextTitle(currentJourney: IGraphJourney, currentParams: unknown, nextJourney: IGraphJourney, selectedData: unknown) : string {
    // Find if any of the fields are specified as title
    if(currentJourney.display) {
      return selectedData[currentJourney.display];
    }
    return null;
  }

  public buildUrl(journey: IGraphJourney, params: Record<string, unknown>): string {
    return UrlUtility.buildUrl(journey.url, ObjectUtility.flattenObj(params));
  }

  public build(orderId: string, journey: IGraphJourney, params: Record<string, unknown>): Observable<IGraphJourneyResult> {

    const requestUrl: string = UrlUtility.buildUrl(journey.url, ObjectUtility.flattenObj(params));

    return this.loadData(orderId, requestUrl, journey);
  }

  loadData(orderId: string, requestUrl: string, journey: IGraphJourney): Observable<IGraphJourneyResult> {
    return this.apiService.request(orderId, requestUrl).pipe(
      map(this.mapGraphQueryResults),
      map((x: GraphQueryPage) => {
        let data = null;

        // This makes Lint happy
        const content = x.content as unknown as Record<string, unknown>;

        if (content.error !== undefined) {
          const error = content.error as unknown as Record<string, unknown>;
          throw <IGraphJourneyError>{
            message: error?.message ?? "Unspecified error",
            url: requestUrl
          };
        } else if (content.value != undefined) {
          data = content.value
        } else {
          data = [content];
        }

        const resultData = data.map(x => {
          const flattened = ObjectUtility.flattenObj(x) as Record<string, unknown>;
          const paths = [];
          if (journey.childJourneys != null) {
            for (const path of journey.childJourneys) {
              if (this.journeyRegister.isRegistered(path)) {
                paths.push(this.journeyRegister.getRegistration(path));
              } else if (this.journeyRegister.isPathRegistered(path) != null) {
                const evalFunc: PathReturnType = this.journeyRegister.getPathRegistration(path);
                const evalPaths = evalFunc(flattened);
                if (evalPaths != null && evalPaths.length > 0) {
                  for (const evalPath of evalPaths) {
                    paths.push(this.journeyRegister.getRegistration(evalPath));
                  }
                }
              } else {
                throw ("Don't know how to process " + path);
              }
            }
            flattened.__paths = paths;
          }
          return flattened;
        });
        return {
          url: requestUrl,
          data: resultData,
          next: x.nextPageUri
        };
      }));
  }

  private mapGraphQueryResults(response: GraphQueryResponse): GraphQueryPage {
    const parsedContent = JSON.parse(response.content as string);

    const newPage: GraphQueryPage = {
      pageNumber: 1,
      content: parsedContent,
      nextPageUri: response.nextPageUri
    };
    return newPage;
  }
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  private units = [
    'bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  public transform(input: number, precision = 2): string {
    let unitIndex = 0;

    if (!input || input === 0) {
      return '0';
    }

    while (input >= 1024) {
      input /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    return input.toFixed(precision) + ' ' + unit;
  }
}

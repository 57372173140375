import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Promo } from '@sk-models';
import { ApiService } from '../api-service/api.service';
import { PromoService } from '@sk-services';

@Component({
  selector: 'sk-order-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  activeTab: string;
  orderId: string;
  promo: Promo;
  loading = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private apiService: ApiService,
      private promoService: PromoService
  ) { }

  ngOnInit(): void {
    const lastUrlSegment = this.route.snapshot.url[3] &&
      this.route.snapshot.url[3].path;
    if (lastUrlSegment === 'promo-details' ||
        lastUrlSegment === 'billing') {
      this.activeTab = lastUrlSegment;
    } else {
      this.router.navigateByUrl(this.router.url + `/promo-details`);
    }

    this.orderId = this.route.snapshot.paramMap.get('id');
    this.loadPromo();
  }

  loadPromo(): void {
    this.loading = true;
    this.apiService.findOrderPromo(this.orderId, false)
      .subscribe(p => {
        if(p) {
          this.promo = this.promoService.computePromoProperties(p);
        }
        this.loading = false;
      });
  }
}

import { Injectable } from '@angular/core';

import { entries } from 'lodash';
import { Bookmark } from '@sk-models';

@Injectable()
export class BookmarksService {

  private readonly storageKeyHistory: string = 'sk_bsp_history';

  private readonly storageKey: string = 'sk_bsp_bookmarks';
  private readonly maxEntries: number = 15;
  private readonly maxEntriesHistory: number = 10;

  public getAll(): Bookmark[] {
    const data = localStorage.getItem(this.storageKey);
    if (data) {
      const bookmarks = JSON.parse(data);
      return bookmarks;
    }
    return [];
  }

  public addOrder(data: Bookmark): Bookmark[] {
    const existing = this.getAll();
    if (existing != null && entries.length <= this.maxEntries) {
      if (existing.find((x) => x.orderId == data.orderId) === undefined) {
        existing.unshift(data);
        const bookmark = JSON.stringify(existing);
        localStorage.setItem(this.storageKey, bookmark);
      }
    }
    return existing;
  }

  public removeOrder(orderId: string): Bookmark[] {
    const existing = this.getAll();
    if (existing != null) {
      const index = existing.findIndex((x) => x.orderId == orderId);
      if (index >= 0) {
        existing.splice(index, 1);
        const bookmark = JSON.stringify(existing);
        localStorage.setItem(this.storageKey, bookmark);
        return existing;
      }
    }
    return existing;
  }

  public isOrderBookmarked(orderId: string): boolean {
    const existing = this.getAll();
    if (existing != null) {
      const index = existing.findIndex((x) => x.orderId == orderId);
      return index >= 0;
    }
    return false;
  }

  public addToHistory(data: Bookmark): void {
    let existing = this.getHistory() ?? [];
    const index = existing.findIndex((x) => x.orderId == data.orderId);
    if (index >= 0) {
      existing.splice(index, 1);
    }
    if(existing.length >= this.maxEntriesHistory) {
      existing = existing.slice(0, this.maxEntriesHistory - 1);
    }
    existing.unshift(data);
    const bookmarks = JSON.stringify(existing);
    localStorage.setItem(this.storageKeyHistory, bookmarks);
  }

  public getHistory(): Bookmark[] {
    const data = localStorage.getItem(this.storageKeyHistory);
    if (data) {
      const history = JSON.parse(data);
      return history;
    }
    return [];
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionBackupEntity } from '../subscription-search.component';

@Component({
  selector: 'sk-entity-detail',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.scss']
})
export class EntityDetailComponent implements OnInit {
  @Input() entity:SubscriptionBackupEntity ;
  @Output() expandEvent = new EventEmitter<boolean>();
  dataSource: SubscriptionBackupEntity[];
  
  displayedColumns: string[] = [
    'relativeUrl'
  ];


  ngOnInit(): void {
    this.dataSource = [this.entity];
  }

}

import { Injectable } from '@angular/core';
import { ToastService } from '@sk-services';

@Injectable({
  providedIn: 'root'
})
export class ClickToCopyService {

  constructor(private toastService: ToastService) { }

  copyToClipboard(copyText: string): void {
    const tmp = document.createElement('textarea');
    tmp.value = copyText;
    tmp.style.position = 'fixed';
    tmp.style.top = '0';
    tmp.style.left = '0';
    tmp.style.padding = '0';
    tmp.style.border = 'none';
    tmp.style.outline = 'none';
    tmp.style.boxShadow = 'none';
    tmp.style.background = 'transparent';
    document.body.appendChild(tmp);
    tmp.focus();
    tmp.setSelectionRange(0, tmp.value.length);
    navigator.clipboard.writeText(tmp.value);
    document.body.removeChild(tmp);
    this.toastService.success('Copied!!');
  }

  copyToClipboardJSON(json: JSON): void {
    const copyText = JSON.stringify(json, null, '    ')
    this.copyToClipboard(copyText);
  }
}

export class RestoreJobFilter {
    resourceType: string;
    jobStatus: string;
    searchString: string;
    expanded: boolean;

    constructor() {
      this.resourceType = 'All';
      this.jobStatus = 'All';
      this.expanded = false;
    }
}

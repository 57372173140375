import { Route } from "@angular/router";
import { UsersGroupsComponent } from "./users-groups.component";
import { UsersGroupsLandingComponent } from "./components/users-groups-landing/users-groups-landing.component";
import { UsersGroupsUserDetailComponent } from "./components/users-groups-user/users-groups-user-detail/users-groups-user-detail.component";
import { UsersGroupsGroupDetailComponent } from "./components/users-groups-group/users-groups-group-detail/users-groups-group-detail.component";
import { TeamsGroupsDetailComponent } from "./components/users-groups-collaboration/teamsgroups-detail/teamsgroups-detail.component";
import { UsersGroupsUserAuditComponent } from "./components/users-groups-user/user-groups-user-audit/users-groups-user-audit.component";
import { UsersGroupsGroupAuditComponent } from "./components/users-groups-group/user-groups-group-audit/users-groups-group-audit.component";

export const UsersGroupsRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'search',
    pathMatch: 'full'
  },
  {
    path: '',
    component: UsersGroupsComponent,
    children: [
      {
        path: 'search',
        component: UsersGroupsLandingComponent
      },
      {
        path: 'users/:userId',
        component: UsersGroupsUserDetailComponent,
      },
      {
        path: 'users/:userId/audit',
        component: UsersGroupsUserAuditComponent,
        data: {
          overrideOutletContainer: true
        }
      },
      {
        path: 'groups/:groupId',
        component: UsersGroupsGroupDetailComponent,
      },
      {
        path:'groups/:groupId/audit',
        component: UsersGroupsGroupAuditComponent,
        data: {
          overrideOutletContainer: true
        }
      },
      {
        path: 'teamsgroups/:teamsgroupsId',
        component: TeamsGroupsDetailComponent,
      },
    ]
  }
]

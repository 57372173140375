import { Injectable } from '@angular/core';

import { Promo, PromoSource } from '@sk-models';

@Injectable()
export class PromoService {
  computePromoProperties(promo: Promo): Promo {
    if (promo) {
      switch (promo.source) {
      case PromoSource.Traditional:
        promo.sourceTitle = 'Traditional';
        promo.sourceAbbreviation = 'Trad';
        break;
      case PromoSource.Marketplace:
        promo.sourceTitle = 'Marketplace';
        promo.sourceAbbreviation = 'Mkt';
        break;
      case PromoSource.APIDirect:
        promo.sourceTitle = 'Api Direct';
        promo.sourceAbbreviation = 'Api';
        break;
      default:
        promo.sourceTitle = 'Promo source not recognized';
        promo.sourceAbbreviation = '?';
        break;
      }

      return promo;
    }
  }
}

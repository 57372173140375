import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GenericModalOptions } from '@sk-models';

@Component({
  selector: 'sk-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {
  title: string;
  text: string;
  restoreTargets: { iconName: string; displayName: string; }[];

  showCodeBlock: boolean;
  showParagraph: boolean;
  showHtml: boolean;
  showJson: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { options: GenericModalOptions, text: string, title: string }) {

    this.text = data.text;
    this.title = data.title;

    switch (data.options) {
    case GenericModalOptions.ShowCodeBlock:
      this.showCodeBlock = true;
      break;
    case GenericModalOptions.ShowHtml:
      this.showHtml = true;
      break;
    case GenericModalOptions.ShowJson:
      this.showJson = true;
      this.text = JSON.parse(this.text);
      break;
    case GenericModalOptions.ShowParagraph:
    default:
      this.showParagraph = true;
      break;
    }
  }
}

import { Moment } from 'moment';

export class RestoreHistoryDetails {
  attempts: number;
  created: Moment;
  lastItemAttempted: Moment;
  requester: string;
  totalItemsAttempted: number;
  totalBytesRestored: number;
  totalItemsRestored: number;
  pointInTime: Moment;
}




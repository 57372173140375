import {
  Component,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BackupSubscription, MachineState } from '@sk-models';

import { ApiService } from '../../../api-service/api.service';

@Component({
  selector: 'sk-purge-modal',
  templateUrl: './purge-modal.component.html',
  styleUrls: ['./purge-modal.component.scss']
})
export class PurgeModalComponent {
  purgeResult: string;
  notReadyToPurge: boolean;
  warningActive: boolean;
  subscription: BackupSubscription;
  isPurgeInProgress: boolean;
  canShowPurgeButton: boolean;
  isPurgeSuccess: boolean;


  constructor(
    @Inject(MAT_DIALOG_DATA) data: { subscription: BackupSubscription },
    private apiService: ApiService) {
    if (data) {
      this.subscription = data.subscription;
      this.warningActive = true;
      this.isPurgeInProgress = false;
      this.canShowPurgeButton = false;
    }
  }

  showPurgeButton(): void {
    this.canShowPurgeButton = true;
    this.warningActive = false;
  }

  canPurgeMachineState(machineState: MachineState): boolean {
    return machineState == null ||
      // We have a machine state, but doesn't look like its registered since a status does NOT exist
      machineState.state == null ||
      // We have a machine state, but its idle
      machineState.state === 'Idle';
  }

  canPurgeSubscription(): boolean {
    // Allow purge if the resource is not registered in the Resource Director
    // or is in the Idle state
    if (this.canPurgeMachineState(this.subscription.restoreState) &&
        this.canPurgeMachineState(this.subscription.snapshotState) &&
        !this.subscription.enabled) {
      return true;
    } else {
      return false;
    }
  }

  purgeSubscription(): void {
    this.canShowPurgeButton = false;
    this.warningActive = false;
    if (this.canPurgeSubscription()) {
      this.isPurgeInProgress = true;

      this.apiService.purgeSubscription(this.subscription.backupServiceId, this.subscription.resourceKey)
        .subscribe(
          () => {
            this.isPurgeSuccess = true;
            this.purgeResult = 'The resource ' + this.subscription.fullName + ' was successfully purged!';
          },
          error => {
            this.isPurgeSuccess = false;
            try {
              const rawError = JSON.parse(error.error);
              this.purgeResult = rawError.errorMessage;
            } catch (e) {
              this.purgeResult = error.message;
            }
          })
        .add(() => this.isPurgeInProgress = false);
    } else {
      this.notReadyToPurge = true;
      this.isPurgeInProgress = false;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { SearchFilter, UsersGroupsSearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { CollaborationService } from 'app/users-groups/services/collaboration.service';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ODataResponse } from '@sk-models';
import { CollaborationResource } from 'app/users-groups/models/collaboration-resource.model';

@Component({
  selector: 'sk-collaboration-resources-search-grid',
  templateUrl: './collaboration-resources-search-grid.component.html',
  styleUrls: ['./collaboration-resources-search-grid.component.scss']
})
export class CollaborationResourcesSearchGridComponent extends SkyKickComponent implements OnInit {

  @Input() backupServiceId: string;
  @Input() filterSubscriptionType: string;
  @Input() filterSearchTerm: string;

  public isLoading = true;
  public hasData = false;

  // Pagination
  pageSizeOptions = [12, 50, 100];
  totalResults: number;

  subject = new Subject();
  filter: UsersGroupsSearchFilter;

  dataSource: MatTableDataSource<CollaborationResource>;
  displayedColumns = ['id', 'name', 'type', 'protected', 'disabledDueToAccess', 'created'];

  constructor(private collaborationService: CollaborationService) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.subs.sink = this.subject.pipe(
      switchMap((data: UsersGroupsSearchFilter) => {
        this.isLoading = true;
        return this.executeSearch(data);
      })
    ).subscribe((uag: ODataResponse<CollaborationResource>) => {
      this.hasData = true;
      this.totalResults = uag['@odata.count'];
      this.dataSource = new MatTableDataSource(uag.value);
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    });
    this.filter = {
      page: 0,
      size: this.pageSizeOptions[0],
      sort: 'name',
      sortDirection: 'asc',
      subscriptionType: this.filterSubscriptionType,
      term: this.filterSearchTerm };
  }

  public refresh(filter: SearchFilter): void {
    this.filter = {
      ...this.filter,
      term: filter.term
    };
    this.subject.next(this.filter);
  }

  private executeSearch(filter: UsersGroupsSearchFilter): Observable<ODataResponse<CollaborationResource>> {
    return this.collaborationService
      .searchCollaborationResources(this.backupServiceId, filter);
  }

  onPageChanged(event: PageEvent): void {
    this.filter.size = event.pageSize;
    this.filter.page = event.pageIndex;
    this.subject.next(this.filter);
  }

  onFilterChanged(term: string): void {
    this.filter = {
      ...this.filter,
      term: term
    };
  }

  onSortChanged(sort: Sort): void {
    this.filter.sort = sort.active;
    this.filter.sortDirection = (sort.direction);
    this.subject.next(this.filter);
  }

  getFriendlyName(cr: CollaborationResource): string {
    if (cr.resourceKey.subscriptionType == "O365Exchange") {
      return cr.name;
    } else if (cr.resourceKey.subscriptionType == "O365SharePoint") {
      if (cr.name != null && cr.name.length > 0) {
        return cr.name;
      } else {
        const url = cr?.customMetadata?.url?.toLowerCase();
        const index = url ? url.indexOf('/sites') : -1;

        if (index !== -1) {
          return url.substring(index+6)
        }
      }
    }
    return 'UNKNOWN';
  }
}

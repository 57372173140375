<div *ngIf="alwaysShow || availableResources.length > 0">
  <sk-section-header title="Resources" [info]="info">
  </sk-section-header>
  <div class="resource-container">
    <ng-container *ngIf="availableResources.length > 0">
      <ng-container *ngFor="let resource of availableResources">
        <div class="resource">
          <sk-widget>
            <div class="resource-data flex-container">
              <div class="flex-grow">
                <img [src]="resource.img" [alt]="resource.title" />
                {{resource.title}}
              </div>
              <div *ngIf="resource.url">
                <mat-icon>chevron_right</mat-icon>
              </div>
              <a *ngIf="resource.url" [href]="resource.url"></a>
            </div>
          </sk-widget>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="availableResources.length == 0">
      No resources available
    </ng-container>
  </div>
</div>

<div class="bsp-content-container">
  <div class="d-flex flex-grow-1 title-container">
    <span class="title">Restore Job History</span>
  </div>
    <form [formGroup]="searchForm">
        <mat-grid-list [cols]="5" rowHeight="75px" class="search-selectors">
            <mat-grid-tile [rowspan]="1" [colspan]="1">
                <mat-form-field>
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="idType">
                        <mat-option *ngFor="let idType of idTypes" [value]="idType">
                            {{idType ? idType : '--'}}
                        </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="!validateIdType()" class="search-validation-hint">select a type</mat-hint>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [rowspan]="1" [colspan]="2">
                <mat-form-field class="id-form-field">
                    <input matInput placeholder="Id"
                        formControlName="idInput"
                        [disabled]="idInputDisabled()">
                    <mat-hint *ngIf="searchForm.get('idInput').enabled && !validateIdInput()"
                              class="search-validation-hint">
                      enter a valid guid
                    </mat-hint>
                </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [rowspan]="1" [colspan]="2">
                <button mat-raised-button
                    id="idSearch"
                    (click)="search()"
                    [disabled]="searchButtonDisabled()">
                Search
                </button>
                <button mat-raised-button
                    id="exportCSVButton"
                    (click)="exportCSV()"
                    [disabled]="!restoreJobs || restoreJobs.length == 0">
                Export CSV
                </button>
                <button mat-raised-button
                    id="aggregateSkipReport"
                    [routerLink] = "['skipped-restore-items-report']">
                    Aggregate Skip Report
              </button>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>

<sk-job-history
  [jobs]="restoreJobs"
  [isRefreshing]="isRefreshing"
  [displayedColumnsOverride]="displayedColumns"
  (refreshEvent)="loadRestoreJobs($event)">
</sk-job-history>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../../api-service/api.service';
import { ResourceKey, SubscriptionJobMetric } from '@sk-models';


@Component({
  selector: 'sk-snapshot-details',
  templateUrl: './snapshot-details.component.html',
  styleUrls: ['./snapshot-details.component.scss']
})
export class SnapshotDetailsComponent implements OnInit {

  jobId: string;
  resourceKey = new ResourceKey();
  metrics: SubscriptionJobMetric[];
  isRefreshing: boolean;


  constructor(
    public apiService: ApiService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.url
      && this.activatedRoute.snapshot.paramMap.get('jobId')
      && this.activatedRoute.snapshot.paramMap.get('subscriptionId')
      && this.activatedRoute.snapshot.paramMap.get('resourceType') ){
      this.jobId = this.activatedRoute.snapshot.paramMap.get('jobId');
      this.resourceKey.subscriptionId = this.activatedRoute.snapshot.paramMap.get('subscriptionId');
      this.resourceKey.resourceType = this.activatedRoute.snapshot.paramMap.get('resourceType');
      this.resourceKey.subscriptionType = '';
      this.onReloadData();
    }
  }

  onReloadData(): void {
    this.isRefreshing = true;
    this.apiService.getSnapshotStatsForJob(this.jobId).subscribe(data => {
      this.metrics = data;
      this.isRefreshing = false;
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackupSubscriptionTypes, BulkReindexOrderRequest, OrderResourceCount } from '@sk-models';
import { ApiService } from 'app/api-service/api.service';
import { finalize } from 'rxjs/operators';


interface TextValuePair {
  value: string | number;
  text: string;
}

@Component({
  selector: 'sk-bulk-reindex-modal',
  templateUrl: './bulk-reindex-modal.component.html',
  styleUrls: ['./bulk-reindex-modal.component.scss']
})
export class BulkReindexModalComponent implements OnInit {

  maxSuggestedAmount = 1000;
  
  backupServiceId: string;

  allResources: OrderResourceCount[] = [];
  selectedCount = 0;

  enabledOptions:TextValuePair[] = [];
  subscriptionOptions:TextValuePair[] = [];
  resourceTypeOptions:TextValuePair[] = [];

  selectedState?: number = 0;
  selectedSubscriptionType?: string = "*";
  selectedResourceType?: string = "*";
  includeExcludedItems = false;
  includeDeletedItems = true;
  advancedOptionsHide = true;

  reindexQueuing = false;
  reindexResult: boolean = null;
  reindexMessage: string = null;
  disableFields = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { backupServiceId: string },
    private apiService: ApiService
  ) {
    if (data) {
      this.backupServiceId = data.backupServiceId;
    }
  }

  ngOnInit(): void {
    this.apiService.getResourceCounts(this.backupServiceId).subscribe(x => {
      this.allResources = x;
      this.onFormChanged();
    });
  }

  onFormChanged(): void {
    const resources = this.allResources;

    const selectedStateResources = this.getSelectedStateResources(resources);
    this.buildSelectedStateResources(resources);

    const selectedSubscriptionTypeResources = this.getSelectedSubscriptionTypeResources(selectedStateResources);
    this.buildSelectedSubscriptionTypeResources(selectedSubscriptionTypeResources);

    const selectedResourceTypeResources = this.getSelectedResourceTypeResources(selectedSubscriptionTypeResources);
    this.buildSelectedResourceTypeResources(selectedResourceTypeResources);

    // sum the count of selectedResourceTypeResources if selectedResourceType is not *
    if(this.selectedResourceType !== '*') {
      // Sum the count of the selected resource type if the selected resource type equals selectedResourceType
      this.selectedCount = selectedResourceTypeResources.filter(x => x.resourceType === this.selectedResourceType).reduce((acc, curr) => acc + curr.count, 0);
    } else {
      this.selectedCount = selectedResourceTypeResources.reduce((acc, curr) => acc + curr.count, 0);
    }
  }

  getSelectedResourceTypeResources(resources: OrderResourceCount[]): OrderResourceCount[] {

    let resourceTypes = resources;

    if(this.selectedSubscriptionType !== '*') {
      resourceTypes = resourceTypes.filter(x => x.subscriptionType === this.selectedSubscriptionType);
    } else {
      this.selectedResourceType = '*';
    }

    if(this.selectedResourceType !== '*') {
      if(resources.find(x => x.resourceType === this.selectedResourceType) == undefined) {
        this.selectedResourceType = '*';
      }
    }

    return resourceTypes;

  }

  buildSelectedResourceTypeResources(resources: OrderResourceCount[]): void {

    this.resourceTypeOptions = [{ value: '*', text: `All (${resources.reduce((acc, curr) => acc + curr.count, 0)})` }];
    if(this.selectedSubscriptionType !== '*') {

      // Group the resources by Subscription type and count them
      const data = resources.reduce((acc, curr) => {
        if (!acc[curr.resourceType]) {
          acc[curr.resourceType] = 0;
        }
        acc[curr.resourceType] += curr.count;
        return acc;
      }, {});

      this.resourceTypeOptions = this.resourceTypeOptions.concat(Object.keys(data).map(x => ({
        value: x,
        text: `${x} (${data[x]})`
      })));
    }
  }

  buildSelectedSubscriptionTypeResources(resources: OrderResourceCount[]): void {

    // Group the resources by Subscription type and count them
    const subscriptionTypes = resources.reduce((acc, curr) => {
      if (!acc[curr.subscriptionType]) {
        acc[curr.subscriptionType] = 0;
      }
      acc[curr.subscriptionType] += curr.count;
      return acc;
    }, {});

    // Subscription Types
    this.subscriptionOptions = [{ value: '*', text: `All (${resources.reduce((acc, curr) => acc + curr.count, 0)})` }];

    this.subscriptionOptions = this.subscriptionOptions.concat(Object.keys(subscriptionTypes).map(x => ({
      value: x,
      text: `${x} (${subscriptionTypes[x]})`
    })));

  }

  getSelectedSubscriptionTypeResources(resources: OrderResourceCount[]): OrderResourceCount[] {

    const subResources = resources;

    if(this.selectedSubscriptionType !== '*') {
      if(subResources.find(x => x.subscriptionType === this.selectedSubscriptionType) == undefined) {
        this.selectedSubscriptionType = '*';
      }
    }

    return subResources;
  }

  private getSelectedStateResources(resources: OrderResourceCount[]): OrderResourceCount[] {

    const target = resources;

    if (this.selectedState !== 0) {
      return target.filter(x => (x.enabled ? 1 : -1) === this.selectedState);
    }
    return target;
  }

  private buildSelectedStateResources(resources: OrderResourceCount[]): void  {
    const allResources = resources.reduce((acc, curr) => acc + curr.count, 0);
    const enabledResources = resources.reduce((acc, curr) => acc + (curr.enabled ? curr.count : 0), 0);
    const disabledResources = resources.reduce((acc, curr) => acc + (!curr.enabled ? curr.count : 0), 0);

    this.enabledOptions = [{
      value: 0,
      text: `All (${allResources})`
    }, {
      value: 1,
      text: `Enabled (${enabledResources})`
    }, {
      value: -1,
      text: `Disabled (${disabledResources})`
    }];
  }

  buildReindexSubscriptionRequest(): BulkReindexOrderRequest {
    const reindexSubscriptionRequest: BulkReindexOrderRequest  = {
      state: this.selectedState == 0 ? null : this.selectedState == 1 ? true : false,
      subscriptionType: this.selectedSubscriptionType == "*" ? null : <BackupSubscriptionTypes>this.selectedSubscriptionType,
      resourceType: this.selectedResourceType == "*" ? null : this.selectedResourceType,
      includeExcludedItems: this.includeExcludedItems,
      ingestDirectly: true,
      isHardReindex: false,
      includeDeletedItems: this.includeDeletedItems,
    }
    return reindexSubscriptionRequest;
  }

  excludedBoxChecked(checked: boolean): void {
    this.includeExcludedItems = checked;
  }

  deletedBoxChecked(checked: boolean): void {
    this.includeDeletedItems = checked;
  }

  toggleAdvancedOptions(): void {
    this.advancedOptionsHide = !this.advancedOptionsHide;
    if (this.advancedOptionsHide === true) {
      this.excludedBoxChecked(false);
    }
  }

  reindexSubscription(): void {
    this.reindexQueuing = true;
    this.disableFields = true;
    const reindexSubscriptionRequest = this.buildReindexSubscriptionRequest();
    this.apiService.bulkReindexSubscription(this.backupServiceId, reindexSubscriptionRequest)
      .pipe(finalize(() => this.reindexQueuing = false))
      .subscribe({
        next: () => {
          this.reindexResult = true;
        },
        error: (err) => {
          this.reindexResult = false;
          this.reindexMessage = err.error.errorMessage;
        },
      });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphJourneyExplorerComponent } from './components/graph-journey-explorer/graph-journey-explorer.component';
import { GraphJourneyStopComponent } from './components/graph-journey-stop/graph-journey-stop.component';
import { GraphJourneyService } from './services/graph-journey.service';
import { HttpClientModule } from '@angular/common/http';
import { CustomMaterialModule } from 'app/custom-material/custom-material.module';
import { GraphDetailsComponent } from './components/graph-details/graph-details.component';
import { SharedModule } from 'app/shared/shared.module';
import { SubscriptionGraphJourneyComponent } from './components/subscription-graph-journey/subscription-graph-journey.component';
import { GraphJourneyRegisterService } from './services/graph-journey-register.service';
import { GraphJourney, GraphJourneyMappers } from './config/graph-journey.config';
import { GraphJourneyApiService } from './services/graph-journey-api.service';
import { GraphDataTableComponent } from './components/graph-data-table/graph-data-table.component';
import { GraphCollapsibleContainerComponent } from './components/graph-collapsible-container/graph-collapsible-container.component';
import {  SubscriptionGraphJourneyService } from './services/subscription-graph-journey.service';

// Graph Journey Registration
const graphJourneyRegisterServiceFactory = () => {
  const graphRegister = new GraphJourneyRegisterService();
  GraphJourney.GraphJourneyRegistrations.forEach(x => graphRegister.register(x));
  GraphJourneyMappers.GraphJourneyMappersRegistrations.forEach(x => graphRegister.registerPath(x.name, x.func));
  return graphRegister;
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CustomMaterialModule,
    SharedModule
  ],
  providers: [
    GraphJourneyService,
    GraphJourneyApiService,
    { provide: GraphJourneyRegisterService, useFactory: graphJourneyRegisterServiceFactory },
    SubscriptionGraphJourneyService
  ],
  declarations: [
    GraphJourneyExplorerComponent,
    GraphJourneyStopComponent,
    GraphDetailsComponent,
    SubscriptionGraphJourneyComponent,
    GraphDataTableComponent,
    GraphCollapsibleContainerComponent
  ],
  exports: [
    SubscriptionGraphJourneyComponent
  ]
})
export class GraphJourneyModule { }

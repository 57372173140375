<div class="component-container">
  <div class="d-flex flex-grow-1 title-container">
    <span class="title">Billing History</span>
  </div>
  <div *ngIf="billingRecords" class="table-wrapper">
    <div class="info">
      <mat-icon class="info clickable md-36" (click)="expandBillingInfoFlyout()">info</mat-icon>
    </div>
    <div class="table billing-table">
      <div class="row billing-row header">
        <span class="month">Month</span>
        <span class="partner">Partner</span>
        <span class="customer">Customer</span>
        <span class="promo">Promo</span>
        <span class="invoice-number">Invoice #</span>
        <span class="locked-date">Locked</span>
        <span class="settlement-date">Settled</span>
      </div>
      <div *ngFor="let rec of billingRecords">
        <div class="billing-row clickable" (click)="toggleSeatDetail(rec)">
          <span class="month">{{rec.month | friendlyDate}}</span>
          <span class="partner">{{rec.partner}}</span>
          <span class="customer">{{rec.customer}}</span>
          <span class="promo">{{rec.promo}}</span>
          <span class="invoice-number">{{rec.invoiceNumber || '-'}}</span>
          <span class="locked-date">{{(rec.lockedDate | friendlyDate) || '-'}}</span>
          <span class="settlement-date">{{(rec.settlementDate | friendlyDate) || '-'}}</span>
        </div>
        <div *ngIf="rec.showSeatDetail" class="billing-row seat-detail seat-detail-header">
          <span class="unique-seats">Unique Seats</span>
          <span class="exchange-paid">Exchange Paid</span>
          <span class="sharepoint-paid">Sharepoint Paid</span>
          <span class="exchange-trial">Exchange Trial</span>
          <span class="sharepoint-trial">Sharepoint Trial</span>
          <span class="exchange-iur">Exchange IUR</span>
          <span class="sharepoint-iur">Sharepoint IUR</span>
          <span class="exchange-nfr">Exchange NFR</span>
          <span class="sharepoint-nfr">Sharepoint NFR</span>
        </div>
        <div *ngIf="rec.showSeatDetail" class="billing-row seat-detail">
          <span class="unique-seats">{{rec.uniqueLicensedSeats}}</span>
          <span class="exchange-paid">{{rec.exchangeSeatsCharged}}</span>
          <span class="sharepoint-paid">{{rec.sharepointSeatsCharged}}</span>
          <span class="exchange-trial">{{rec.exchangeSeatsTrial}}</span>
          <span class="sharepoint-trial">{{rec.sharepointSeatsTrial}}</span>
          <span class="exchange-iur">{{rec.exchangeSeatsIur}}</span>
          <span class="sharepoint-iur">{{rec.sharepointSeatsIur}}</span>
          <span class="exchange-nfr">{{rec.exchangeSeatsNfr}}</span>
          <span class="sharepoint-nfr">{{rec.sharepointSeatsNfr}}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!billingRecords" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="side-drawer-container">
  <div class="side-drawer-header">
    <div class="text">
      User Bookmarks & History
    </div>
    <div class="actions" *ngIf="bookmarks?.length > 0 || history?.length > 0">
      <mat-icon (click)="toggleEditMode()" class="navigatable" *ngIf="editMode == false">edit</mat-icon>
      <mat-icon (click)="toggleEditMode()" class="navigatable" *ngIf="editMode">done</mat-icon>
    </div>
  </div>
  <div class="side-drawer-content-container">
    <sk-bookmark
      (navigate) = "onNavigate($event)"
      (bookmarkRemoved) = "onRemoveBookmark($event)"
      [bookmarks]="bookmarks"
      [editMode]="editMode"
      [title]="bookMarksTitle">
    </sk-bookmark>
    <sk-bookmark
      (navigate) = "onNavigate($event)"
      [bookmarks]="history"
      [editMode]="editMode"
      [title]="historyTitle">
    </sk-bookmark>
  </div>
</div>

import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Partner } from '@sk-models';

@Component({
  selector: 'sk-partner-search',
  templateUrl: './partner-search.component.html',
  styleUrls: ['./partner-search.component.scss']
})
export class PartnerSearchComponent {
  @HostBinding('class') class ='d-flex flex-grow-1 flex-column';

  constructor(private router: Router) { }

  partnerSelected(partner: Partner): void {
    const url = `partners/${partner.id}`;
    this.router.navigateByUrl(url);
  }

}

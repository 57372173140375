import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { SubscriptionJobMetric } from '@sk-models';
import { dateTimeFormatter } from '../../pipes/datetime-utils';


@Component({
  selector: 'sk-job-progress-details',
  templateUrl: './job-progress-details.component.html',
  styleUrls: ['./job-progress-details.component.scss']
})
export class JobProgressDetailsComponent implements OnChanges {

  @Input()
  jobType: string;

  @Input() isRefreshing: boolean;
  @Input() metrics: SubscriptionJobMetric[];
  @Output() reloadData = new EventEmitter();


  metricsSorted: SubscriptionJobMetric[];
  view: number[] = [1000, 500];
  showXAxis = true;
  showYAxis = true;
  maxXAxisTickLength = 17;
  xAxisLabel = 'Time';
  yAxisLabel: string;
  showTimeline = true;
  autoScale = true;
  dataPoints: {name: string, series: {
    name: Date;
    value: number;
}[]}[];

  currentMetric = '';
  currentEntityType = '';
  allEntityTypes: string[] = [];
  allMetricTypes: string[] = [];

  isLoading = false;

  graphControlForm: FormGroup;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  isInitialized = false;

  constructor(
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metrics && this.metrics) {
      this.isLoading = true;
      this.metricsSorted = this.metrics.sort((a, b) => {
        if (a.entityType < b.entityType) {
          return -1;
        }
        if (a.entityType > b.entityType) {
          return 1;
        }
        return 0;
      });
      this.populateMetricChoices(this.metrics);
      if (!this.isInitialized) {
        this.createLegendForm();
        this.isInitialized = true;
      }
      this.isLoading = false;
      this.updateDisplay();
    }
  }

  populateMetricChoices(metrics: SubscriptionJobMetric[]): void {
    this.allEntityTypes = metrics.map(m => m.entityType)
      .filter((element, index, array) => {
        return index === array.indexOf(element);
      });

    this.allMetricTypes = metrics.map(m => m.metricName)
      .filter((element, index, array) => {
        return index === array.indexOf(element);
      });
    if(this.allEntityTypes)
      this.currentEntityType = this.allEntityTypes[0];
    if(this.allMetricTypes)
      this.currentMetric = this.allMetricTypes[0];
  }

  createLegendForm(): void {
    this.graphControlForm = new FormGroup({
      metric: new FormControl(this.currentMetric),
      entityType: new FormControl(this.currentEntityType)
    });

    this.graphControlForm.valueChanges.subscribe(() => this.updateDisplay());
  }

  updateDisplay(): void {
    this.currentMetric = this.graphControlForm.get('metric').value;
    this.currentEntityType = this.graphControlForm.get('entityType').value;

    const metricToDisplay = this.metricsSorted.filter(metric => metric.metricName === this.currentMetric
      && metric.entityType === this.currentEntityType);

    this.yAxisLabel = `${this.currentMetric}`;
    this.dataPoints = metricToDisplay.map(metric => {
      return {
        name: metric.entityType,
        series: metric.dataPoints.map(dp => {
          return {
            name: new Date(dp.time.toString()),
            value: dp.value
          };
        })
      };
    });
  }

  refreshData(): void {
    this.reloadData.emit();
    this.isLoading = true;
  }

  formatDateTime(dateTime: Date): string {
    return dateTimeFormatter(dateTime, 'MM/DD/YY hh:mm A');
  }

  getSummationDetails(): { entityType: string, metrics: SubscriptionJobMetric[] }[] {
    const val = this.allEntityTypes.map(entityType => {
      return {
        entityType: entityType,
        metrics: this.metricsSorted.filter(metric => metric.entityType === entityType)
      };
    });
    return val;
  }

  exitClicked(): void {
    const jobDetailsUrl = this.router.url.slice(0, this.router.url.lastIndexOf('/'));
    this.router.navigateByUrl(jobDetailsUrl);
  }
}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ApiService } from '../../api-service/api.service';
import { ResourceKey, SkippedRestoreItem } from '@sk-models';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { MessageService } from '@sk-services';

@Component({
  selector: 'sk-skipped-restore-items',
  templateUrl: './skipped-restore-items.component.html',
  styleUrls: ['./skipped-restore-items.component.scss']
})
export class SkippedRestoreItemsComponent implements OnInit {
  @Input() jobId: string;
  @Input() resourceKey: ResourceKey;
  @Input() redirectSourceKey: ResourceKey;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'skyKickId',
    'parentSkyKickId',
    'displayName',
    'entityType',
    'abandonedReason',
    'timestamp',
    'size'
  ];

  dataSource: MatTableDataSource<SkippedRestoreItem>;
  skippedItems: SkippedRestoreItem[];
  isLoading = false;

  constructor(private apiService: ApiService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.isLoading = true;

    if (this.jobId && this.resourceKey) {
      this.apiService.findSkippedRestoreItemResults(this.jobId, this.resourceKey, this.redirectSourceKey)
        .subscribe({
          next: results => this.setDataSource(results),
          complete: () => this.isLoading = false
        });
    } else {
      this.apiService.findSkippedRestoreItemAggregate()
        .subscribe({
          next: results => this.setDataSource(results),
          complete: () => this.isLoading = false
        });
    }
  }

  setDataSource(items: SkippedRestoreItem[]): void {
    if (items) {
      items.map(x => {
        return Object.assign(x, { displayName: x.friendlyUrl + '/' + x.entityName });
      });
      this.skippedItems = items;
      this.dataSource = new MatTableDataSource(this.skippedItems);
      this.dataSource.sort = this.sort;
    }
  }

  showModal(message: string, title: string): void {
    this.messageService.openCustomModal(GenericModalComponent, message, title);
  }
}

import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { dateTimeFormatter } from './../datetime-utils';
import { Subscription } from 'rxjs';
import { AppSettingsService } from '@sk-services';

@Pipe({
  name: 'friendlyDate',
  pure: false //set to false to make pipe impure - allow real-time updates
})
export class FriendlyDatePipe implements PipeTransform, OnDestroy {

  readonly localFormat = 'MM/DD/YY hh:mm A';
  readonly utcFormat = 'MM/DD/YY HH:mm';
  private subscription: Subscription;
  private useUtc = false

  constructor(private appSettings: AppSettingsService) {
    this.subscription = this.appSettings.useUtc$.subscribe((useUtc) => {
      this.useUtc = useUtc;
    });
  }

  transform(input: string | Date | moment.Moment, formatString: string): string {
    if (!input) { return '--'; }

    if(this.useUtc) {
      return `${moment(input).utc().format(this.utcFormat)}Z`;
    }

    return dateTimeFormatter(input, (formatString) ? formatString : this.localFormat);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGraphJourney } from 'app/graph-journey/models/graph-journey.interface';

@Component({
  selector: 'sk-graph-collapsible-container',
  templateUrl: './graph-collapsible-container.component.html',
  styleUrls: ['./graph-collapsible-container.component.scss']
})
export class GraphCollapsibleContainerComponent {

  @Input() hasMore = false;
  @Input() journey: IGraphJourney;
  @Input() displayName: string;
  @Input() requestedUrl: string;
  @Input() collapsed = false;
  @Output() changed = new EventEmitter<boolean>();


  expand(): void {
    this.collapsed = false;
    this.changed.emit(this.collapsed);
  }

  collapse(): void {
    if(this.hasMore) {
      this.collapsed = true;
    }
    this.changed.emit(this.collapsed);
  }


}

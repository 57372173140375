import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VersionDialogComponent } from 'app/shared/version-dialog/version-dialog.component';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  constructor(private dialog: MatDialog) { }

  showVersionDialog(darkTheme: boolean): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { isDarkTheme: darkTheme };
    const dialogRef = this.dialog.open(VersionDialogComponent, dialogConfig);
    const instance = dialogRef.componentInstance;
    instance.ngBuildInfo = environment.buildInfo;    
  }
}

import { IAppEnvironment } from './config/environment.model';
import { getEnvironment, isProdEnvironment } from './config/environment-util';
import { azureActiveDirectory } from './config/azureActiveDirectory.config';
import { getRealUserMonitoringClientConfig } from './config/realUserMonitoring.config';
import { BuildInfo } from './config/version.config';

const host = window.location.hostname;
const isProd = isProdEnvironment(host);

export const environment: IAppEnvironment = {
  azureActiveDirectory: azureActiveDirectory,
  client_url(): string {
    return window.location.origin;
  },
  server_url(): string {
    return this.client_url(); // SPA and ASP.NET Core are running on same port/url
  },
  api_url(): string {
    return this.server_url() + '/api';
  },

  odata_url(): string {
    return this.server_url() + '/odata';
  },
  production: true,
  buildInfo: BuildInfo.get(),
  environment: getEnvironment(host),
  rumClient: getRealUserMonitoringClientConfig(isProd)
};

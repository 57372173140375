<span *ngIf="displayText" class="text-span">
  <span
    [title]="hoverText"
    [class.clickable]="copyText"
    (click)="copyToClipboard()">
    {{displayText}}
    <mat-icon class="display-icon" *ngIf="displayIcon">{{displayIcon}}</mat-icon>
  </span>
</span>
<mat-icon
	inline
	*ngIf="!displayText"
	fontSet="{{determineIconFontSet()}}"
	[title]="hoverText"
	[class.clickable]="copyText"
	(click)="copyToClipboard()">
	{{displayIcon || 'file_copy'}}
</mat-icon>

import { BaseRealUserMonitoringService } from './base-real-user-monitoring.service';
import { datadogRum } from '@datadog/browser-rum';
import { Injectable } from '@angular/core';
import { IAppEnvironment } from 'environments/config/environment.model';

@Injectable({
  providedIn: 'root'
})
export class DataDogRealUserMonitoringService extends BaseRealUserMonitoringService {

  private isInitialized = false;


  public initialize(config: IAppEnvironment): void {

    if (!this.isInitialized) {
      const rumConfig = config.rumClient;

      // Not sure how much of this we want to expose to be configurable from a settings perspective
      // Some of these hardcoded values could easily be added in the appopiate environment files
      datadogRum.init({
        applicationId: rumConfig.applicationId,
        clientToken: rumConfig.clientToken,
        env: config.environment,
        version: config.buildInfo.version,
        site: 'datadoghq.com',
        service: 'backup-support-portal',
        // Interactions tracking
        trackInteractions: true,
        sampleRate: 100,
        // Session tracking
        replaySampleRate: 0,
        defaultPrivacyLevel: 'mask-user-input',
        // Required for iFrame
        useCrossSiteSessionCookie: true,
        // APM Tracing
        allowedTracingOrigins: rumConfig.apmTracingEnable === true ? [config.server_url()] : undefined
      });

      if (rumConfig.enableRumRecording === true) {
        // Start recording for session replay
        datadogRum.startSessionReplayRecording();
      }

      this.isInitialized = true;

    } else {
      // eslint-disable-next-line no-console
      console.warn('DatadogRealUserMonitoringService::initialization called multiple times');
    }
  }

  public setUserContext(username: string): void {
    if (this.isInitialized) {
      datadogRum.setUser({
        id: username
      });
    }
  }

  public logError(error: unknown): void {
    if (this.isInitialized) {
      datadogRum.addError(error);
    }
  }
}


<div *ngIf="!collapsed">
  <div class="expanded-container">
    <div class="header-container">
      <div class="header" (click)="collapse()" [ngClass]="{'hover': hasMore}">
        <div [ngClass]="{'action-offset': !hasMore }">{{journey.name}} <span *ngIf="displayName"> &#183; {{displayName}} </span></div>
        <mat-icon *ngIf="hasMore">expand_more</mat-icon>
      </div>
      <div class="sub-header" *ngIf="requestedUrl">
        {{requestedUrl}}
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<ng-content select="[expanded-footer]" *ngIf="!collapsed"></ng-content>
<div *ngIf="collapsed" (click)="expand()">
  <div class="collapsed-container">
    <div>{{journey.name}} <span *ngIf="displayName"> &#183; {{displayName}} </span></div>
    <mat-icon>chevron_right</mat-icon>
  </div>
</div>

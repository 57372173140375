import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppThemeService {
  darkTheme: boolean;

  getCurrentTheme(): string {
    return localStorage.getItem('theme');
  }

  setCurrentTheme(): void {
    const currentTheme = this.getCurrentTheme();
    if (currentTheme) {
      document.documentElement.setAttribute('data-theme', currentTheme);
      if (currentTheme === 'dark') {
        this.darkTheme = true;
      } else {
        this.darkTheme = false;
      }
    }
  }

  isDarkTheme(): boolean {
    this.setCurrentTheme();
    return this.darkTheme;
  }

  switchTheme(): boolean {
    this.darkTheme = !this.darkTheme;
    if (this.darkTheme === true) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
    return this.darkTheme;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';

@Component({
  selector: 'sk-users-groups-landing',
  templateUrl: './users-groups-landing.component.html',
  styleUrls: ['./users-groups-landing.component.scss']
})
export class UsersGroupsLandingComponent extends SkyKickComponent implements OnInit {
  public orderId: string;

  public usersSearchTabName = 'UsersSearch';
  public usersSearchLoaded = false;
  public groupsSearchTabName = 'GroupsSearch';
  public groupsSearchLoaded = false;
  public collaborationSearchTabName = 'CollaborationSearch';
  public collaborationSearchLoaded = false;

  public currentTabName = '';

  constructor(
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.orderId = this.route.parent.snapshot.paramMap.get('id');
    this.currentTabName = this.usersSearchTabName;
    this.usersSearchLoaded = true;
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currentTabName = event.tab.textLabel;

    if (this.currentTabName == this.usersSearchTabName){
      this.usersSearchLoaded = true;
    } else if (this.currentTabName == this.groupsSearchTabName) {
      this.groupsSearchLoaded = true;
    } else if (this.currentTabName == this.collaborationSearchTabName) {
      this.collaborationSearchLoaded = true;
    }
  }
}

<h4>Last Indexed Date</h4>

<div class="notification">
  <div>
    <mat-icon inline class="blue">info</mat-icon>
    NOT PARTNER FACING
  </div>
  Please remember that this is an engineering tool. The value reported below represents the last time SkyKick
  added a search document to Elastic Search. Snapshots are not the only jobs that index documents, and not all
  backed up data is indexed.
</div>

<ng-container *ngIf="this.isLoading === false; else spinner">
  <ng-container *ngIf="!elasticSerachLastIndexDate; else error">
    <div class="response">
      <mat-icon inline class="success">done</mat-icon>
      <b>{{ elasticSerachLastIndexDate | friendlyDate }}</b>
    </div>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <mat-spinner class="loading-spinner"></mat-spinner>
</ng-template>

<ng-template #error>
  <div class="response">
    <mat-icon inline class="error">cloud_off</mat-icon>
    There was a problem contacting Elastic Search. Please try again or contact SWAT.
  </div>
</ng-template>

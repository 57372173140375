<form [formGroup]="filterForm" class="flex-row-container">
  <div class="flex-row">
    <div class="flex-item search-form-field">
      <mat-form-field>
        <input matInput placeholder="Search..." formControlName="searchInput" #input>
      </mat-form-field>
    </div>

    <div class="flex-item filter-drop">
      <mat-form-field>
        <mat-label>Subscription Type</mat-label>
        <mat-select formControlName="subscriptionType">
          <mat-option [value]="'Any'">Any</mat-option>
          <mat-option *ngFor="let subscriptionType of subscriptionTypes" [value]="subscriptionType">
            {{ subscriptionType ? subscriptionType : '--' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex-item filter-drop">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="resourceStatus">
          <mat-option [value]="'Any'">Any</mat-option>
          <mat-option [value]="true">Enabled</mat-option>
          <mat-option [value]="false">Disabled</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex-item">
      <button mat-fab extended (click)="resetFilterControl()">
        <mat-icon>clear</mat-icon>
        Reset Filters
      </button>
    </div>
  </div>
</form>

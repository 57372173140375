  <div *ngIf="journey != null">
    <div>
      <div>
        <h3><span *ngIf="displayName">{{displayName}} &#183;</span> {{journey.name}}</h3><a href="" (click)="goHome($event)">Reset</a>
      </div>
    </div>
    <hr />
    <div class="initial-stop" *ngIf="journey != null">
      <sk-graph-journey-stop [orderId]="orderId" [journey]="journey" [params]="params" [autoLoad]="false">
      </sk-graph-journey-stop>
    </div>
  </div>
  <div *ngIf="journey == null">
    <sk-no-data header="Not supported" subheader="Currently not supported for this type"></sk-no-data>
  </div>

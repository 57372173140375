import { Component, Input } from '@angular/core';
import { BackupSubscription } from '@sk-models';

@Component({
  selector: 'sk-subscription-summary-card',
  templateUrl: './subscription-summary-card.component.html',
  styleUrls: ['./subscription-summary-card.component.scss']
})
export class SubscriptionSummaryCardComponent {

  @Input() subscription: BackupSubscription;

}

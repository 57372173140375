import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RestoreJobFilter, SearchReindexStatus, BackupSubscription } from '@sk-models';
import { MessageService, SearchReindexService } from '@sk-services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReindexModalComponent } from '../../subscriptions/subscription-details/reindex/reindex-modal.component';
import { SearchReindexStatusEnum } from '../../models/search-reindex-status';
import { BulkReindexModalComponent } from 'app/order/order-actions/bulk-reindex-modal/bulk-reindex-modal.component';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'sk-reindex-history',
  templateUrl: './reindex-history.component.html',
  styleUrls: ['./reindex-history.component.scss'],
  encapsulation: ViewEncapsulation.None // Defines global styles, but required if we want to use component based scss for table styling
})
export class ReindexHistoryComponent implements OnChanges {

  @Input() orderId: string;
  @Input() subscriptionId: string;
  @Input() resourceType: string;
  @Input() filter: RestoreJobFilter;
  @Input() jobs: SearchReindexStatus[];
  @Input() isRefreshing: boolean;
  @Output() refreshData = new EventEmitter();

  running = false;

  defaultSort = {
    field: 'created',
    dir: 'desc'
  };

  get displayedColumns(): string[] {
    let columns = ['id'];
    if (!this.subscriptionId) {
      columns = columns.concat([
        'subscriptionId',
        'subscriptionType'
      ]);
    }
    columns = columns.concat([
      'status',
      'startedTime',
      'lastUpdatedTime',
      'finishedTime',
      'indexedDocumentCount',
      'errorMessage',
      'roleInstanceId',
      'machineName',
      'comments'
    ]);
    return columns;
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: MatTableDataSource<SearchReindexStatus>;
  activeTab: string;
  subscription: BackupSubscription;

  constructor(
    private searchReindexService: SearchReindexService,
    private dialog: MatDialog,
    private messageService: MessageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jobs) {
      this.jobs = (this.jobs ?? [])
        .map(j => this.searchReindexService.computeJobProperties(j))
        .sort((left, right) => this.jobHistoryCompareFn(left, right));

      this.loadData();
    }
    if (changes.filter) {
      this.onFilterChanged(this.filter);
    }
    // check if reindex is running and disable button.
    for (let i = 0; i < this.jobs.length; i++) {
      if (this.jobs[i].status == SearchReindexStatusEnum.InProgress) {
        this.running = true;
        break;
      }
      this.running = false;
    }
  }


  jobHistoryCompareFn(left: SearchReindexStatus, right: SearchReindexStatus): number {
    return right.startedTime.valueOf() - left.startedTime.valueOf();
  }

  loadData(): void {
    // some previousValue indicates that currentValue has changed - refresh grid source
    if (this.dataSource) {
      this.dataSource.data = this.jobs;
    } else {
      // no previousValue means it has just been initialized
      this.dataSource = new MatTableDataSource(this.jobs);
      this.sort?.sort({
        id: 'created',
        start: 'desc',
        disableClear: false
      });
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (job, property) => this.getProperty(job, property);
      this.dataSource.filterPredicate = this.filterData;
    }
  }

  onFilterChanged(filter: RestoreJobFilter): void {
    this.dataSource.filter = JSON.stringify(filter);
  }

  getProperty(job: SearchReindexStatus, columnName: string): string | number {
    const propertyName = columnName.replace(/\s/g, '');
    return job[propertyName];
  }

  filterData(job: SearchReindexStatus, filterString: string): boolean {
    const dataFilter = <RestoreJobFilter>JSON.parse(filterString);

    // check type
    const selectedType = dataFilter.resourceType;
    if (selectedType !== 'All' && selectedType !== job.subscriptionType) {
      return false;
    }

    // check status
    const selectedStatus = dataFilter.jobStatus;
    if (selectedStatus !== 'All' && selectedStatus !== job.statusIconTitle) {
      return false;
    }

    const searchBox = dataFilter.searchString?.toLowerCase() || '';
    return !(searchBox !== null && job.id.indexOf(searchBox) === -1 && job.subscriptionId.indexOf(searchBox) === -1);
  }

  displayReindexSubscriptionModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      backupServiceId: this.orderId,
      subscriptionId: this.subscriptionId,
      resourceType: this.resourceType
    }
    const dialogRef = this.dialog.open(ReindexModalComponent, dialogConfig)
    dialogRef.afterClosed();
  }

  displayReindexOrderModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { backupServiceId : this.orderId };
    const dialogRef = this.dialog.open(BulkReindexModalComponent, dialogConfig)
    dialogRef.afterClosed();
  }

  onRefreshData(): void {
    this.refreshData.emit();
  }

  showModal(message: string, title: string): void {
    this.messageService.openCustomModal(GenericModalComponent, message, title);
  }
}

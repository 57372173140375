
<div class="details-container">
  <div class="details-title has-back">
    <div>
      <mat-icon
        class="clickable back"
        title="Back"
        (click)="returnClicked()">
        keyboard_backspace
      </mat-icon>
    </div>
    <div>
      Check IP Address
    </div>
  </div>

  <div class="details-table">
    <form [formGroup]="ipCheckerForm" class="ip-checker-form">
      <mat-form-field class="ip-checker-input">
        <input matInput [formControl]="ipAddress" placeholder="Enter an IP Address to see if it is coming from Azure">
        <mat-error *ngIf="ipAddress.invalid">Invalid IPv4 or IPv6 Address entered</mat-error>
      </mat-form-field>
      <button mat-raised-button
        id="ipAddressInput"
        (click)="checkIPAddress()"
        [disabled]="ipAddress.invalid">
        Check
      </button>
    </form>
  </div>
</div>


export class RestoredItemFilter {
    entityType: string;
    status: string;
    minimumSize: number;
    maximumSize: number;
    expanded: boolean;
    searchInput: string;

    constructor() {
      this.entityType = 'All';
      this.status = 'All';
      this.maximumSize = null;
      this.minimumSize = null;
      this.searchInput = '';
      this.expanded = false;
    }
}

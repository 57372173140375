import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import {
  SearchReindexStatus,
  RestoreJobFilter,
} from '@sk-models';
import { ApiService } from '../../api-service/api.service';

@Component({
  selector: 'sk-order-reindex-history',
  templateUrl: './order-reindex-history.component.html'
})
export class OrderReindexHistoryComponent implements OnInit {


  @Input() orderId: string;
  @Input() subscriptionId: string;

  jobs: SearchReindexStatus[];
  isRefreshing: boolean;
  filterData: RestoreJobFilter = new RestoreJobFilter();

  jobStatuses: string[];
  resourceTypes: string[];

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.loadSearchReindexStatuses(false);
  }

  loadSearchReindexStatuses(reload: boolean): void {
    this.isRefreshing = true;

    this.apiService.findBackupOrderSearchIndexStatuses(this.orderId, reload)
      .subscribe(jobs => {
        this.jobs = jobs;
        this.isRefreshing = false;
        this.jobStatuses = Array.from(new Set(this.jobs.map((item: SearchReindexStatus) => item.statusIconTitle)));
        this.resourceTypes = Array.from(new Set(this.jobs.map((item: SearchReindexStatus) => item.subscriptionType)));
      });
  }

  onFilterChanged(filter: RestoreJobFilter): void {
    this.filterData = filter;
  }

  onRefreshData(): void {
    this.loadSearchReindexStatuses(true);
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BackupJob, RestoredItem, RestoredItemFilter, RestoredItemStatus } from '@sk-models';
import { FilterService } from '@sk-services';
import { ApiService } from 'app/api-service/api.service';

@Component({
  selector: 'sk-restored-items',
  templateUrl: './restored-items.component.html',
  styleUrls: ['./restored-items.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class RestoredItemsComponent implements OnInit {
  @Input() job: BackupJob;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<RestoredItem>;
  filterForm: FormGroup;

  get selectedEntityType(): string { return this.filterForm.get('entityType').value; }
  get selectedStatus(): string { return this.filterForm.get('status').value; }
  get minimumSize(): number { return this.filterForm.get('minimumSize').value; }
  get maximumSize(): number { return this.filterForm.get('maximumSize').value; }
  get searchInput(): string { return this.filterForm.get('searchInput').value; }

  entityTypes: string[];
  restoreStatuses: string[];

  restoredItems: RestoredItem[];
  isLoading: boolean;
  displayedColumns: string[] = [
    'expand',
    'entityName',
    'status',
    'entityType',
    'numberOfAttempts',
    'size',
    'skyKickId',
    'parentSkyKickId'
  ];

  constructor(
    private apiService: ApiService,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.initDataSource();
    this.initFilter();
    this.loadRestoredItems();
  }

  initDataSource(): void {
    this.dataSource = new MatTableDataSource(this.restoredItems);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (job, property) => this.sortProperty(job, property);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.filterData;
  }

  sortProperty(item: RestoredItem, columnName: string): string {
    const propertyName = columnName.replace(/\s/g, '');
    switch (propertyName) {
    case 'status':
      return this.getStatus(item);
    default:
      return item[propertyName];
    }
  }

  initFilter(): void {
    let existingFilter = this.filterService.getFilter('restoredItemFilter') as RestoredItemFilter;
    if (!existingFilter) {
      existingFilter = new RestoredItemFilter();
    }

    this.filterForm = new FormGroup({
      entityType: new FormControl(existingFilter.entityType),
      status: new FormControl(existingFilter.status),
      minimumSize: new FormControl(existingFilter.minimumSize),
      maximumSize: new FormControl(existingFilter.maximumSize),
      searchInput: new FormControl(existingFilter.searchInput)
    });

    this.filterForm.valueChanges
      .subscribe(() => this.updateCurrentFilter());

    this.dataSource.filterPredicate = this.filterData;
  }

  loadRestoredItems(): void {
    this.isLoading = true;
    this.apiService.getRestoredItems(this.job.id, this.job.resourceKey)
      .subscribe({
        next: items => {
          this.restoredItems = items;
          this.dataSource.data = items;
          this.populateFilterFields();
        },
        complete: () => this.isLoading = false
      });
  }

  filterData(item: RestoredItem, filterString: string): boolean {
    // to figure out what's in this delimited list, check
    // wherever the MatTableDataSource.filter is set.
    const filters = filterString.split(',');

    // check entity type
    const selectedType = filters[0];
    if (selectedType !== 'All' && selectedType !== item.entityType) {
      return false;
    }

    // check status
    const selectedStatus = filters[1];
    if (selectedStatus !== 'All' &&
          ( (selectedStatus === 'Successful' && !item.isSuccessfullyRestored) ||
            (selectedStatus === 'Skipped' && !item.isSkipped) ||
            (selectedStatus === 'Abandoned' && !item.isAbandoned))
    ) {
      return false;
    }

    // check minimum size
    const minimumSize = Number(filters[2]);
    if (minimumSize !== null &&
        item.size < minimumSize
    ) {
      return false;
    }

    // check maximum size
    const maximumSize = Number(filters[3]);
    if (maximumSize !== null &&
        item.size > maximumSize
    ) {
      return false;
    }

    // check search input
    const searchInput = filters[4];
    if (searchInput &&
          ( (item.skyKickId ? !item.skyKickId.includes(searchInput) : true) &&
            (item.entityName ? !item.entityName.includes(searchInput) : true) &&
            (item.friendlyUrl ? !item.friendlyUrl.includes(searchInput) : true))
    ) {
      return false;
    }

    return true;
  }

  getStatus(item: RestoredItem): string {
    if (item.isSuccessfullyRestored) {
      return RestoredItemStatus[RestoredItemStatus.Successful];
    } else if (item.isAbandoned) {
      return RestoredItemStatus[RestoredItemStatus.Abandoned];
    } else if (item.isSkipped) {
      return RestoredItemStatus[RestoredItemStatus.Skipped];
    }
  }

  updateCurrentFilter(): void {
    const filter = new RestoredItemFilter();
    filter.entityType = this.selectedEntityType;

    this.filterService.setFilter('restoredItemFilter', filter);
    this.dataSource.filter = `${this.selectedEntityType},` +
                             `${this.selectedStatus},` +
                             `${this.minimumSize},` +
                             `${this.maximumSize},` +
                             `${this.searchInput}`;
  }

  populateFilterFields(): void {
    this.entityTypes = this.restoredItems ? Array.from(new Set(this.restoredItems.map((item: RestoredItem) => item.entityType))) : null;
    this.restoreStatuses = Object.keys(RestoredItemStatus).filter(key => typeof RestoredItemStatus[key] === 'number');
  }

  resetFilters(): void {
    this.initFilter();
    this.updateCurrentFilter();
  }

  expandCollapse(row: RestoredItem): void {
    row.isExpanded = !row.isExpanded;
  }
}

<div class="status-indicator">
  <ng-container *ngIf="isEnabled === true || isOverridden === true; else notProtected">
    <ng-container *ngTemplateOutlet="protected"></ng-container>
  </ng-container>

  <ng-template #protected>
    <mat-icon
      [ngClass]="{'warning': disabledDueToAccess, 'success': !disabledDueToAccess}"
      [attr.title]="disabledDueToAccess ? (scope + ' Lost Access') : (scope + ' Protected')">
      check_circle
    </mat-icon>
  </ng-template>

  <ng-template #notProtected>
    <mat-icon
      class="muted"
      title="{{scope}} Not Protected">
      check_circle
    </mat-icon>
  </ng-template>

  <span class="small-icon-bracket">[</span>
  <ng-container *ngIf="isEnabled === true; else disabled">
    <ng-container *ngTemplateOutlet="enabled"></ng-container>
  </ng-container>

  <ng-template #enabled>
    <mat-icon
      class="success small-icon"
      title="{{scope}} Explicitly Enabled">
      person
    </mat-icon>
  </ng-template>

  <ng-template #disabled>
    <mat-icon
      class="muted small-icon"
      title="{{scope}} Explicitly Disabled">
      person_off
    </mat-icon>
  </ng-template>

  <ng-container *ngIf="isOverridden === true; else notOverridden">
    <ng-container *ngTemplateOutlet="overridden"></ng-container>
  </ng-container>

  <ng-template #overridden>
    <mat-icon
      class="success small-icon"
      title="{{scope}} Protected via Group Membership">
      group
    </mat-icon>
  </ng-template>

  <ng-template #notOverridden>
    <mat-icon
      class="muted small-icon"
      title="{{scope}} Not Protected via Group Membership">
      group_off
    </mat-icon>
  </ng-template>
  <span class="small-icon-bracket">]</span>
</div>

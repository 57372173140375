import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'sk-widget-data-row',
  templateUrl: './widget-data-row.component.html',
  styleUrls: ['./widget-data-row.component.scss']
})
export class WidgetDataRowComponent  {

  @Input() label: string;
  @Input() value: string;
  @Input() clickToCopy = false;
  @Input() dataType: 'text' | 'date' = 'text';

  @ViewChild('template', { static:true }) template: TemplateRef<unknown>;
}

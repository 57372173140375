import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'subscriptionType' })
export class SubscriptionTypePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return input;
    }

    switch (input.toLowerCase()) {
    case "groupsconversations":
    case "groupsfiles":
      return "Groups";
    case "team":
    case "teamsfiles":
    case "teamchannelposts":
      return "Teams";
    default:
      return input;
    }
  }
}

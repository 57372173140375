// Just disable rules for the file, don't want to touch this if we don't have to.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ResponseCacheInterceptor implements HttpInterceptor {
    responseCache: object = {};
    clearCacheHeader = 'X-Clear-Cache';

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const clearCache = request.headers.has(this.clearCacheHeader);
      const setHeadersObj = {};
      setHeadersObj[this.clearCacheHeader] = null;

      request = request.clone({
        headers: request.headers.delete(this.clearCacheHeader)
      });

      if (clearCache) {
        return next.handle(request);
      }

      const key = this.makeCacheKey(request);
      const cachedResponse = this.responseCache[key];

      if (cachedResponse) {
        return of(cachedResponse);
      }

      return next.handle(request)
        .pipe(tap(
          (event: HttpEvent<any>) => {
            if (!(event instanceof HttpResponse)) {
              return;
            }
            this.responseCache[key] = event as HttpResponse<any>;
          }
        ));
    }

    makeCacheKey(request: HttpRequest<any>): string {
      return `${request.method}${request.urlWithParams}${this.hashString(request.body)}`;
    }

    hashString(request: any): string {
      let hash = 0;
      if (!request) {
        return hash.toString();
      }

      const s = JSON.stringify(request);

      for (let i = 0; i < s.length; i++) {
        const c = s.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = ((hash << 5) - hash) + c;
        // eslint-disable-next-line no-bitwise
        hash = hash & hash; // Convert to 32bit integer
      }

      return hash.toString();
    }
}

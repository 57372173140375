import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RestoreJobFilter } from '@sk-models';
import { FilterService } from '@sk-services';

@Component({
  selector: 'sk-reindex-history-filter',
  templateUrl: './reindex-history-filter.component.html'  
})
export class ReindexHistoryFilterComponent implements OnInit {  
  @Input() resourceTypes: string[];
  @Input() jobStatuses: string[];
  @Output() filterChanged = new EventEmitter<RestoreJobFilter>();
    
  filterForm: FormGroup;
  
  readonly filterName: string = 'reindexSubscriptionHistoryFilter';

  get selectedJobStatus(): string { return this.filterForm.get('jobStatus').value; }
  get selectedResourceType(): string { return this.filterForm.get('resourceType').value; }
  get selectedSearchQuery(): string { return this.filterForm.get('searchInput').value; }
  

  constructor(private filterService: FilterService) { }

  ngOnInit(): void {
    this.initFilter();    
  }
  
  resetCurrentFilter(): void {
    this.filterService.clearFilter(this.filterName);
    this.initFilter();
    this.updateCurrentFilter();
  }

  initFilter(): void {
    let existingFilter = this.filterService.getFilter(this.filterName) as RestoreJobFilter;
    if (!existingFilter) {
      existingFilter = new RestoreJobFilter();
    }

    this.filterForm = new FormGroup({
      resourceType: new FormControl(existingFilter.resourceType),
      jobStatus: new FormControl(existingFilter.jobStatus),
      searchInput: new FormControl()
    });   
    this.filterForm.valueChanges.subscribe(() => this.updateCurrentFilter());       
  }

  
  updateCurrentFilter(): void {
    const filter = new RestoreJobFilter();
    filter.jobStatus = this.selectedJobStatus;
    filter.resourceType = 'All';
    filter.searchString = this.selectedSearchQuery;

    this.filterService.setFilter(this.filterName, filter);    
    this.filterChanged.emit(filter);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../api-service/api.service';

@Component({
  selector: 'sk-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss']
})
export class DeveloperComponent implements OnInit {
  groupMembership: string;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getGroupMembership();
  }

  getGroupMembership(): void {
    this.apiService.fetchGroupMembership()
      .subscribe(o => {
        this.groupMembership = o;
        this.isLoading = false;
      });
  }
}

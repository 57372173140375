import * as moment from 'moment';
import { isEnvironmentReplacement } from './environment-util';
import { IBuildInfo } from './environment.model';

export abstract class BuildInfo {

  // These are intended to be replaced during the build process`
  private static _buildDate = '2024-11-06 15:31:58Z';
  private static _buildId = '0.1.530';

  static get(): IBuildInfo {
    return {
      buildDate: isEnvironmentReplacement(this._buildDate) ? moment().toString() : this._buildDate,
      version: isEnvironmentReplacement(this._buildId) ? '0.0.0.0' : this._buildId
    }
  }
}
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { SearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { UserResourcesSearchGridComponent } from '../users-groups-user-search/user-resources-search-grid/user-resources-search-grid.component'
import { UsersSearchGridComponent } from '../users-groups-user-search/users-search-grid/users-search-grid.component'

@Component({
  selector: 'sk-users-groups-user-search',
  templateUrl: './users-groups-user-search.component.html',
  styleUrls: ['./users-groups-user-search.component.scss']
})
export class UsersGroupsUserSearchComponent extends SkyKickComponent implements OnInit, AfterViewInit {
  @Input() backupServiceId: string;

  filter: SearchFilter;

  public ExchangeIcon = '../../../../../assets/images/icon-exchange.svg';
  public OneDriveIcon = '../../../../../assets/images/icon-onedrive.svg';
  public TeamsChatsIcon = '../../../../../assets/images/icon-teams.svg';

  @ViewChild('searchTabGroup') searchTabGroup: MatTabGroup;
  @ViewChild('usersSearch') usersSearch: UsersSearchGridComponent;
  @ViewChild('userResourcesExchangeSearch') exchangeSearch: UserResourcesSearchGridComponent;
  @ViewChild('userResourcesOneDriveSearch') oneDriveSearch: UserResourcesSearchGridComponent;
  @ViewChild('userResourcesTeamsChatsSearch') teamsChatsSearch: UserResourcesSearchGridComponent;

  usersLabel = 'users';
  userMailboxesLabel = 'userMailboxes';
  userOneDrivesLabel = 'userOneDrives';
  userTeamsChatsLabel = 'userTeamsChats';

  private titleTextPrefix = "User Search"
  prefixText = this.titleTextPrefix;

  ngOnInit(): void {
    this.filter = { term: '' };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.refreshSelectedTab();
    });
  }

  onFilterChanged(filter: SearchFilter): void {
    this.filter = {
      ...this.filter,
      term: filter.term
    };
    this.refreshSelectedTab();
  }

  get selectedTab(): MatTab {
    return this.searchTabGroup._tabs.toArray()[this.searchTabGroup.selectedIndex];
  }

  onSubTabChange(): void {
    this.updateTitleText();
    this.refreshSelectedTab();
  }

  private updateTitleText(): void {
    const textLabel = this.selectedTab.textLabel
    if (textLabel === this.usersLabel) {
      this.prefixText = this.titleTextPrefix;
    } else if (textLabel === this.userMailboxesLabel) {
      this.prefixText = 'User\\Mailbox Search';
    } else if (textLabel === this.userOneDrivesLabel) {
      this.prefixText = 'User\\OneDrive Search';
    } else if (textLabel === this.userTeamsChatsLabel) {
      this.prefixText = 'User\\Chats Search';
    }
  }

  private refreshSelectedTab(): void {
    const textLabel = this.selectedTab.textLabel
    if (textLabel === this.usersLabel) {
      this.usersSearch.refresh({ term: this.filter.term });
    } else if (textLabel === this.userMailboxesLabel) {
      this.exchangeSearch.refresh({ term: this.filter.term });
    } else if (textLabel === this.userOneDrivesLabel) {
      this.oneDriveSearch.refresh({ term: this.filter.term });
    } else if (textLabel === this.userTeamsChatsLabel) {
      this.teamsChatsSearch.refresh({ term: this.filter.term });
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, SubscriptionService } from '@sk-services';
import { AuthRoles, authUserInRole, BackupSubscription } from '@sk-models';
import { GraphJourneyRegisterService } from 'app/graph-journey/services/graph-journey-register.service';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { IGraphJourney } from 'app/graph-journey/models/graph-journey.interface';
import { SubscriptionGraphJourneyService } from 'app/graph-journey/services/subscription-graph-journey.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sk-subscription-graph-journey',
  templateUrl: './subscription-graph-journey.component.html'
})
export class SubscriptionGraphJourneyComponent extends SkyKickComponent implements OnInit, OnDestroy {

  displayName: string;
  journey: IGraphJourney = null;
  params: { [key: string]: unknown }
  orderId: string;
  subscriptionId: string;
  resourceType: string;
  backupSubscription: BackupSubscription;

  isVisible = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private subscriptionGraphJourneyService: SubscriptionGraphJourneyService,
    private registar: GraphJourneyRegisterService,
    private authService: AuthService) {
    super();
    this.authService.authData$.pipe(filter(x => x != null), take(1)).subscribe((data) => {
      this.isVisible = authUserInRole(data, AuthRoles.Engineer);
    });
  }


  ngOnInit(): void {
    const routeParamMap = this.activatedRoute.snapshot.paramMap;
    this.orderId = routeParamMap.get('id');
    this.subscriptionId = routeParamMap.get('subscriptionId');
    this.resourceType = routeParamMap.get('resourceType');

    this.subscriptionService.getSubscription(this.orderId, this.subscriptionId, this.resourceType);

    this.subs.sink = this.subscriptionService.subscription$
      .subscribe(sub => {
        if (sub) {
          const params = this.activatedRoute.snapshot.queryParams;

          this.backupSubscription = sub;
          // Get the initial journey for this resource type.
          // For instance, we may deep link into a folder structure, but we want to know if we are starting
          // with OneDrive or something else
          const initialJourney = this.subscriptionGraphJourneyService.getInitialJourney(sub);

          // Check to see if the there is a "bspJourney" parameter - if so, attempt to use it.
          if (params != null && params['bspJourney'] != null) {
            const journey = params['bspJourney'];
            if (this.registar.isRegistered(journey)) {
              this.journey = this.registar.getRegistration(journey);
            }
          }

          // If we don't have a journey at this point (either the bspJourney) was invalid or none was specified
          // grab the default for the group
          if (this.journey == null) {
            this.journey = initialJourney.journey;
            this.params = initialJourney.params;
          } else {
            this.displayName = initialJourney.journey?.name;
            this.params = params
          }
        }
      });
  }

}

<div [hidden]="!dataSource" [class.mat-elevation-z8]="!dataSource" class="grid-container">
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
      <ng-container matColumnDef="skyKickId">
        <mat-header-cell *matHeaderCellDef>
          <span>SkyKickID</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.skyKickId }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="parentSkyKickId">
        <mat-header-cell *matHeaderCellDef>
          <span>Parent SkyKickID</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.parentSkyKickId }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="abandonedReason">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Abandoned Reason</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <a *ngIf="item.abandonedReason"
            href=""
            [title]="item.abandonedReason"
            target="_blank"
            (click)="showStackTrace(item.abandonedReason); $event.preventDefault();">
            {{ item.abandonedReason.length > 400
              ? item.abandonedReason.substring(0,400) + '...'
              : item.abandonedReason }}
						</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Time Stamp</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{item.timestamp | friendlyDate}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns">
      </mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
  </div>
    <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Skips', sheet: 'Skips'})">Excel</button>
    <button mat-raised-button (click)="exporter.exportTable('csv')">Csv</button>
    <button mat-raised-button (click)="exporter.exportTable('json')">Json</button>
    <button mat-raised-button (click)="exporter.exportTable('txt')">Txt</button>
  <div>
</div>

<div *ngIf="!isLoading && skippedItems?.length == 0" class="no-results">
  <span>There were no skipped items in this restore job!</span>
</div>

<div *ngIf="!isLoading && !skippedItems" class="no-results">
  <span>No Results Found</span>
</div>

<div *ngIf="isLoading" class="loading-spinner">
  <mat-spinner></mat-spinner>
</div>

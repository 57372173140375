<a [routerLink]="link">
  <span class="tab-name"
    routerLinkActive="active"
    [ngClass]="{
      'light-theme': lightTheme,
      'dark-theme': !lightTheme
    }">
    {{name}}
  </span>
</a>

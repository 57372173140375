<sk-users-groups-search-filter (filterChange)="onFilterChanged($event)">
  <div class="search-prefix-container" slot="first-grid-tile">
    <mat-icon class="search-prefix-icon">person</mat-icon>
    <div class="search-prefix-content">
      <div class="search-prefix-text">{{ prefixText }}</div>
      <mat-tab-group mat-stretch-tabs #searchTabGroup class="small-tab-group" (selectedTabChange)="onSubTabChange()">
        <mat-tab [label]="usersLabel" #users>
          <ng-template mat-tab-label><mat-icon class="shake">person_search</mat-icon></ng-template>
        </mat-tab>
        <mat-tab [label]="userMailboxesLabel" #userMailboxes>
          <ng-template mat-tab-label><img [src]="ExchangeIcon" alt="Exchange" class="shake" /></ng-template>
        </mat-tab>
        <mat-tab [label]="userOneDrivesLabel" #userOneDrives>
          <ng-template mat-tab-label><img [src]="OneDriveIcon" alt="OneDrive" class="shake onedrive-icon" /></ng-template>
        </mat-tab>
        <mat-tab [label]="userTeamsChatsLabel" #userTeamsChats>
          <ng-template mat-tab-label><img [src]="TeamsChatsIcon" alt="TeamsChats" class="shake" /></ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</sk-users-groups-search-filter>

<sk-users-search-grid #usersSearch
  [ngStyle]="{ display: !users.isActive ? 'none' : null }"
  [backupServiceId]="backupServiceId"
  [filterSearchTerm]="filter.term">
</sk-users-search-grid>

<sk-user-resources-search-grid #userResourcesExchangeSearch
  [ngStyle]="{ display: !userMailboxes.isActive ? 'none' : null }"
  [backupServiceId]="backupServiceId"
  [filterSubscriptionType]="'O365Exchange'"
  [filterSearchTerm]="filter.term">
</sk-user-resources-search-grid>

<sk-user-resources-search-grid #userResourcesOneDriveSearch
  [ngStyle]="{ display: !userOneDrives.isActive ? 'none' : null }"
  [backupServiceId]="backupServiceId"
  [filterSubscriptionType]="'O365SharePoint'"
  [filterSearchTerm]="filter.term">
</sk-user-resources-search-grid>

<sk-user-resources-search-grid #userResourcesTeamsChatsSearch
  [ngStyle]="{ display: !userTeamsChats.isActive ? 'none' : null }"
  [backupServiceId]="backupServiceId"
  [filterSubscriptionType]="'Teams'"
  [filterSearchTerm]="filter.term">
</sk-user-resources-search-grid>

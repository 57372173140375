import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private useUtc = new BehaviorSubject<boolean>(false);
  public useUtc$ = this.useUtc.asObservable();
  public utcToggleEnabled: boolean;

  constructor() {
    this.utcToggleEnabled = window.frames.parent.length === 0;
    if(this.utcToggleEnabled) {
      const current = localStorage.getItem('useUtc');
      if(current === 'true') {
        this.useUtc.next(true);
      }
    }
  }

  setUtc(value: boolean): void {
    this.useUtc.next(value);
    localStorage.setItem('useUtc', value.toString());
  }

}

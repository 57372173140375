import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BackupSubscriptionTypes, SubscriptionsFilter } from '@sk-models';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'sk-subscriptions-filter',
  templateUrl: './subscriptions-filter.component.html',
  styleUrls: ['./subscriptions-filter.component.scss']
})
export class SubscriptionsFilterComponent implements OnInit, AfterViewInit {
  @Output() filterChange = new EventEmitter<SubscriptionsFilter>();
  @ViewChild('input') input: ElementRef;

  // Filters Form & Display
  subscriptionTypes: string[];
  filterForm: FormGroup;
  debounceTime = 250;

  public filter = new SubscriptionsFilter();

  get selectedResourceStatus(): boolean {
    if (this.filterForm.get('resourceStatus').value === 'Any') {
      return null;
    }
    return this.filterForm.get('resourceStatus').value;
  }

  get selectedSubscriptionType(): BackupSubscriptionTypes {
    if (this.filterForm.get('subscriptionType').value === 'Any') {
      return null;
    }
    return this.filterForm.get('subscriptionType').value;
  }

  get selectedSearchQuery(): string {
    return this.filterForm.get('searchInput').value;
  }

  ngOnInit(): void {
    this.initFilterControl();
    this.subscriptionTypes = this.buildSubscriptionTypeList();
  }

  ngAfterViewInit(): void {
    merge(
      fromEvent(this.input.nativeElement, 'keyup'),
      this.filterForm.valueChanges)
      .pipe(
        // Delay all form submits, need for search and
        // unnoticable delay for filters
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.updateFilterControl();
        })
      )
      .subscribe();
  }

  public initFilterControl(): void {
    this.filter = new SubscriptionsFilter();

    this.filterForm = new FormGroup({
      resourceStatus: new FormControl(this.filter.enabled),
      subscriptionType: new FormControl(this.filter.subscriptionType),
      searchInput: new FormControl(this.filter.searchString)
    });

    this.filterForm.controls.subscriptionType.setValue('Any');
    this.filterForm.controls.resourceStatus.setValue('Any');
  }

  private updateFilterControl(): void {
    this.filter = new SubscriptionsFilter();
    this.filter.subscriptionType = this.selectedSubscriptionType;
    this.filter.enabled = this.selectedResourceStatus;
    this.filter.searchString = this.selectedSearchQuery;
    this.filterChange.emit(this.filter);
  }

  public resetFilterControl(filter?: SubscriptionsFilter): void {
    if (filter) {
      this.setFilterControl(filter);
    } else {
      this.initFilterControl();
      this.updateFilterControl();
    }
  }

  private setFilterControl(filter: SubscriptionsFilter): void {
    if (this.filter.subscriptionType != filter.subscriptionType) {
      this.filterForm.controls.subscriptionType.setValue(filter.subscriptionType, { emitEvent: false });
    }
    if (this.filter.enabled != filter.enabled) {
      this.filterForm.controls.resourceStatus.setValue(filter.enabled, { emitEvent: false });
    }
    if (this.filter.searchString != filter.searchString) {
      this.filterForm.controls.searchInput.setValue(filter.searchString, { emitEvent: false });
    }
  }

  private buildSubscriptionTypeList(): string[] {
    const valueList: string[] = Object.keys(BackupSubscriptionTypes);
    return valueList;
  }
}

<div class="component-container">
  <div class="subscription-browser">
    <div class="browser-title">
      <span>Subscription Explorer</span>
    </div>
    <div *ngIf="!isVisible">
      <sk-banner header="Not Supported" subtext="Browse Subscription is not available for this resource type">
      </sk-banner>
    </div>
    <div *ngIf="isVisible && isLimitedVisibilityType">
      <sk-banner header="Limited Visibility"
        subtext="This resource type is only available to those users in the Engineering role."></sk-banner>
    </div>
    <div class="browser-content" *ngIf="subscription && isVisible">
      <sk-tree-viewer *ngIf="treeRoot"
                      [treeRoot]="treeRoot"
                      [orderId]="subscription.backupServiceId"
                      [subscriptionId]="subscription.id"
                      [subscriptionNativeId]="subscription.o365Id"
                      [resourceType]="subscription.resourceKey.resourceType"
                      (entitySelectedEvent)="setSelectedEntityAsQueryParams($event)">
      </sk-tree-viewer>
      <div class="matSpinner">
        <mat-spinner [diameter]="20" *ngIf="!treeRoot">
        </mat-spinner>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!subscription && isVisible" class="loading-spinner">
  <mat-spinner></mat-spinner>
</div>
